import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const Logout = () => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate(); 

    const logout = async () => {
        try {
            console.log("  Calling Logout Endpoint...");
            const response = await axiosPrivate.get('/logout');
                
            //setAuth();
            navigate('/login');
            console.log("  Response: " + JSON.stringify(response?.data));
                
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Button className="btn-sm" variant="outline-danger" id="logoutButton"
            onClick={logout}>Abmelden</Button>
    )
}

export default Logout