import { Badge } from 'react-bootstrap';
import { Fragment } from "react";
import { CERTIFICATE_STATUS } from "../util/Stati";
const CertificateStatus = (certProp) => {
  console.log(certProp);
  var { status, cert } = certProp;

  if (!cert && !status) {
    return;
  }

  if (cert) {
    status = cert.stati[cert.stati.length - 1].status;
  }

  var variant;
  var txt;
  
  switch(true) {
    case status == CERTIFICATE_STATUS.WIP :
      variant = 'primary';
      txt = 'in Bearbeitung';
      break;
    case status == CERTIFICATE_STATUS.IN_APPROVAL :
      variant = 'primary';
      txt = 'in Genehmigung';
      break;
    case status == CERTIFICATE_STATUS.APPROVED :
      variant = 'primary';
      txt = 'Genehmigt';
      break;
    case status == CERTIFICATE_STATUS.REJECTED :
      variant = 'warning';
      txt = 'Zurückgewiesen';
      break;      
    case status == CERTIFICATE_STATUS.PUBLIC :
      variant = 'success';
      txt = 'Aktiv';
      break;
    case status == CERTIFICATE_STATUS.RETRACTED :
      variant = 'dark';
      txt = 'Zurückgezogen';
      break;      
    default:
      break;           
  }

  return ( 
    <Fragment>
      <Badge bg={variant}>
        {txt}
      </Badge>
    </Fragment>
  )
}
export default CertificateStatus