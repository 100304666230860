import { Fragment } from "react";
import { Col, Row} from "react-bootstrap";
const ValidFromTo = (param) => {
  console.log('param.cert.validFrom');
  console.log(param.cert);
  const validFrom = param.cert?.validFrom ? new Date(param.cert.validFrom).toLocaleDateString("DE") : '';
  const validTo = param.cert?.validTo ? new Date(param.cert.validTo).toLocaleDateString("DE") : '';

  var txt;
  if (validFrom && validTo) {
    txt = validFrom + ' - ' + validTo;
  } else {
    txt = 'in Bearbeitung.'
  }

  return( 
      <Fragment>
        {txt}
      </Fragment>
  )
}
export default ValidFromTo