import { Link } from "react-router-dom";
import { useState } from "react";
import useAuth from "../hooks/useAuth";
import { Col } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse';

const NavigationBar = () => {
    const [open, setOpen] = useState(false);
    const { auth } = useAuth();

    return (

        <Col id="menuContainer" className="col-auto col-md-2 col-xl-2 px-sm-2 px-0 bg-dark">
            <div className="d-flex flex-column align-items-center align-items-sm-start pt-2 text-white min-vh-100">
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start w-100" id="menu">
                    <li className="navbarItem">
                        <Link to="/home" className="navbarLink">
                            <span className="navbarIconContainer bg-yellow">
                                <i className="fs-4 bi-house navbarIcon"></i>
                            </span>
                            <span className="ms-1 d-none d-sm-inline navbarName">Home</span>
                        </Link>
                    </li>
                    {auth?.roles?.includes("Auditor", "Admin") &&
                    <li className="navbarItem">
                        <Link to="/clients" className="navbarLink">
                            <span className="navbarIconContainer bg-orange">
                                <i className="fs-4 bi-buildings navbarIcon"></i>
                            </span>
                            <span className="ms-1 d-none d-sm-inline navbarName">Kunden</span>
                        </Link>
                    </li>
                    }
                    {auth?.roles?.includes("Auditor", "Admin") &&
                    <li className="navbarItem">
                        <Link to="/certificationsList" className="navbarLink">
                            <span className="navbarIconContainer bg-red">
                                <i className="fs-4 bi-file-medical navbarIcon"></i>
                            </span>
                            <span className="ms-1 d-none d-sm-inline navbarName">Zertifizierungen</span>
                        </Link>
                    </li>
                    }
                    {auth?.roles?.includes("Admin") &&                                      
                    <li className="navbarItem">
                        <Link to="/benutzer" className="navbarLink">
                            <span className="navbarIconContainer bg-purple">
                                <i className="fs-4 bi-people navbarIcon"></i>
                            </span>
                            <span className="ms-1 d-none d-sm-inline navbarName">Benutzer</span>
                        </Link>
                    </li>
                    }
                    {auth?.roles?.includes("Admin") && 
                    <div>
                    <li className="navbarItem"                             
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}>
                        <a href="#" className="navbarLink">
                            <span className="navbarIconContainer bg-cyan">
                                <i className="fs-4 bi-people navbarIcon"></i>
                            </span>
                            <span className="ms-1 d-none d-sm-inline navbarName">subtest</span>
                        </a>   
                    </li> 
                    <Collapse in={open}>
                        <div id="example-collapse-text">
                            <ul className="collapse show nav flex-column ms-1" id="submenu1" data-bs-parent="#menu">
                                <li className="navbarItem">
                                    <a href="#" className="navbarLink">
                                        <span className="navbarIconContainer bg-cyan">
                                        <i className="fs-4 bi-people nav-icon"></i>
                                        </span>
                                        <span className="ms-1 d-none d-sm-inline navbarName">Submenü 1</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Collapse>
                    </div>
                    }
                </ul>
            </div>
        </Col>
    )
}

export default NavigationBar