import { Formik } from 'formik';
import { Fragment, useCallback, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import axios from '../../api/axios';
import MyAlert from "../../components/MyAlert";
import useConsent from "../../hooks/useConsent";
import MsgHolder from "../../util/MsgHolder";
import map from "../../assets/images/map.jpg";
import mapWebp from "../../assets/images/map.webp";
import cookieWebp from "../../assets/images/cookie.webp";
import cookie from "../../assets/images/cookie.jpg";

const Kontakt = () => {
  
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation();
  const [ msgHolder, setMsgHolder ] = useState(new MsgHolder());
  const { consent, setConsent } = useConsent();
  const [ dsopen, setDsopen ] = useState();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
  
    const token = await executeRecaptcha('Kontakt');
    return token;
      // send token to server for verification
  }, [executeRecaptcha]);

  const updateConsent = () => {
    setConsent(!consent);
  }
  
  function initFormValues() {
    return ({
        company : '',
        name : '',
        email : '',
        phone : '',
        message : ''
    });
  }

  const ok = t('kontakt.ok');
  const error = t('kontakt.error');

  function createYupSchema() {
    return ( Yup.object({
      company: Yup.string()
        .min(3, t('kontakt.errFirmaKurz'))
        .max(50, t('kontakt.errFirmaLang')),
      name: Yup.string()
        .min(5, t('kontakt.errNameKurz'))
        .max(50, t('kontakt.errNameLang'))
        .required(t('kontakt.errNamePflicht')),
      phone: Yup.string()
        .min(8, t('kontakt.errTelKurz'))
        .max(20, t('kontakt.errTelLang')),
      email: Yup.string()
        .min(8, t('kontakt.errEmailKurz'))
        .max(50, t('kontakt.errEmailLang'))
        .email(t('kontakt.errEmailFormat'))
        .required(t('kontakt.errEmailPflicht')),
      message: Yup.string()
        .min(30, t('kontakt.errMsgKurz'))
        .max(1000, t('kontakt.errMsgLang'))
        .required(t('kontakt.errMsgPflicht'))
      }));
  }

  return( 
    <Fragment>
      <div className="articleSeperator" id="kontakt"></div>

      <article className="mb-4 contactForm">
        <div className="container">
          <div className="row row-cols-1 row-cols-lg-2 bsGutterX">
            <div className="col-lg-6 pb-4">
              <h2>{t('kontakt.title')}</h2>
              { !consent &&
                <Row className='mt-4 pt-4'>
                  <Col sm={1}>  
                    <Form.Switch value="false" 
                      onClick={updateConsent} />
                  </Col>
                  <Col>                                
                    <Form.Label>
                      {t('kontakt.dsConsent')}
                      <a href="#0" role='button' onClick={() => setDsopen(!dsopen)}
                        aria-controls="example-collapse-text" aria-expanded={dsopen}>
                        {t('kontakt.dsHeader')}
                      </a>.
                    </Form.Label>
                  </Col>
                </Row>
              }

              <MyAlert msgHolder={msgHolder}/>
              <Formik
                initialValues = {initFormValues()}
                validationSchema = {createYupSchema()}
                onSubmit = {async (values, { setSubmitting }) => {
                  const token = await handleReCaptchaVerify();
                  try {
                    values.token = token;
                    await axios.post('/customerMessage',
                      JSON.stringify(values), {
                        headers: {'Content-Type' : 'application/json'},
                        withCredentials : false }
                      );
                      setMsgHolder(new MsgHolder(ok, 'success'));
                  } catch (err) {
                    setMsgHolder(new MsgHolder(error));
                  }
                  setSubmitting(false);
                }}
              >
              {({
                  handleSubmit, handleChange, handleBlur,
                  isSubmitting, values, touched, errors
              }) => (
                  <Form onSubmit={handleSubmit} id="contactForm">
                      <Row className="pt-4">
                        <Col sm={6}>
                          <Form.Group controlId="validationCompany">
                              <Form.Label>{t('kontakt.firma')}</Form.Label>
                              <Form.Control type="text" name="company" 
                                  onChange={handleChange} onBlur={handleBlur} value={values.company} 
                                  maxLength="50"
                                  isValid={touched.company && !errors.company}
                                  isInvalid={errors.company} disabled={!consent}
                              />
                              <Form.Control.Feedback type="invalid">{errors.company}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="validationName">
                              <Form.Label>{t('kontakt.name')}</Form.Label>
                              <Form.Control type="text" name="name" 
                                  onChange={handleChange} onBlur={handleBlur} value={values.name}
                                  maxLength="50" 
                                  isValid={touched.name && !errors.name} isInvalid={errors.name}
                                  disabled={!consent}
                              />
                              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="pt-4">
                        <Col sm={6}>
                          <Form.Group controlId="validationEmail">
                              <Form.Label>{t('kontakt.email')}</Form.Label>
                              <Form.Control type="text" name="email" 
                                  onChange={handleChange} onBlur={handleBlur} value={values.email}
                                  maxLength="50" 
                                  isValid={touched.email && !errors.email} isInvalid={errors.email}
                                  disabled={!consent}
                              />
                              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="validationPhone">
                            <Form.Label>{t('kontakt.telefon')}</Form.Label>
                            <Form.Control type="text" name="phone" 
                                onChange={handleChange} onBlur={handleBlur} value={values.phone}
                                maxLength="20" 
                                isValid={touched.phone && !errors.phone} isInvalid={errors.phone}
                                disabled={!consent}
                            />
                            <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>                      
                      <Row className="pt-4">
                          <Form.Group controlId="validationMessage">
                              <Form.Label>{t('kontakt.nachricht')}</Form.Label>
                              <Form.Control as="textarea" name="message" cols="60" rows="5" 
                                  onChange={handleChange} onBlur={handleBlur} value={values.message}
                                  maxLength="1000" 
                                  isValid={touched.message && !errors.message} isInvalid={errors.message}
                                  disabled={!consent}
                              />
                              <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                          </Form.Group>
                      </Row>                                            
                      <Row className="pt-4 text-center">
                        <Col>
                          <Button className="btn-md publicBtn" variant="outline-success" type="submit" 
                            disabled={isSubmitting || !consent}>{t('kontakt.btnSenden')}
                          </Button>
                        </Col>
                      </Row>
                  </Form>
              )}
              </Formik>
            </div>

            { dsopen ? (
              <Row className='mt-4 pt-4 shadow rounded px-0'>
                <Col sm={3}>
                  <picture>
                    <source srcSet={cookieWebp} type="image/webp"/>
                    <img src={cookie} className="img-fluid" alt='hamster with cookie'></img>
                  </picture>
                </Col>
                <Col>
                  <strong>{t('kontakt.dsHeader')}</strong>
                  <br/>
                  {t('kontakt.dsPara1')}<br/><br/>
                  {t('kontakt.dsPara2')}
                  <br/><br/>
                  {t('kontakt.dsPara3')}
                  <br/><br/>
                  {t('kontakt.dsPara4')}
                  <br/><br/>
                  {t('kontakt.dsPara5')}
                </Col>
              </Row>
            ) : (
              <div className="col-lg-6 shadow rounded px-0">
                <div className="col-12">
                  <picture>
                    <source srcSet={mapWebp} type="image/webp"/>
                    <img src={map} className="img-fluid rounded-top" alt="map"/>
                  </picture>
                </div>
                <div className="col-12 px-5 py-4">
                  <h3>{t('kontakt.subheader')}</h3>
                  <h4>IIDC-Austria / Main Office</h4>
                  <span className="text-nowrap">Johann-Roithner-Str. 131</span> | <span className="text-nowrap">A-4050 Traun</span> | <span className="text-nowrap">+43 (0) 699 884 658 04</span>
                  <h4>IIDC-Germany</h4>
                  <span className="text-nowrap">Martin Seitz Str. 5</span> | <span className="text-nowrap">D-94036 Passau</span> | <span className="text-nowrap">+43 (0) 699 884 658 04</span>
                  <h4>IIDC-Hungary</h4>
                  <span className="text-nowrap">Váci út 47</span> | <span className="text-nowrap">H-1134 Budapest</span> | <span className="text-nowrap">+36-30-850-6936</span>
                  <h4>IIDC-France</h4>
                  <span className="text-nowrap">1, Rue de la Haye</span> | <span className="text-nowrap">F-95731 Roissy CDG</span> | <span className="text-nowrap">+43 (0) 699 171 875 83</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </article>
    </Fragment>
  )
}
export default Kontakt