import { Outlet } from "react-router-dom"
import Header from "./Header"
import NavigationBar from "./NavigationBar"
import { Container, Col, Row } from "react-bootstrap";
import '../assets/css/theme.css';

const Layout = () => {
    return (
        <main className="App">
            <div>
                <Header/>
                <Container fluid>
                    <Row className="flex-nowrap">
                        <NavigationBar/>
                        <Col id="main" className="article2 pt-50">
                            <Outlet />
                        </Col>
                    </Row>
                </Container>
            </div>
        </main>
    )
}

export default Layout