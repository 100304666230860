import { Fragment } from "react";
import { useTranslation } from 'react-i18next';
import spice from "../../assets/images/spice1.jpeg";
import spiceWebp from "../../assets/images/spice1.webp";

const Panel = () => {
  const { t } = useTranslation();
  return( 
    <Fragment>
      <div className="articleSeperator"></div>
      <article id="panel" className="mb-4">
        <div className="container">
          <div className="row bsGutterX">
            <div className="col">
              <h2>{t('panel.title')}<br/>{t('panel.subtitle')}</h2>
              <p>
                {t('panel.para1')}
              </p>
            </div>
          </div>
          <div className="row bsGutterX">
            <div className="col">
              <ul>
                <li>{t('panel.li1')}</li>
                <li>{t('panel.li2')}</li>
                <li>{t('panel.li3')}</li>
                <li>{t('panel.li4')}</li>
                <li>{t('panel.li5')}</li>
                <li>{t('panel.li6')}</li>
                <li>{t('panel.li7')}</li>
              </ul>
            </div>
            <div className="col">
              <ul>
                <li>{t('panel.li8')}</li>
                <li>{t('panel.li9')}</li>
                <li>{t('panel.li10')}</li>
                <li>{t('panel.li11')}</li>
                <li>{t('panel.li12')}</li>
                <li>{t('panel.li13')}</li>
                <li>{t('panel.li14')}</li>
              </ul>
            </div>
          </div>
          <div className="row bsGutterX">
            <div className="col">
              <p>
                {t('panel.para2')}
              </p>
              <p>
                {t('panel.para3')}
              </p>
            </div>
          </div>
        </div>
      </article>
    </Fragment>
  )
}
export default Panel