import { useNavigate } from "react-router-dom"
import { Fragment } from "react";
import { Button } from "react-bootstrap";
import Header from "../fragments/public/Header"
import Footer2 from "../fragments/public/Footer2"
import { useTranslation } from 'react-i18next';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/iidc.css';

const Impressum = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <Fragment>
            <Header/>
            <main>
            <div className="articleSeperator2"></div>
              <article id="impressum" className="article2">
                <div className="container ">
                  <h2 className="text-center mt-0 pt-4 pb-3">{t('impressum.title')}</h2>
                  <div>
                    <h4>{t('impressum.header1')}</h4>
                    <p>
                      IIDC Austria Islamic Information Documentation and Certification GmbH<br/>
                      Johann-Roithner-Str. 131 | A-4050 Traun<br/><br/>
                      Tel.: +43 (0) 699 884 658 04<br/>
                      E-Mail: office@iidc.at<br/>
                      Internet: www.iidc.at<br/>
                    </p>
                    <h4>{t('impressum.header2')}</h4>
                    <p>
                      Oberbank Traun<br/>
                      IBAN: AT17 1500 3007 6112 5616<br/>
                      BIC: 0BKLAT2L<br/>
                    </p>
                    <h4>{t('impressum.header3')}</h4>
                    <p>
                      {t('impressum.line1')}<br/>
                      {t('impressum.line2')}<br/>
                      {t('impressum.line3')}<br/>
                      {t('impressum.line4')}<br/>
                      {t('impressum.line5')}<br/>
                      {t('impressum.line6')}<br/>
                    </p>
                    <p>{t('impressum.para1')}<br/></p>
                      {t('impressum.line7')}<br/>
                      {t('impressum.line8')}<br/>
                      {t('impressum.line10')}<a href="https://www.ris.bka.gv.at/Bundesrecht/">{t('impressum.link1')}</a><br/>
                      {t('impressum.line11')}<a href="https://www.ris.bka.gv.at/Ergebnis.wxe?Abfrage=Bundesnormen&Kundmachungsorgan=&Index=&Titel=GEwo&Gesetzesnummer=&VonArtikel=&BisArtikel=&VonParagraf=&BisParagraf=&VonAnlage=&BisAnlage=&Typ=&Kundmachungsnummer=&Unterzeichnungsdatum=&FassungVom=06.06.2017&VonInkrafttretedatum=&BisInkrafttretedatum=&VonAusserkrafttretedatum=&BisAusserkrafttretedatum=&NormabschnittnummerKombination=Und&ImRisSeit=Undefined&ResultPageSize=100&Suchworte=&Position=1">{t('impressum.link2')}</a>
                  </div>
                  <div className="text-center mt-4 pt-4">
                    <Button className="btn-md publicBtn" variant="outline-success" 
                    href="/">{t('impressum.btnBack')}</Button>
                  </div>
                </div>
              </article>
            </main>
            <Footer2/>
        </Fragment>
    )
}

export default Impressum