import { Fragment } from "react";
import { useTranslation } from 'react-i18next';
import info1Webp from "../../assets/images/Info_Circle_1.webp";
import info2Webp from "../../assets/images/Info_Circle_2.webp";
import info3Webp from "../../assets/images/Info_Circle_3.webp";
import info4Webp from "../../assets/images/Info_Circle_4.webp";
import info1 from "../../assets/images/Info_Circle_1.png";
import info2 from "../../assets/images/Info_Circle_2.png";
import info3 from "../../assets/images/Info_Circle_3.png";
import info4 from "../../assets/images/Info_Circle_4.png";

const Wachstum = () => {
  const { t } = useTranslation();
  return( 
    <Fragment>
      <article id="wachstum" className="article3 container-fluid">
        <div className="container text-center text-white">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-6 mx-auto">
              <h2 className="text-white"><p>{t('wachstum.title')}</p></h2>
              <p>{t('wachstum.para1')}</p>
            </div>

            <div className="row pt-4 text-center">
              <div className="col-6 col-md-3">
                <picture>
                  <source srcSet={info1Webp} type="image/webp"/>
                  <img src={info1} className="infoCircle" alt="Muslime"/>
                </picture>
                <span className="infoCircleText1">{t('wachstum.bubble11')}</span>
                <span className="infoCircleText2">{t('wachstum.bubble12')}</span>
              </div>
              <div className="col-6 col-md-3">
                <picture>
                  <source srcSet={info2Webp} type="image/webp"/>
                  <img src={info2} className="infoCircle" alt="Länder"/>
                </picture>
                <span className="infoCircleText1">{t('wachstum.bubble21')}</span>
                <span className="infoCircleText2">{t('wachstum.bubble22')}</span>
              </div>
              <div className="col-6 col-md-3">
                <picture>
                  <source srcSet={info3Webp} type="image/webp"/>
                  <img src={info3} className="infoCircle" alt="Europa"/>
                </picture>
                <span className="infoCircleText1">{t('wachstum.bubble31')}</span>
                <span className="infoCircleText2">{t('wachstum.bubble32')}</span>
              </div>
              <div className="col-6 col-md-3">
                <picture>
                  <source srcSet={info4Webp} type="image/webp"/>
                  <img src={info4} className="infoCircle" alt="Exporte"/>
                </picture>
                <span className="infoCircleText1">{t('wachstum.bubble41')}</span>
                <span className="infoCircleText2">{t('wachstum.bubble42')}</span>
              </div>
            </div>  
        </div>
      </article>
    </Fragment>
  )
}
export default Wachstum