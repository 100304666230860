import { Fragment } from "react";
import { Card, Row, Col } from "react-bootstrap";
import Address from "../../fragments/Address";
const ProductionSites = (props) => {
	const { certificate, client} = props;

	return( 
		<Fragment>
			{certificate?.productionSites?.length > 0 && ( 
				<div>
					<Card.Title>Produktionsstätten</Card.Title>
					{ client?.productionSites?.map((site, i) =>
						{
							if (certificate.productionSites.includes(site._id)) return (
								<Row className="mb-3" key={'site'+i}>
									<Col>
										<Address address={site.address}/>            
									</Col>
								</Row>
							)
						}
					)}
				</div> 
			)}
		</Fragment>
	)
}
export default ProductionSites