import { Fragment } from "react";
import { useTranslation } from 'react-i18next';

const Leistungen = () => {
  const { t } = useTranslation();
  return( 
    <Fragment>
      <div className="articleSeperator2"></div>
      <article id="leistungen" className="article2">
        <div className="container">
          <div className="row row-cols-1 row-cols-lg-2 bsGutterX">
            <div className="col col-lg-6">
              <h3>{t('leistung.title')}</h3>
              <p>
                {t('leistung.para1_1')} <strong>{t('leistung.para1_2')}</strong>{t('leistung.para1_3')}
                <strong>{t('leistung.para1_4')}</strong>{t('leistung.para1_5')}<strong>{t('leistung.para1_6')}</strong>
                {t('leistung.para1_7')}
              </p>
              <div className="text-center mt-5">
                <a className="toggleLink toggleHalal btn btn-outline-success publicBtn mb-5" data-bs-toggle="collapse" href="#collapseHalal" role="button" aria-expanded="false" aria-controls="collapseHalal">
                  {t('leistung.btnHalal')}
                  <span className="text-collapsed"><i className="bi bi-chevron-down"></i></span>
                  <span className="text-expanded"><i className="bi bi-chevron-up"></i></span>
                </a>
              </div>
            </div>
            <div className="col col-lg-6">
              <div className="backgroundGreen rounded px-4 py-2">
                <h3>{t('leistung.subheader')}</h3>
                <ul>
                  <li>{t('leistung.li1')}</li>
                  <li>{t('leistung.li2')}</li>
                  <li>{t('leistung.li3')}</li>
                  <li>{t('leistung.li4')}</li>
                </ul>
                <div className="collapse" id="collapseLeistungen">
                  <ul>
                  <li>{t('leistung.li5')}</li>
                  <li>{t('leistung.li6')}</li>
                  <li>{t('leistung.li7')}</li>
                  <li>{t('leistung.li8')}</li>
                  <li>{t('leistung.li9')}</li>
                  <li>{t('leistung.li10')}</li>
                  <li>{t('leistung.li11')}</li>
                  <li>{t('leistung.li12')}</li>
                  </ul>
                </div>
              </div>
              <div className="text-center mt-2 mb-2">
                <a className="toggleLink toggleLeistungen" data-bs-toggle="collapse" href="#collapseLeistungen" role="button" 
                  aria-expanded="false" aria-controls="collapseLeistungen" title="Leistungen">
                  <span className="text-collapsed"><i className="bi bi-chevron-down fs-1"></i></span>
                  <span className="text-expanded"><i className="bi bi-chevron-up fs-1"></i></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </article>
    </Fragment>
  )
}
export default Leistungen