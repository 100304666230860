import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button, Tab, Col, Row, Card, Nav } from "react-bootstrap";
import MyAlert from "./MyAlert";
import MsgHolder from "../util/MsgHolder";
import Address from "../fragments/Address";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import CertHeader from "../fragments/CertHeader";
import ProductList from "../fragments/ProductList";
import useAuth from "../hooks/useAuth";
import CertificateStatus from "../fragments/CertificateStatus";
import Stati from "../util/Stati";

const CertificationDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate(); 
    const [certification, setCertification] = useState();
    const [msgHolder, setMsgHolder] = useState(new MsgHolder());
    const [key, setKey] = useState('daten');
    const { auth } = useAuth();

    const getCertification = async (cid) => {
        const controller = new AbortController();
        console.log('getCertification: get certification from db.');
        try {
            const response = await axiosPrivate.get(`/certification/${cid}`, {
                signal : controller.signal
            });
            console.log('getCertification: api response:');
            console.log(response.data);
            setCertification(response.data);

        } catch (err) {
            console.log('getCertification: api responded with error:');
            console.log(err);
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }

    const stopApproval = async (certificationId, certificateId) => {
        const controller = new AbortController();
        console.log('stopApproval: stopping Approval.');
        try {
            const response = await axiosPrivate.delete(`/approval/add/certificate/${certificateId}/certification/${certificationId}`, {
                signal : controller.signal
            });
            setMsgHolder(new MsgHolder('Die Gehnemigung wurde gestoppt, das Zertifikat kann wieder bearbeitet werden.','success'));
            getCertification(certificationId);
        } catch (err) {
            console.log('getCertification: api responded with error:');
            console.log(err);
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }

    const publishCertificate = async (certificationId, certificateId) => {
        const controller = new AbortController();
        console.log('publishCertifikate.');
        try {
            const response = await axiosPrivate.post(`/publishCertificate/${certificateId}/certification/${certificationId}`, {
                signal : controller.signal
            });
            setMsgHolder(new MsgHolder('Das Zertifikat wurde veröffentlicht. Bitte den Kunden verständigen.','success'));
            getCertification(certificationId);
        } catch (err) {
            console.log('getCertification: api responded with error:');
            console.log(err);
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }

    useEffect(() => {
        let params = new URLSearchParams(document.location.search);
        let cid = params.get("cid");
        let key = params.get("key");
        if (key) {
            setKey(key);
        }
        console.log('useEffect... calling getCertification with id: ' + cid);
        getCertification(cid);
        if (location.state?.msgHolder) {
            console.log('setting msg from location: ' , location.state?.msgHolder );
            setMsgHolder(location.state?.msgHolder);
        }
        
        return () => {
        }
    }, []);

    return (
        <section>
            <Card className="inputCard shadow-sm">
                <Tab.Container id="myTabs"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                >
                    <Nav variant="tabs">
                        <Nav.Item className="myTab">
                            <Nav.Link className="myTabLink" eventKey="daten">Allgemeine Daten</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="myTab">
                            <Nav.Link className="myTabLink" eventKey="produkte">Produkte</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="myTab">
                            <Nav.Link className="myTabLink" eventKey="zertifikate">Zertifikate</Nav.Link>
                        </Nav.Item>                                                
                    </Nav>
                    
                    <MyAlert msgHolder={msgHolder}/>
                    <Tab.Content>
                        <Card.Header>
                            <CertHeader cert={certification}/>
                        </Card.Header>
                        <Tab.Pane eventKey="daten">
                            <Card.Body>
                                <Card.Title className="mt-4">Daten zur Zertifizierung</Card.Title>
                                <Card.Title>Hersteller</Card.Title>
                                <Row className="mb-2">
                                    <Col sm={2}>Name:</Col>
                                    <Col>
                                        { certification?.productionSite ?
                                            (certification?.productionSite.name) : 
                                            (certification?.clientName)
                                        }
                                     </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col sm={2}>Adresse:</Col>
                                    <Col>
                                        { certification?.productionSite ?
                                            (<Address address={certification?.productionSite?.address}/>) : 
                                            (<Address address={certification?.clientAddress}/>)
                                        }
                                    </Col>
                                </Row>

                                <Card.Title>Gültigkeit</Card.Title>
                                <Row>
                                    <Col sm={2}>Erstzertifizierung: </Col>
                                    <Col>{certification?.initialValidFrom ? new Date(certification.initialValidFrom).toLocaleDateString("DE") : 'noch nicht gesetzt.'}</Col>
                                </Row>
                                <Row>
                                    <Col sm={2}>Gültig ab: </Col>
                                    <Col>{certification?.validFrom ? new Date(certification.validFrom).toLocaleDateString("DE") : 'noch nicht gesetzt.'}</Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col sm={2}>Gültig bis: </Col>
                                    <Col>{certification?.validTo ? new Date(certification.validTo).toLocaleDateString("DE") : 'noch nicht gesetzt.'}</Col>
                                </Row>

                                <Card.Title>Scope</Card.Title>
                                <Row className="mb-2">
                                    <Col sm={2}>Kategorie:</Col>
                                    <Col>{certification?.category}</Col>
                                </Row>
                                { certification?.subcategory && ( 
                                <Row className="mb-2">
                                    <Col sm={2}>Subkategorie:</Col>
                                    <Col>{certification?.subcategory}</Col>
                                </Row>
                                )}
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button variant="outline-primary"
                                        onClick={() => { 
                                        navigate({
                                            pathname: "/addCertification",
                                            search: `certId=${certification._id}&cid=${certification._clientId}`
                                        })}}
                                        >Bearbeiten</Button>
                            </Card.Footer>
                        </Tab.Pane>
                        <Tab.Pane eventKey="produkte">
                            <Card.Body>
                                <ProductList certification={certification}/>
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button variant="outline-primary"
                                    onClick={() => { 
                                    navigate({
                                        pathname: "/editProducts",
                                        search: `certId=${certification._id}&cid=${certification._clientId}`
                                    })}}
                                    >
                                        Bearbeiten
                                </Button>
                            </Card.Footer> 
                        </Tab.Pane>
                        <Tab.Pane eventKey="zertifikate">
                            <Card.Body>
                                {certification?.products.length > 0 ? (
                                    <div>
                                        <Card.Title className="mt-4">Zertifikate</Card.Title>
                                        {certification?.certificates?.length > 0 ? (
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Nummer</th>
                                                        <th scope="col">Gültig von</th>
                                                        <th scope="col">Gültig bis</th>
                                                        <th scope="col">Produkte</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { certification.certificates.map((certificate, i) =>
                                                        <tr className="mb-2" key={'product'+i}>
                                                            <td>
                                                                {certificate.certificateNumber}
                                                            </td>
                                                            <td>
                                                                {new Date(certificate.validFrom).toLocaleDateString("DE")}
                                                            </td>
                                                            <td>
                                                                {new Date(certificate.validTo).toLocaleDateString("DE")}
                                                            </td>
                                                            <td>
                                                                {certificate.products?.length}
                                                            </td>
                                                            <td>
                                                                <CertificateStatus cert={certificate}/>
                                                            </td>
                                                            <td>
                                                                { certificate.stati[certificate.stati.length-1].status != Stati.CERTIFICATE_STATUS.WIP &&
                                                                    <Button className="btn-sm mx-1" variant="outline-primary" id="editButton"
                                                                        onClick={() => {
                                                                            navigate({
                                                                                pathname: '/certificateDetails',
                                                                                search: `certificationId=${certification._id}&certificateId=${certificate._id}&clientId=${certification._clientId}`
                                                                            });
                                                                        }}>
                                                                        Details
                                                                    </Button>
                                                                }
                                                                { (certificate.stati[certificate.stati.length-1].status == Stati.CERTIFICATE_STATUS.WIP ||
                                                                  certificate.stati[certificate.stati.length-1].status == Stati.CERTIFICATE_STATUS.REJECTED) && 
                                                                    <Button className="btn-sm mx-1" variant="outline-primary" id="detailsButton"        
                                                                        onClick={() => { 
                                                                            navigate({
                                                                                pathname: '/addCertificate',
                                                                                search: `certificationId=${certification._id}&certificateId=${certificate._id}&clientId=${certification._clientId}`
                                                                            });
                                                                        }}>
                                                                        Bearbeiten
                                                                    </Button>
                                                                }
                                                                { certificate.stati[certificate.stati.length-1].status == Stati.CERTIFICATE_STATUS.IN_APPROVAL && 
                                                                    <span>
                                                                        <Button className="btn-sm mx-1" variant="outline-danger" id="stopApprovalButton"
                                                                            onClick={() => {
                                                                                stopApproval(certification?._id, certificate?._id);
                                                                            }}>
                                                                            Genehigung stoppen
                                                                        </Button>
                                                                        {auth?.roles?.includes("Admin") && 
                                                                            <Button className="btn-sm mx-1" variant="outline-success" id="approvalButton"
                                                                                onClick={() => {
                                                                                    navigate({
                                                                                        pathname: '/certificateApproval',
                                                                                        search: `certificationId=${certification._id}&certificateId=${certificate._id}&clientId=${certification._clientId}`
                                                                                    });
                                                                                }}>
                                                                                Unterschreiben
                                                                            </Button>
                                                                        }
                                                                    </span>
                                                                }
                                                                { certificate.stati[certificate.stati.length-1].status == Stati.CERTIFICATE_STATUS.APPROVED && 
                                                                    <Button className="btn-sm mx-1" variant="outline-success" id="stopApprovalButton"
                                                                        onClick={() => {
                                                                            publishCertificate(certification?._id, certificate?._id);
                                                                        }}>
                                                                        veröffentlichen
                                                                    </Button>
                                                                }
                                                                { certificate.stati[certificate.stati.length-1].status == Stati.CERTIFICATE_STATUS.PUBLIC && 
                                                                    auth?.roles?.includes("Admin") && 
                                                                    <Button className="btn-sm mx-1" variant="outline-danger" id="retractButton"
                                                                        onClick={() => {
                                                                            navigate({
                                                                                pathname: '/certificateApproval',
                                                                                search: `certificationId=${certification._id}&certificateId=${certificate._id}&clientId=${certification._clientId}&retract=true`
                                                                            });
                                                                        }}>
                                                                        zurückziehen
                                                                    </Button>
                                                                }                                                                                                                                                                                              
                                                            </td>                                                                                                                                                            
                                                        </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div>
                                                Es wurden noch keine Zertifikate erfasst.
                                            </div>
                                        ) }
                                    </div>
                                ) : (
                                    <div>
                                        <br/>
                                        <br/>
                                        Es wurden noch keine Produkte erfasst.<br/>
                                        Das erstellen von Zertifikaten ist erst nach dem Erfassen der Produkte möglich.
                                        <br/>
                                        <br/>
                                    </div>
                                ) }

                            </Card.Body>
                            <Card.Footer className="text-end">
                                {certification?.products.length > 0 && (
                                    <Button variant="outline-primary"
                                        onClick={() => { 
                                        navigate({pathname: "/addCertificate",
                                            search: `certificationId=${certification._id}&clientId=${certification._clientId}`
                                        })}}>
                                        <span>Neues Zertifikat erstellen</span>
                                    </Button>
                                ) }
                            </Card.Footer>                                
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </Card>
        </section>
    )
}

export default CertificationDetails