import { Badge, Button } from 'react-bootstrap';
import { Fragment } from "react";
const CertStatus = (certProp) => {

  if (!certProp.cert || !certProp.cert.stati) {
    console.error("certification.cert was: " , certProp.cert);
    return;
  }

  const { stati } = certProp.cert;
  var status = stati[stati.length - 1].status;
  var variant;
  var txt;
  /*    New: 10,
    Products: 20,
    Audit: 30,
    Certificate: 40,
    Approval: 50,
    Publish: 60,
    Retracted: 70,
    Renewal: 80,
    Expired: 90*/
console.log('status: ' + status);
  switch(true) {
    case status < 20 :
      variant = 'primary';
      txt = 'Neu';
      break;
    case status >= 20 && status < 30:
      variant = 'secondary';
      txt = 'Produktprüfung';
      break;
    case status >= 30 && status < 40:
      variant = 'secondary';
      txt = 'Audit';
      break;
    case status >= 40 && status < 50:
      variant = 'info';
      txt = 'Zertifikat erstellt';
      break;
    case status >= 50 && status < 60:
      variant = 'warning';
      txt = 'Genehmigung';
      break;      
    case status >= 60 && status < 70:
      variant = 'success';
      txt = 'Aktiv';
      break;
    case status >= 80 && status < 90:
      variant = 'danger';
      txt = 'Zurückgezogen';
      break;      
    case status >= 90 && status < 90:
      variant = 'dark';
      txt = 'Abgelaufen';
      break;
    default:
      break;           
  }

  return ( 
    <Fragment>
      <Badge bg={variant}>
        {txt}
      </Badge>
    </Fragment>
  )
}
export default CertStatus