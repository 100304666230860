import { useNavigate } from "react-router-dom"
import { Fragment } from "react";
import Header from "../fragments/public/Header"
import Hero from "../fragments/public/Hero"
import Vorteile from "../fragments/public/Vorteile"
import Leistungen from "../fragments/public/Leistungen"
import Halal from "../fragments/public/Halal"
import Wachstum from "../fragments/public/Wachstum"
import Zertifizierung from "../fragments/public/Zertifizierung"
import Akkreditierungen from "../fragments/public/Akkreditierungen"
import Kontakt from "../fragments/public/Kontakt"
import About from "../fragments/public/About"
import Team from "../fragments/public/Team"
import Footer from "../fragments/public/Footer"
import Panel from "../fragments/public/Panel"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../assets/css/iidc.css';

const Index = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <Fragment>
            <Header/>
            <main>
                <Hero/>
                <Vorteile/>
                <Leistungen/>
                <Halal/>
                <Wachstum/>
                <Zertifizierung/>
                <Akkreditierungen/>
                <Kontakt/>
                <Panel/>
                <About/>
                <Team/>
            </main>
            <Footer/>
        </Fragment>
    )
}

export default Index