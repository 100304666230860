import { Fragment } from "react";
const Categories = () => {
  return( 
      <Fragment>
        <option></option>
        <option>A: Farming of Animals</option>
        <option>C: Food Manufacturing</option>
        <option>E: Catering</option>
        <option>K: Production of (Bio) Chemicals</option>
        <option>I: Production of Food Packaging and Packaging Material</option>
      </Fragment>
  )
}
const Subcategories = (categoryProp) => {
  console.log("SUBCAT")
  const { category } = categoryProp;
  console.log(category)
  var options = new Array();
  if (category) {
    if (category.startsWith('A:')) {
      options.push('AI: Farming of Animals for Meat / Milk / Eggs / Honey');
    } else if (category.startsWith('C:')) {
      options.push('CI:  Processing of perishable animal products');
      options.push('CII: Processing of perishable plant products');
      options.push('CIV: Processing of ambient stable products');
      options.push('CV:  Animal Slaughtering');
    }
  }
  return ( 
      <Fragment>
        <option></option>
        { options.map((opt, i) =>
          <option key={'subcategory'+i}>{opt}</option>
        )}
      </Fragment>
  )
}
export {Categories, Subcategories}