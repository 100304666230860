import { Fragment } from "react";
import { useTranslation } from 'react-i18next';
import camel from "../../assets/images/camel.jpeg";
import camelWebp from "../../assets/images/camel.webp";
import president from "../../assets/images/guenther_ahmed_rusznak.jpg";
import presidentWebp from "../../assets/images/guenther_ahmed_rusznak.webp";

const About = () => {
  const { t } = useTranslation();
  return( 
    <Fragment>
      <div className="articleSeperator2"></div>
      <article id="about" className="article2">
        <div className="container ">
          <h2 className="text-center mt-0 pt-4 pb-3">{t('about.title')}</h2>
          <div className="row row-cols-1 row-cols-lg-2 bsGutterX">
            <div className="col col-lg-6">
              <p>
                <strong>{t('about.para11')} </strong>
                {t('about.para12')}
              </p>
              <p>
              {t('about.para2')}
              </p>
              <p>
              {t('about.para3')}
              </p>
              <picture>
                <source srcSet={camelWebp} type="image/webp"/>
                <img src={camel} className="img-fluid mx-auto d-block rounded" alt="Friendly Camel"/>
              </picture>
            </div>              
            <div className="col col-lg-6 rounded px-5 pt-5 pb-3 mt-4 mt-lg-0 backgroundGreen">
              <div className="row pb-3">
                <div className="col-3">
                  <picture>
                    <source srcSet={presidentWebp} type="image/webp"/>
                    <img src={president} className="img-fluid mx-auto d-block rounded" alt="Günther Ahmed Rusznak - IIDC Präsident"/>
                  </picture>
                </div>
                <div className="col-9">
                  {t('about.subheader11')}
                  <h4>Günther Ahmed Rusznak</h4>
                  {t('about.subheader12')}
                </div>
              </div>
              <p>{t('about.para4')}</p>
              <p>{t('about.para5')}</p>
              <p>{t('about.para6')}</p>
              <p>{t('about.para7')}</p>
            </div>
          </div>          
        </div>
      </article>
    </Fragment>
  )
}
export default About