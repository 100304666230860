import { Fragment } from "react";
import { Card } from "react-bootstrap";
import CertificateStatus from "../../fragments/CertificateStatus";
const History = (prop) => {
  const { certificate } = prop;

  const statusRow = (status) => {
	return (
		<tr className="mb-2" key={status._id}>
			<td>
				{new Date(status?.ts).toLocaleString("DE")}
			</td>
			<td>
				{status?.user}
			</td>
			<td>
				<CertificateStatus status={status?.status}/>
			</td>
			<td>
				{status?.reason}
			</td>
		</tr>
	);
}

  return( 
	<Fragment>
		<Card.Title>Verlauf</Card.Title>
		<table className="table table-striped">
			<thead>
				<tr>
					<th scope="col">Zeitpunkt</th>
					<th scope="col">Name</th>
					<th scope="col">Status</th>
					<th scope="col">Begründung</th>
				</tr>
			</thead>
			<tbody>
				{ certificate?.stati?.toReversed().map((status, i) =>
					statusRow(status)
				)}
			</tbody>
		</table>
	</Fragment>
  )
}
export default History