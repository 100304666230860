import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button, Card, ButtonGroup, Table, Form } from "react-bootstrap";
import { Formik } from 'formik';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import MyAlert from "./MyAlert";
import MsgHolder from "../util/MsgHolder";
import * as Yup from 'yup';




const CustomerMessage = () => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate(); 
    const [message, setMessage] = useState();
    const [msgHolder, setMsgHolder] = useState(new MsgHolder());
    const errorMsg = "Die Anfrage konnte nicht verarbeitet werden.";
   
    const getCustomerMessage = async (id) => {
        const controller = new AbortController();
        console.log('getCustomerMessage: fetching customerMessage from db.');
        try {
            const response = await axiosPrivate.get(`/customerMessages/${id}`, {
                signal : controller.signal
            });
            console.log('getCustomerMessage: api response:');
            console.log(response.data);
            setMessage(response.data);
        } catch (err) {
            console.log('getCustomerMessage: api responded with error:');
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }

    const submit = async (values) => {
        console.log('CustomerMessage.submit: ' + values.submitBtn);
        const controller = new AbortController();
        var response = null;
        var msgHolder = null;
        try {
            console.log("CustomerMessage.submit...");
            if (values.submitBtn === 'del') {
                response = await axiosPrivate.delete('/customerMessages/' + message._id, {
                    signal : controller.signal
                });
                //msgHolder = new MsgHolder('Benutzerdaten wurden geändert.', 'success');
            } else {
                response = await axiosPrivate.put('/customerMessages/' + message._id, JSON.stringify(values), {
                    signal : controller.signal
                });
                //msgHolder = new MsgHolder(okMsg);
            }
            console.log("CustomerMessage.submit:  Response: " + JSON.stringify(response?.data));
            navigate('/home', {state : {id : 1 , msgHolder : msgHolder}});
        } catch (err) {
            setMsgHolder(new MsgHolder(errorMsg));
        }
    }

    useEffect(() => {
        console.log('CustomerMessage... calling getCustomerMessage');
        let params = new URLSearchParams(document.location.search);
        let id = params.get("id");
        getCustomerMessage(id);
        return () => {
        }
    }, []);

    return (
        <section>
            <h2>Anfrage vom {new Date(message?.messageDate).toLocaleString("DE")}</h2>
            <MyAlert msgHolder={msgHolder}/>
            
            <Formik
                initialValues = {{ 'note' : message?.note}}
                enableReinitialize = 'true'
                validationSchema = {
                    Yup.object(
                    { 'note' : Yup.string().
                        when('submitBtn', {
                            is: (submitBtn) => submitBtn === 'save' || submitBtn === 'answer',
                            then: Yup.string().max(2000, 'Die Anmerkung darf höchstens 2000 Zeichen haben.').
                                required('Die Anmerkung muss eingegeben werden.'),
                            otherwise: Yup.string()
                        })
                    })
                }
              onSubmit = {submit}
            >
            {({
                handleSubmit, handleChange, handleBlur,
                setFieldValue, values, touched, errors
            }) => (   
            <Form onSubmit={handleSubmit}>
                <Card className="inputCard shadow-sm">             
                    <Card.Body>
                        <Table className="table-striped">
                            <thead>        
                                <tr>
                                    <th>Firma</th>
                                    <th>Name</th>
                                    <th>E-Mail</th>
                                    <th>Telefonnummer</th>
                                </tr>email phone
                            </thead>
                            <tbody>
                                <tr key={message?._id}>
                                    <td>{message?.company}</td>
                                    <td>{message?.name}</td>
                                    <td>{message?.email}</td>
                                    <td>{message?.phone}</td>
                                </tr>
                            </tbody>                                                          
                        </Table>

                        <div>{message?.message}</div>
                        <hr/>
                        <Form.Group controlId="validationMessage">
                            <Form.Label>Anmerkung:</Form.Label>
                            <Form.Control as="textarea" name="note" cols="60" rows="5" 
                                onChange={handleChange} onBlur={handleBlur} value={values.note}
                                maxLength="2000" 
                                isValid={touched.note && !errors.note}
                                isInvalid={errors.note}
                            />
                            <Form.Control.Feedback type="invalid">{errors.note}</Form.Control.Feedback>
                        </Form.Group>
                    </Card.Body>
                    <Card.Footer className="text-end">
                        <ButtonGroup>
                            <Button variant="outline-danger" type="submit"
                                onClick={() => {
                                    setFieldValue('submitBtn', 'del');
                                }}
                            >Löschen</Button>
                            <Button variant="outline-primary" type="submit"
                                onClick={() => {
                                    setFieldValue('submitBtn', 'save');
                                }}
                            >Speichern</Button>                    
                            <Button variant="outline-success" type="submit"
                                onClick={() => {
                                    setFieldValue('submitBtn', 'answer');
                                }}
                            >Beantwortet</Button>  
                        </ButtonGroup>                  
                    </Card.Footer>
                </Card>
            </Form>
            )}
            </Formik>
        </section>
    )
}

export default CustomerMessage