import { Fragment } from "react";
import { useState, useEffect } from "react";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import ablaufgrafikDe from "../../assets/images/ablaufgrafik-de.jpg";
import ablaufgrafikDeWebp from "../../assets/images/ablaufgrafik-de.webp";
import ablaufgrafikEn from "../../assets/images/ablaufgrafik-en.jpg";
import ablaufgrafikEnWebp from "../../assets/images/ablaufgrafik-en.webp";
import ablaufgrafikHu from "../../assets/images/ablaufgrafik-hu.jpg";
import ablaufgrafikHuWebp from "../../assets/images/ablaufgrafik-hu.webp";

const Zertifizierung = () => {
  const { t } = useTranslation();
  const [image, setImage] = useState(ablaufgrafikDe);
  const [imageWebp, setImageWebp] = useState(ablaufgrafikDeWebp);
  useEffect(() => {
    switch (i18n?.resolvedLanguage) {
      case 'de': 
        setImage(ablaufgrafikDe);
        setImageWebp(ablaufgrafikDeWebp);
        break;
      case 'en': 
        setImage(ablaufgrafikEn);
        setImageWebp(ablaufgrafikEnWebp);
        break;
      case 'mg': 
        setImage(ablaufgrafikHu);
        setImageWebp(ablaufgrafikHuWebp);
        break;        
      default:
        setImage(ablaufgrafikDe);
        setImageWebp(ablaufgrafikDeWebp);
    }
    return () => {
    }
  }, [i18n?.resolvedLanguage]);
  return( 
    <Fragment>
      <article id="zertifizierung" className="mb-5 pt-5">
        <div className="container">
          <h2 className="text-center">{t('zertifizierung.title')}</h2>
          <div className="row row-cols-1 row-cols-lg-2 bsGutterX">
            <div className="col col-lg-6 pt-5">
              <p>{t('zertifizierung.para1')}</p>
              <p>{t('zertifizierung.para2')}</p>
              <p>{t('zertifizierung.para3')}</p>
              <h3>{t('zertifizierung.subheader')}</h3>
              <p>{t('zertifizierung.para4')}</p>
            </div>              
            <div className="col col-lg-6 pt-lg-4">

              <picture>
                <source srcSet={imageWebp} type="image/webp"/>
                <img src={image} className="img-fluid mx-auto d-block" alt="Ablauf der Zertifizierung"/>
              </picture>

            </div>
          </div>
        </div>
      </article>
    </Fragment>
  )
}
export default Zertifizierung