class MsgHolder {

  msg = '';
  variant = 'warning';
  
  errors = [];
  warnings = [];
  infos = [];
  ok = [];

  constructor(msg, variant = 'danger') {
    this.msg = msg;
    this.variant = variant;
  }

  setError(error) {
    this.errors = new Array();
    this.errors.push(error);
  }
  addError(error) {
    this.errors.push(error);
  }

  addWarning(warning) {
    this.warnings.push(warning);
    }

  addInfo(info) {
    this.infos.push(info);
    }

   setOk(ok) {
      this.ok = new Array();
      this.ok.push(ok);
    }
  
  addOk(ok) {
    this.ok.push(ok);
  }

  isError() {
    console.log("this.errors.length " + this.errors.length);
    if (this.errors.length > 0) {
      return true
    } else {
      return false;
    }
  }

  debug() {
    console.log('MsgHolder:');
    console.log(`Msg: ${this.msg} Variant: ${this.variant}`);
    console.log(`Errors: ${this.errors.length} Warnings: ${this.warnings.length} Infos: ${this.infos.length} Ok: ${this.ok.length}`);
  }
};

export default MsgHolder;