import { Card } from "react-bootstrap";
import CustomerMessages from "./CustomerMessages";
import CertificateApprovalsList from "./CertificateApprovalsList";
import useAuth from "../hooks/useAuth";

const Home = () => {
    const { auth } = useAuth();
    
    return (
        <section>
            <CustomerMessages/>
            <br/>
            { auth?.roles?.includes("Admin") &&
                <CertificateApprovalsList/>
            }
            <br/>
            <Card>
                <Card.Header>
                    Willkommen beim IIDC Mitarbeiterportal!
                </Card.Header>
                <Card.Body>
                    <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>
                                Version
                            </th>
                            <th>
                                Datum
                            </th>
                            <th>
                                Änderungen
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1.01.04</td>
                            <td>26.04.2023</td>
                            <td>
                                Bilder auf webp umgestellt.
                            </td>
                        </tr>  
                        <tr>
                            <td>1.01.03</td>
                            <td>26.04.2023</td>
                            <td>
                                Rechnungsadresse
                            </td>
                        </tr>                         
                        <tr>
                            <td>1.01.02</td>
                            <td>07.04.2023</td>
                            <td>
                                <ul>
                                    <li>
                                        Fehlerbehebung:<br/>
                                        <ul>
                                            <li>
                                                Länder-Dropdowns ergänzt.
                                            </li>
                                            <li>
                                                Kundenname jetzt 50 Zeichen.
                                            </li>
                                            <li>
                                                Löschen von Produkten ausgebessert.
                                            </li>
                                            <li>
                                                Formatierung bei erstellten Zertifikaten verbessert.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Produkte: Import von Daten aus Excel-Datei eingebaut.
                                    </li>
                                    <li>
                                        Produkte: Prüfung auf eindeutige Nummer und Namen.
                                    </li>                                    
                                </ul>
                            </td>
                        </tr>                         
                        <tr>
                            <td>1.01.01</td>
                            <td>02.04.2023</td>
                            <td>
                                Abweichende Produktionsstätte im Zertifikat richtig anzeigen.
                            </td>
                        </tr>                        
                        <tr>
                            <td>1.01</td>
                            <td>30.03.2023</td>
                            <td>
                                <ul>
                                    <li>
                                        Eingabemöglichkeit für Produkte.
                                    </li>
                                    <li>
                                        Zertifikate als Word Dokument erstellen.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>1.00</td>
                            <td>20.03.2023</td>
                            <td>Erste Version online.</td>
                        </tr>
                    </tbody>
                    </table>
                    </Card.Body>

            </Card>
        </section>
    )
}

export default Home