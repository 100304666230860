import { useNavigate, Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";
import useAuth from "../hooks/useAuth";
import { Navbar, Nav } from "react-bootstrap";
import Logout from './Logout';

const Header = () => {
    const { auth } = useAuth();
    const { setAuth } = useContext(AuthContext);
    const navigate = useNavigate();

    return (
        <Navbar className="myNavbar" variant="light" expand="lg" sticky="top">
            <div className="topbar">
                <img
                    src="/images/IIDC_logo_white.png"
                    className="d-inline-block align-top navbar-logo"
                    alt="IIDC Logo"
                    height={45}
                />
            </div>
            <div className="toolbar">
                <ul className="toolbar-list">
                    <li className="toolbar-item d-none d-sm-block">
                        <span className="toolbar-item-link">
                            {auth.name}
                        </span>
                    </li>
                    <li className="toolbar-item">
                        <span className="toolbar-item-link">
                            <i className="fs-4 bi-gear"></i>
                        </span>
                    </li>
                    <li className="toolbar-item">
                        <span className="toolbar-item-link">
                            <Logout/>
                        </span>
                    </li>
                </ul>
            </div>
        </Navbar>
    )
}

export default Header