import { useNavigate } from "react-router-dom"
import { Fragment } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Header from "../fragments/public/Header"
import Footer2 from "../fragments/public/Footer2"

import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/iidc.css';

const Informationen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goBack = () => navigate(-1);

  return (
    <Fragment>
      <Header />
      <main>
        <div className="articleSeperator2"></div>
        <article id="info" className="article2">
          <div className="container">
            <h2 className="text-center mt-0 pt-4 pb-4">{t('info.title')}</h2>

            <div className="row row-cols-1 row-cols-lg-2 mb-4">
              <div className="col">
                <div className="shadow rounded backgroundSandDark px-4 py-1">
                  <h4>{t('info.header1')}</h4>
                  <div className="text-center mt-3 mb-3">
                    <Button className="btn-sm" variant="outline-success" href={'/assets/info/gebuehren_' + t('info.pdf') + '.pdf'}>download</Button>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="shadow rounded backgroundSandDark px-4 py-1">
                  <h4>{t('info.header2')}</h4>
                  <div className="text-center mt-3 mb-3">
                    <Button className="btn-sm" variant="outline-success" href={'/assets/info/regeln_' + t('info.pdf') + '.pdf'}>download</Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-2 mb-4">
              <div className="col">
                <div className="shadow rounded backgroundSandDark px-4 py-1">
                  <h4>{t('info.header4')}</h4>
                  <div className="text-center mt-3 mb-3">
                    <Button className="btn-sm" variant="outline-success" href={'/assets/info/beanstandungen_' + t('info.pdf') + '.pdf'}>download</Button>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="shadow rounded backgroundSandDark px-4 py-1">
                  <h4>{t('info.header41')}</h4>
                  <div className="text-center mt-3 mb-3">
                    <Button className="btn-sm" variant="outline-success" href={'/assets/info/beanstandungen_form_' + t('info.pdf') + '.pdf'}>download</Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-2 mb-4">
              <div className="col">
                <div className="shadow rounded backgroundSandDark px-4 py-1">
                  <h4>{t('info.header3')}</h4>
                  <div className="text-center mt-3 mb-3">
                    <Button className="btn-sm" variant="outline-success" href={'/assets/info/aussetzen_' + t('info.pdf') + '.pdf'}>download</Button>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="shadow rounded backgroundSandDark px-4 py-1">
                  <h4>{t('info.header6')}</h4>
                  <div className="text-center mt-3 mb-3">
                    <Button className="btn-sm" variant="outline-success" href={'/assets/info/' + t('info.unparteilichkeitPdf') + '.pdf'}>download</Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <div className="shadow rounded backgroundSandDark px-4 py-1">
                  <h4>{t('info.header5')}</h4>
                  <p>{t('info.para51')}</p>
                </div>
              </div>
            </div>

            <div className="text-center mt-4 pt-4">
              <Button className="btn-md publicBtn" variant="outline-success" 
                href="/">{t('impressum.btnBack')}</Button>
            </div>
          </div>
        </article>
      </main>
      <Footer2 />
    </Fragment>
  )
}

export default Informationen