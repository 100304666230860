import { Fragment } from "react";
import { Col, Row, Card } from "react-bootstrap";
const Reason = (prop) => {
  const { certificate } = prop;
  return( 
	<Fragment>
		{certificate?.stati[certificate?.stati?.length - 1].reason &&
			<div>
				<Card.Title>Begründung</Card.Title>
				<Row className="mb-3">
					<Col>
						{certificate.stati[certificate.stati.length - 1].reason}           
					</Col>
				</Row>
			</div> 
		}
	</Fragment>
  )
}
export default Reason