import Register from './components/Register';
import Login from './components/Login';
import Home from './components/Home';
import Layout from './components/Layout';
import Clients from './components/Clients';
import CertificationDetails from './components/CertificationDetails';
import CertificateDetails from './components/CertificateDetails';
import CertificateApproval from './components/CertificateApproval';
import ClientDetails from './components/ClientDetails';
import AddClient from './components/AddClient';
import AddCertification from './components/AddCertifikation';
import EditProducts from './components/EditProducts';
import AddCertificate from './components/AddCertifikate';
import CertificationsList from './components/CertificationsList';
import Benutzer from './components/Benutzer';
import Unauthorized from './components/Unauthorized';
import CustomerMessage from './components/CustomerMessage';
import ValidateCertificate from './components/ValidateCertificate';
import RequireAuth from './components/RequireAuth';
import { Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/css/theme.css';

const ROLES = {
  'Auditor': 'Auditor',
  'Admin': 'Admin'
}

function App() {

  return (
    <Routes>
      {/* public routes */}
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="validateCertificate" element={<ValidateCertificate />} />
      <Route path="unauthorized" element={<Unauthorized />} />

      {/* we want to protect these routes */}
      <Route path="/*" element={<Layout />}>
        <Route element={<RequireAuth allowedRoles={[ROLES.Auditor, ROLES.Admin]} />}> {/* layout route (= no path) */}
          <Route path="home" element={<Home />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.Auditor, ROLES.Admin]} />}>
          <Route path="clients" element={<Clients />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.Auditor, ROLES.Admin]} />}>
          <Route path="clientDetails" element={<ClientDetails />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.Auditor, ROLES.Admin]} />}>
          <Route path="certificationDetails" element={<CertificationDetails />} />
        </Route> 
        <Route element={<RequireAuth allowedRoles={[ROLES.Auditor, ROLES.Admin]} />}>
          <Route path="certificateDetails" element={<CertificateDetails />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
          <Route path="certificateApproval" element={<CertificateApproval />} />
        </Route>        
        <Route element={<RequireAuth allowedRoles={[ROLES.Auditor, ROLES.Admin]} />}>
          <Route path="addClient" element={<AddClient />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.Auditor, ROLES.Admin]} />}>
          <Route path="editClient" element={<AddClient />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.Auditor, ROLES.Admin]} />}>
          <Route path="addCertification" element={<AddCertification />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.Auditor, ROLES.Admin]} />}>
          <Route path="addCertificate" element={<AddCertificate />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.Auditor, ROLES.Admin]} />}>
          <Route path="editProducts" element={<EditProducts />} />
        </Route>                      
        <Route element={<RequireAuth allowedRoles={[ROLES.Auditor, ROLES.Admin]} />}>
          <Route path="certificationsList" element={<CertificationsList />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.Auditor, ROLES.Admin]} />}>
          <Route path="benutzer" element={<Benutzer />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.Auditor, ROLES.Admin]} />}>
          <Route path="customerMessage" element={<CustomerMessage />} />
        </Route>
        <Route path="*" element={<Navigate to='/' />} />
      </Route>
    </Routes>
  );
}

export default App;