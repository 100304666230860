import { useState, useEffect, useRef } from "react";
import { Button, ButtonGroup, Table, Form, Card, Row, Modal } from "react-bootstrap";
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import MyAlert from "./MyAlert";
import useAuth from "../hooks/useAuth";
import MsgHolder from "../util/MsgHolder";

const UserList = () => {
    const ref = useRef(null);
    const { auth } = useAuth();
    const [users, setUsers] = useState();
    const [addUserOpen, setAddUserOpen] = useState(false);
    const [editUser, setEditUser] = useState();
    const axiosPrivate = useAxiosPrivate(); 
    const [msgHolder, setMsgHolder] = useState(new MsgHolder());

    
    const getUsers = async () => {
        const controller = new AbortController();
        console.log('getUsers: fetching users from db.');
        try {
            const response = await axiosPrivate.get('/users', {
                signal : controller.signal
            });
            console.log('getUsers: api response:');
            console.log(response.data);
            setUsers(response.data);
        } catch (err) {
            console.log('getUsers: user api responded with error:');
            console.log(err);
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }
    useEffect(() => {
        console.log('useEffect... calling getUsers');
        getUsers();
        return () => {
        }
    }, []);

    return (
        <section>
            <h2>Benutzerliste</h2>
            <br />
            <MyAlert msgHolder={msgHolder}/>
            <div className="flexGrow">
                <Card>
                    <Card.Body>
                    {users?.length ? ( 
                        <Table className="table-striped">
                            <thead>        
                                <tr>
                                    <th>Benutzer</th>
                                    <th>Name</th>
                                    <th>Rollen</th>
                                    <th>letztes Login</th>
                                    <th>
                                        <Button className="btn-sm" variant="outline-primary" id="addUserButton"        
                                            onClick={() => { 
                                                setAddUserOpen(!addUserOpen);
                                                setEditUser();
                                                setMsgHolder(new MsgHolder());
                                            }} aria-controls="example-collapse-text" aria-expanded={addUserOpen}
                                            >Benutzer anlegen
                                        </Button>
                                    </th>
                                </tr>
                                {users.map((user, i) => 
                                    <tr key={user._id}>
                                        <td>{user.userid}</td>
                                        <td>{user.name}</td>
                                        <td>{user.roles.sort().reduce(function (result, item, index) {
                                                return result + (index > 0 ? ", ":"") + item;
                                            }, "")}</td>
                                        <td>{user.login ? new Date(user?.login).toLocaleString("DE") : ''}</td>
                                        <td>
                                            <ButtonGroup aria-label="Aktionen">
                                                <EditButton user={user}/>
                                                <DeleteButton id={user._id}/>
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                )
                                }
                            </thead>
                            
                        </Table>
                        ) : (
                            <div>Keine Benutzer angelegt.</div> 
                        )
                    } 
                    </Card.Body>
                </Card>
            </div>
            <AddButton/>
        </section>
    );

    function EditButton(user) {
        return (
            <Button className="btn-sm ml-10" variant="outline-primary" id="editUserButton"        
            onClick={() => {
                setAddUserOpen(!addUserOpen);
                console.log('set state edituser to:');
                console.log(user);
                setEditUser(user);
                setMsgHolder(new MsgHolder());
            }} aria-controls="example-collapse-text" aria-expanded={addUserOpen}
            >Ändern</Button>
        );
    }

    function DeleteButton({id}) {
        const axiosPrivate = useAxiosPrivate();
        if (id === auth._id) {
            return;
        }
        const controller = new AbortController();
        var response = null;
        return (
            <Button className="btn-sm" variant="outline-danger"
                onClick={async () => {
                    console.log('Delete User with id: ' + id);
                    console.log('call user api.');
                    try {
                        response = await axiosPrivate.delete('/users/' + id, {
                            signal : controller.signal
                        });
                        console.log('user api response:');
                        console.log(response);
                        setMsgHolder(new MsgHolder('Der Benutzer wurde gelöscht.', 'success'));
                        getUsers();
                    } catch (err) {
                        console.log('user api responsed with error:');
                        console.log(err);
                        setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
                    }
                }}
            >Löschen</Button>
        );
    }

    function createYupSchema() {
        return Yup.object({
            userid: Yup.string()
                .min(6, 'Mindestens 6 Zeichen.')
                .max(20, 'Höchstens 20 Zeichen.')
                .required('Der Benutzer muss eingegeben werden.'),
            name: Yup.string()
                .min(6, 'Der Name muss mindestens 8 Zeichen lang sein.')
                .max(40, 'Der Name darf höchstens 40 Zeichen.')
                .required('Der Name muss eingegeben werden.')
        });
    }

    function AddButton() {
        const handleClose = () => {
            setMsgHolder(new MsgHolder());
            setAddUserOpen(false);
        }
        const axiosPrivate = useAxiosPrivate(); 

        if (!addUserOpen) {
            return;
        }

        const controller = new AbortController();
        var initValues = {
            userid : '',
            editPwd : 'true',
            pwd : '',
            pwd2 : '',
            roles : ''
        }
        console.log('addbutton. editUser:');
        console.log(editUser);
        if (editUser) {
            initValues = {
                id : editUser.user._id,
                userid : editUser.user.userid,
                editPwd : 'false',
                pwd : '',
                pwd2 : '',
                name : editUser.user.name,
                roles : editUser.user.roles
            }
        }

        return (
            
            <Formik
            innerRef={ref}
                initialValues = { initValues }
                validate = {values => {
                    const errors = {};
                    if (!editUser) {
                        if (values.pwd.length === 0 && values.pwd.length === 0) {
                            errors.pwd = 'Das Passwort muss eingegeben werden.';      
                        }
                        if (values.pwd2.length === 0) {
                            errors.pwd2 = 'Das Passwort muss eingegeben werden.';      
                        }
                    }
                    if (values.pwd.length > 0 && values.pwd.length < 8) {
                        errors.pwd = 'Das Passwort muss mindestens 8-stellig sein.';  
                    } 
                    if (values.pwd2.length > 0 && values.pwd2.length < 8) {
                        errors.pwd2 = 'Das Passwort muss mindestens 8-stellig sein.';  
                    }                     
                    if (values.pwd.length > 0 && values.pwd.length > 0 && values.pwd !== values.pwd2) {
                      errors.pwd2 = 'Die Passwörter stimmen nicht überein.';
                    }
                    return errors;
                }}
                validationSchema = { createYupSchema() }
                onSubmit = {async (values, { setSubmitting, setErrors }) => {
    
                    console.log("createUser: form values: " + JSON.stringify(values));
                    var response = null;
                    try {
                        console.log("createUser:  Submiting...");
                        if (editUser) {
                            response = await axiosPrivate.put('/users', JSON.stringify(values), {
                                signal : controller.signal
                            });
                            setMsgHolder(new MsgHolder('Die Daten wurden geändert.', 'success'));
                        } else {
                            response = await axiosPrivate.post('/users', JSON.stringify(values), {
                                signal : controller.signal
                            });
                            setMsgHolder(new MsgHolder('Neuer Benutzer angelegt.', 'success'));
                        }
                        console.log("createUser:  Response: " + JSON.stringify(response?.data));
                        setEditUser();
                        setAddUserOpen(false);
                        getUsers();
                    } catch (err) {
                        console.log('createUser: user api responsed with error:');
                        console.log(err);
                        alert(err.response?.data?.message? err.response.data.message : err.message);
                    }
                    
                    setSubmitting(false);
                    }}
            >
            {({
                handleSubmit, handleChange, handleBlur, setFieldValue, 
                isSubmitting, values, touched, errors, 
            }) => (
                <Modal show={addUserOpen}>
                <MyAlert msgHolder={msgHolder}/>
                <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    <Row className="pt-4">
                        <Form.Group controlId="userid">
                            <Form.Label>Benutzer:</Form.Label>
                            <Form.Control type="text" name="userid" 
                                onChange={handleChange} onBlur={handleBlur} value={values.userid} 
                                isValid={touched.userid && !errors.userid}
                                isInvalid={errors.userid}
                            />
                            <Form.Control.Feedback type="invalid">{errors.userid}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="pt-4">
                        <Form.Group controlId="name">
                            <Form.Label>Name:</Form.Label>
                            <Form.Control type="text" name="name" 
                                onChange={handleChange} onBlur={handleBlur} value={values.name} 
                                isValid={touched.name && !errors.name}
                                isInvalid={errors.name}
                            />
                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>                    
                    <Row className="pt-4">
                        <Form.Group controlId="roles" className="mb-3">
                            <Form.Label>Rollen:</Form.Label>
                            { (editUser?.user?._id === auth._id) ? 
                                ( 
                                  <input type="hidden" name="roles" value="Admin"/>
                                ) : 
                                ( <span/>  )
                            }
                            <Form.Check
                                    name="roles"
                                    value="Admin"
                                    label="Admin"
                                    disabled={editUser?.user?._id === auth._id}
                                    checked={values.roles.includes('Admin')}
                                    onChange={handleChange}
                                    />
                            <Form.Check
                                name="roles"
                                value="Auditor"
                                label="Auditor"
                                checked={values.roles.includes('Auditor')}
                                onChange={handleChange}
                            />
                        </Form.Group>                    
                    </Row>     
                    <Row className="pt-4">
                        <Field type="hidden" name="editPwd"/>
                        <Form.Group controlId="pwd">
                            <Form.Label onClick={() => {
                                        if(values.editPwd === 'true') { 
                                            console.log("setzte values.editPwd auf flase.");
                                            setFieldValue('editPwd','false');
                                            setFieldValue('pwd','');
                                            setFieldValue('pwd2','');
                                            document.getElementById('lockIcon').classList.add('bi-lock-fill');
                                            document.getElementById('lockIcon').classList.remove('bi-unlock-fill');
                                        } else {
                                            console.log("setzte values.editPwd auf true.");
                                            setFieldValue('editPwd','true');
                                            document.getElementById('lockIcon').classList.remove('bi-lock-fill');
                                            document.getElementById('lockIcon').classList.add('bi-unlock-fill');
                                        }
                                        console.log("ENDE CLICKY!!!!");
                                    }}
                            >Neues Password:
                                { editUser !== undefined && 
                                    <span className="ms-2">
                                        <i id="lockIcon" className="fs-6 bi-lock-fill"></i>
                                    </span>  
                                }
                            </Form.Label>
                            <Form.Control type="password" name="pwd" 
                                onChange={handleChange} onBlur={handleBlur} value={values.pwd} 
                                //isValid={touched.pwd && !errors.pwd}
                                isInvalid={touched.pwd && errors.pwd}
                                disabled={values.editPwd === 'false'}
                                autoComplete="off"
                            />
                            <Form.Control.Feedback type="invalid">{errors.pwd}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="pt-4">
                        <Form.Group controlId="pwd2">
                            <Form.Label>Password Wiederholung:</Form.Label>
                            <Form.Control type="password" name="pwd2" 
                                onChange={handleChange} onBlur={handleBlur} value={values.pwd2} 
                                //isValid={touched.pwd2 && !errors.pwd2}
                                isInvalid={touched.pwd2 && errors.pwd2}
                                disabled={values.editPwd === 'false'}
                                autoComplete="off"
                            />
                            <Form.Control.Feedback type="invalid">{errors.pwd2}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        Abbrechen
                    </Button>                    
                    <Button className="btn-sm" variant="outline-primary" type="submit" disabled={isSubmitting}>OK</Button>
                </Modal.Footer>
                </Form>
                </Modal>
            )}
            </Formik>
        );
    }
}

export default UserList;