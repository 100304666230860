import { Fragment } from "react";
import { useTranslation } from 'react-i18next';

const Team = () => {
  const { t } = useTranslation();
  return( 
    <Fragment>
      <div className="articleSeperator"></div>
      <article id="team" className="mb-5">
        <div className="container">
          <h2 className="text-center">{t('team.title')}</h2>
          <p className="w-50 mx-auto text-center">
            {t('team.para1')}
          </p>
          <div className="row visitenKarten">
            <div className="col-md">
              <div className="shadow rounded">
                <h4>Katharina Fatima Reh
                  <div className="fs-6 w-75 border-bottom">{t('team.roleKatharina')}</div>
                </h4>
                <br/>+43 680 24 55 902
                <br/>katharina.reh@iidc.at
                <br/>{t('team.langKatharina')}
              </div>
            </div>
            <div className="col-md">
              <div className="shadow rounded">
                <h4>Günther Ahmed Rusznak
                  <div className="fs-6 w-75 border-bottom">{t('team.roleAhmed')}</div>
                </h4>
                <br/>+43 699 884 658 04
                <br/>ahmed.rusznak@iidc.at
                <br/>{t('team.langAhmed')}
              </div>  
            </div>             
          </div>
          <div className="row visitenKarten">
            <div className="col-md">
              <div className="shadow rounded">
                <h4>Agnes Dunai
                  <div className="fs-6 w-75 border-bottom">{t('team.roleAgnes')}</div>
                </h4>
                <br/>+36 30 850 6936
                <br/>agnes.dunai@iidc.at
                <br/>{t('team.langAgnes')}
              </div> 
            </div>
            <div className="col-md">
              <div className="shadow rounded">
                <h4>Yusuf Sever
                  <div className="fs-6 w-75 border-bottom">{t('team.roleYussuf')}</div>
                </h4>
                <br/>+43 676 378 6649
                <br/>yusuf.sever@iidc.at
                <br/>{t('team.langYussuf')}
              </div>  
            </div>
          </div>
          <div className="row visitenKarten">
            <div className="col-md">
            <div className="shadow rounded">
                <h4>Ali Bassaine
                  <div className="fs-6 w-75 border-bottom">{t('team.roleAli')}</div>
                </h4>
                <br/>+43 699 171 875 83
                <br/>ali.bassaine@iidc.at
                <br/>{t('team.langAli')}
                
              </div>
            </div>
            <div className="col-md">
            </div>             
          </div>
                    
        </div>
      </article>
    </Fragment>
  )
}
export default Team