import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import React, { Suspense } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './i18n';
import reportWebVitals from './reportWebVitals';
import App from './App';
import Datenschutz from './components/Datenschutz';
import Impressum from './components/Impressum';
import Index from './components/Index';
import Informationen from './components/Informationen';
import Recaptcha from './components/Recaptcha';
import { AuthProvider } from './context/AuthProvider';
import { ConsentProvider } from './context/ConsentProvider';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const domContainer = document.querySelector('#root');
const root = ReactDOM.createRoot(domContainer);

root.render(
  <React.StrictMode>
    <Suspense fallback={<div>loading...</div>}>
      <ConsentProvider>
        <Recaptcha>
          <BrowserRouter>
            <AuthProvider>
              <Routes>
                <Route path="/*" element={<App />} />
                <Route path="/" element={<Index />} />
                <Route path="/impressum" element={<Impressum />} />
                <Route path="/datenschutz" element={<Datenschutz />} />
                <Route path="/informationen" element={<Informationen />} />
              </Routes>
            </AuthProvider>
          </BrowserRouter>
        </Recaptcha>
      </ConsentProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
