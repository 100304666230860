import { useState, useEffect, Fragment } from "react";
import  Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import i18n from "i18next";

const Header = () => {
  const [lang, setLang] = useState(i18n?.resolvedLanguage?.toUpperCase());
  useEffect(()=>{
    document.documentElement.lang = i18n.language;
  }, []);

  return ( 
    <Fragment>
      <div className="container-fluid bg-light border-bottom">
        <div className="row pt-2 pb-2">
          <div className="col-md-6 col-lg-6 col-xl-6 d-none d-md-block">
            Islamic Information Documentation and Certification GmbH
          </div>
          <div className="col col-6 col-md-3 col-lg-3 col-xl-3">
            +43 (0) 699 884 658 04
          </div>
          <div className="col col-5 col-md-2 col-lg-2 col-xl-2">
            office@iidc.at
          </div>
          <div className="col-1">
          <Dropdown>
            <DropdownButton id="dropdown-basic-button" variant="outline-dark" title={lang ? lang : ''}>
              <Dropdown.Item onClick={() => {
                  i18n.changeLanguage('de');
                  setLang('DE');
                  document.documentElement.lang = 'de';
                  }}>Deutsch</Dropdown.Item>
              <Dropdown.Item onClick={() => {
                  i18n.changeLanguage('en');
                  setLang('EN');
                  document.documentElement.lang = 'en';
                  }}>English</Dropdown.Item>
              {/*<Dropdown.Item onClick={() => {
                  i18n.changeLanguage('fr');
                  setLang('FR');
                  document.documentElement.lang = 'fr';
                  }}>Français</Dropdown.Item>*/
              <Dropdown.Item onClick={() => {
                  i18n.changeLanguage('mg');
                  setLang('MG');
                  document.documentElement.lang = 'hu';
                  }}>Magyar</Dropdown.Item>}
            </DropdownButton>
          </Dropdown>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default Header