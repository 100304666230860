import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import MyAlert from "./MyAlert";
import MsgHolder from "../util/MsgHolder";
import Address from "../fragments/Address";
import axios from '../api/axios';
import { Container, Col, Row, Badge } from "react-bootstrap";

const ValidateCertificate = () => {
    const [certification, setCertification] = useState();
    const [msgHolder, setMsgHolder] = useState(new MsgHolder());

    const getCertificationByUUID = async (cid) => {
        const controller = new AbortController();
        console.log('getCertificationByUUID: fetching certification from db.');
        try {
            const response = await axios.get(`/validateCertificate/${cid}`, {
                signal : controller.signal
            });
            console.log('getCertificationByUUID: api response:');
            setCertification(response.data);

        } catch (err) {
            console.log('getCertificationByUUID: api responded with error:');
            console.log(err);
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }
    
    useEffect(() => {
        let params = new URLSearchParams(document.location.search);
        let cid = params.get("cid");
        console.log('useEffect... calling getCertificationByUUID with id: ' + cid);
        if (cid) {
            getCertificationByUUID(cid);
        } else {
            setMsgHolder(new MsgHolder("No Certificate ID found in URL."));
        }
        return () => {
        }
    }, []);

    return (
        <Container>
            <Row>
                <Col className="md-12">
                    <img src="/images/logo.png" style={{'max-width': 150 + 'px'}} className="float-end" alt="IIDC Logo"/>
                </Col>                    
            </Row>

            <MyAlert msgHolder={msgHolder}/>

            <div className="card border-success mb-3" style={{'max-width': 25 + 'rem'}}>
            <div className="card-header text-center">Halal Certificate Validation</div>
            <div className="card-body mt-2">
                <h5 className={`card-title ${(certification?.status === 40) && ("text-success")} text-center`
                    }>                        
                    <span className="pe-1">This Halal Certificate is</span>
                        {(certification?.status === 40) && (
                            <Badge bg='success'>valid</Badge>
                        )
                        }
                        {(certification?.status === 70) && (
                            <Badge bg='danger'>retracted</Badge>
                        )
                        }
                        {(certification?.status === 90) && (
                            <Badge bg='danger'>expired</Badge>
                        )
                        }
                </h5>
                <div className="text-center mt-4">
                    <img src="/images/logo0.png" style={{'max-width': 80 + 'px'}} alt="IIDC Logo"/>
                </div>
                <Row className="mb-2 mt-4">
                    <Col className="text-center">
                        Certificate Number: <br/><strong>{certification?.certificationNumber}</strong>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="text-center">
                        Issued to:<br/>
                        <strong>{certification?.clientName}</strong>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="text-center">
                        <Address address={certification?.clientAddress}/>
                    </Col>
                </Row>                
                <Row className="mb-2 mt-4 text-center justify-content-center">
                    <Col sm={4} >
                        Valid from:
                    </Col>
                    <Col sm={4}>
                        {certification?.validFrom ? new Date(certification?.validFrom).toLocaleDateString("DE") : ''}
                    </Col>
                </Row>
                <Row className="mb-2 text-center justify-content-center">
                    <Col sm={4}>
                        Valid until:
                    </Col>
                    <Col sm={4}>
                        {certification?.validTo ? new Date(certification?.validTo).toLocaleDateString("DE") : ''}
                    </Col>
                </Row>

            </div>
            </div>
        </Container>
    )
}

export default ValidateCertificate