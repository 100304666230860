import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button, ButtonGroup, Form, Col, Row, Card } from "react-bootstrap";
import { Formik, } from 'formik';
import * as Yup from 'yup';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import MyAlert from "./MyAlert";
import MsgHolder from "../util/MsgHolder";
import ValidToFrom from "../fragments/certificate/ValidFromTo";
import Products from "../fragments/certificate/Products";
import ProductionSites from "../fragments/certificate/ProductionSites";

const CertificateDetails = () => {
    const navigate = useNavigate();
    const [msgHolder, setMsgHolder] = useState(new MsgHolder());
    const [certification, setCertification] = useState();
    const [certificate, setCertificate] = useState();
    const axiosPrivate = useAxiosPrivate();
    const [client, setClient] = useState();
    const [downloaded, setDownloaded] = useState(false);
    const [retract, setRetract] = useState(false);

    const getCertification = async (certId) => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.get(`/certification/${certId}`, {
                signal : controller.signal
            });
            setCertification(response.data);
        } catch (err) {
            console.log('CertificateDetails.getCertification: api responded with error:');
            console.log(err);
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }

    const getCertificate = async (certificationId, certificateId) => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.get(`/certificate/${certificateId}/certification/${certificationId}`, {
                signal : controller.signal
            });
            setCertificate(response.data);
        } catch (err) {
            console.log('CertificateDetails.getCertificate: api responded with error:');
            console.log(err);
            var msgHolder = new MsgHolder(err.response?.data?.message? err.response.data.message : err.message);
            navigate('/clients', {state : {id : 1 , msgHolder : msgHolder}});
        }
    }

    const getClient = async (clientId) => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.get(`/client/${clientId}`, {
                signal : controller.signal
            });
            setClient(response.data);
        } catch (err) {
            console.log('CertificateDetails.getClient: api responded with error:');
            console.log(err);
            msgHolder = new MsgHolder(err.response?.data?.message? err.response.data.message : err.message);
            navigate('/clients', {state : {id : 1 , msgHolder : msgHolder}});
        }
    }

    const handlePreview = async (certificateId, lang) => {
        console.log("CertificateDetails.handlePreview");
        setMsgHolder();
        await downloadDocument(certificateId, lang);
    }

    const downloadDocument = async (certificateId, lang) => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.get(`/download/preview/${certification._id}/${certificateId}/${lang}`,
                {
                    signal : controller.signal,
                    responseType: 'blob'
                });
            // create file link in browser's memory
            const url = window.URL.createObjectURL(new Blob([response.data]));
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Zertifikat_Vorschau_${certificate?.certificateNumber}_${lang}.docx`);
            document.body.appendChild(link);
            link.click();
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
            setDownloaded(true);
        } catch (err) {
            console.log('CertificateDetails.downloadDocument: api responded with error!');
            console.log(err);
            setMsgHolder(new MsgHolder("Die Vorschau konnte nicht erstellt werden."));
        }
    }

    const submit = async (values) => {
        console.log('CertificateApproval.submit: ' + values.submitBtn);
        const controller = new AbortController();
        var response = null;
        var msgHolder = null;
        try {
            if (values.submitBtn === 'retract') {
                var url = `/publishCertificate/${certificate._id}/certification/${certification._id}`;
                response = await axiosPrivate.delete(url, JSON.stringify(values), {
                    signal : controller.signal
                });
                msgHolder = new MsgHolder('Das Zertifikat wurde zurückgezogen.');
            } else {
                var url = `approval/answerApproval/${certificate._id}/certification/${certification._id}`;
                response = await axiosPrivate.post(url, JSON.stringify(values), {
                    signal : controller.signal
                });
                if (values.submitBtn === 'approve') {
                    msgHolder = new MsgHolder('Das Zertifikat ist nun bereit zur Veröffentlichung.');
                } else {
                    msgHolder = new MsgHolder('Das Zertifikat wurde zurückgewiesen und kann weiter bearbeitet werden.');
                }
            }

            console.log("CertificateApproval.submit:  Response: " + JSON.stringify(response?.data));
            navigate('/home', {state : {id : 1 , msgHolder : msgHolder}});
        } catch (err) {
            setMsgHolder(new MsgHolder('Technischer Fehler!'));
        }
    }

    useEffect(() => {
        let params = new URLSearchParams(document.location.search);
        let certificationId = params.get("certificationId");
        let clientId = params.get("clientId");
        setRetract(params.get("retract"));
        if (certificationId) {
            getCertification(certificationId);
            getClient(clientId);
            let certificateId = params.get("certificateId");
            if (certificateId) {
                getCertificate(certificationId, certificateId);
            }
        } else {
            navigate('/certificationsList', {state : {id : 1 , msgHolder : new MsgHolder("AddCertification ohne certificateID aufgerufen.")}});
        }

        return () => {
        }
    }, []);

    return (
        <section>
            <div className="flexGrow">
                <Formik
                    initialValues = {{ 'reason' : ''}}
                    validationSchema = {
                        Yup.object(
                        { 'reason' : Yup.string().
                            when('submitBtn', {
                                is: (submitBtn) => submitBtn === 'reject' || submitBtn === 'retract',
                                then: Yup.string().max(2000, 'Die Anmerkung darf höchstens 2000 Zeichen haben.').
                                    required('Die Anmerkung muss eingegeben werden.'),
                                otherwise: Yup.string()
                            })
                        })
                    }
                    onSubmit = {submit}
                >
                {({
                    handleSubmit, handleChange, handleBlur,
                    setFieldValue, values, touched, errors
                }) => (   
                    <Form onSubmit={handleSubmit}>
                
                        <Card className="inputCard shadow-sm">
                            <Card.Header>
                                Zertifizierung für {certification?.clientName}
                                <span className="float-end">Zertifikatsnummer: {certificate?.certificateNumber}</span>
                            </Card.Header>
                            <Card.Body>
                                <MyAlert msgHolder={msgHolder}/>
                                <Card.Title>Zertifikat</Card.Title>

                                <ValidToFrom certificate={certificate}/>
                                <ProductionSites certificate={certificate} client={client}/>
                                <Products certificate={certificate} certification={certification}/>
                                
                                <Card.Title>Begründung</Card.Title>
                                <Form.Group controlId="validationMessage">
                                    <Form.Label>(Pflichtfeld bei Abweisung)</Form.Label>
                                    <Form.Control as="textarea" name="reason" cols="60" rows="5" 
                                        onChange={handleChange} onBlur={handleBlur} value={values.reason}
                                        maxLength="2000" 
                                        isValid={touched.reason && !errors.reason}
                                        isInvalid={errors.reason}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.reason}</Form.Control.Feedback>
                                </Form.Group>
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button className="mx-2" variant="outline-dark" id="backButton"
                                    onClick={() => {
                                        navigate({
                                            pathname: '/certificationDetails',
                                            search: `cid=${certification?._id}&key=zertifikate`
                                        });
                                    }}>
                                    zur Zertifizierung
                                </Button>
                                <ButtonGroup aria-label="Vorschau">
                                    <Button variant="outline-primary" type="button"
                                        onClick={() => {
                                            handlePreview(certificate._id, 'de');
                                        }}>
                                        <span>Vorschau DE</span>
                                    </Button>
                                    <Button variant="outline-primary" type="button"
                                        onClick={() => {
                                            handlePreview(certificate._id, 'en');
                                        }}>
                                        <span>EN</span>
                                    </Button>
                                </ButtonGroup>
                                { retract ? (
                                    <Button variant="outline-danger" type="submit" className="mx-2"
                                    onClick={() => {
                                        setFieldValue('submitBtn', 'retract');
                                    }}>
                                        Zertifikat zurückziehen
                                    </Button>
                                ) : (
                                    <span>
                                        { downloaded &&
                                        <Button variant="outline-success" type="submit" className="mx-2"
                                            onClick={() => {
                                                setFieldValue('submitBtn', 'approve');
                                            }}>
                                            Zertifikat unterschreiben
                                        </Button>
                                        }
                                        <Button variant="outline-danger" type="submit" className="mx-2"
                                            onClick={() => { 
                                                setFieldValue('submitBtn', 'reject');}}>
                                            Zertifikat zurückweisen
                                        </Button>
                                    </span>
                                )}                            
                            </Card.Footer>
                        </Card>
                    </Form>
                    )}   
                </Formik>             
            </div>                                  
        </section>
    )
}

export default CertificateDetails