import { Fragment } from "react";
import { useTranslation } from 'react-i18next';
import spice from "../../assets/images/spice1.jpeg";
import spiceWebp from "../../assets/images/spice1.webp";

const Vorteile = () => {
  const { t } = useTranslation();
  return( 
    <Fragment>
      <div className="articleSeperator"></div>
      <article id="vorteile" className="mb-4">
        <div className="container">
          <div className="row bsGutterX">
            <div className="col-12 col-sm-8 col-lg-6">
                <h2>{t('vertrauen.title')}<br/>{t('vertrauen.subtitle')}</h2>
                <p>
                  {t('vertrauen.para1')}
                </p>
                <p>
                  {t('vertrauen.para2')}
                </p>
                <h3>{t('vertrauen.subheader')}</h3>
                <ul>
                  <li>{t('vertrauen.li1')}</li>
                  <li>{t('vertrauen.li2')}</li>
                  <li>{t('vertrauen.li3')}</li>
                  <li>{t('vertrauen.li4')}</li>
                  <li>{t('vertrauen.li5')}</li>
                  <li>{t('vertrauen.li6')}</li>
                </ul>
              </div>
              <div className="col col-sm-4 pt-sm-5 mt-sm-5 col-lg-6 d-none d-sm-block">
                <picture>
                  <source srcSet={spiceWebp} type="image/webp"/>
                  <img src={spice} className="img-fluid mx-auto d-block rounded" alt="IIDC Siegel"/>
                </picture>
              </div>
            </div>
        </div>
      </article>
    </Fragment>
  )
}
export default Vorteile