import { Fragment } from "react";
import { Card } from "react-bootstrap";
const ValidFromTo = (props) => {
	const { certificate, certification } = props;

	const productRow = (product) => {
		if (certificate?.products?.includes(product._id)) {
			return (
				<tr className="mb-2" key={product._id}>
					<td>
						{product?.number}
					</td>
					<td>
						{product?.name}
					</td>
					<td>
						{product?.info}
					</td>
					<td>
						{product?.nameEN}
					</td>
					<td>
						{product?.infoEN}
					</td>                                                                                                                                                            
				</tr>
			);
		}
	}

	return( 
		<Fragment>
			<Card.Title>Produkte</Card.Title>
			<table className="table table-striped">
				<thead>
					<tr>
						<th scope="col">Nummer</th>
						<th scope="col">Name</th>
						<th scope="col">Info</th>
						<th scope="col">Name Englisch</th>
						<th scope="col">Info Englisch</th>
					</tr>
				</thead>
				<tbody>
					{ certification?.products?.map((product, i) =>
						productRow(product)
					)}
				</tbody>
			</table>
		</Fragment>
	)
}
export default ValidFromTo