import { Fragment } from "react";
import logo from "../../assets/images/IIDC_logo_white.png";
import siegel from "../../assets/images/IIDC_Siegel_SW.png";
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return( 
    <Fragment>
      <footer className="footer container-fluid">
        <div className="container">
          <div id="footer-Logo">
            <img src={logo} className="img-fluid" alt="IIDC Logo"/>
          </div>
          <div className="row">
            <div className="col-sm-4 pt-5 lh-lg">
              Islamic Information <br/>Documentation and Certification GmbH
              <br/>Johann-Roithner-Str. 131 | A-4050 Traun
              <br/>+43 (0) 699 884 658 04
              <br/>office@iidc.at
            </div>
            <div className="col-sm-3 pt-5 lh-lg">
            </div>
            <div className="col-sm-3 pt-5 lh-lg">
              <ul>
                <li className="pt-3">
                  <a className="heroLink" href="/informationen">{t('links.info')}</a>
                </li>
                <li className="pt-3">
                  <a className="heroLink" href="/datenschutz">{t('links.ds')}</a>
                </li>
                <li className="pt-3">
                  <a className="heroLink" href="/impressum">{t('links.impressum')}</a>
                </li>                  
              </ul>
            </div>
            <div className="col-sm-2">
              <img src={siegel} className="img-fluid" alt="IIDC Siegel"/>
            </div>
          </div>
        </div>
      </footer> 
    </Fragment>
  )
}
export default Footer