import { Card } from "react-bootstrap";
import CertStatus from "./CertStatus";

const CertHeader = (certProp) => {
    if (!certProp.cert) {
        return;
    }
    const cert  = certProp.cert;
    if (!cert) {
        return;
    }
    return (
        <section>
            <Card.Title className="mt-2">Zertifizierung für {cert.clientName} -  {cert.certificationNumber}
                <span className="float-end"><CertStatus cert={cert}/></span>
            </Card.Title>
        </section>
    )
}

export default CertHeader