import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button, Card, Table } from "react-bootstrap";
import CustomerMessageStatus from "../fragments/CustomerMessageStatus";
import MyAlert from "./MyAlert";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import MsgHolder from "../util/MsgHolder";

const CustomerMessages = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate(); 
    const [messages, setMessages] = useState();
    const [msgHolder, setMsgHolder] = useState(new MsgHolder());
   
    const getCustomerMessages = async () => {
        const controller = new AbortController();
        console.log('getCustomerMessages: fetching customerMessages from db.');
        try {
            const response = await axiosPrivate.get('/customerMessages', {
                signal : controller.signal
            });
            console.log('getCustomerMessages: api response:');
            console.log(response.data);
            setMessages(response.data);
        } catch (err) {
            console.log('getCustomerMessages: api responded with error:');
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }

    useEffect(() => {
        console.log('CustomerMessages... calling getCustomerMessages');
        getCustomerMessages();
        return () => {
        }
    }, []);

    return (
        <section id="kundenAnfragen">
        {messages?.length ? ( 
            <div>
                <h2>Anfragen</h2>
                <MyAlert msgHolder={msgHolder}/>
                <Card className="shadow-sm">
                    <Table className="table-striped">
                        <thead>        
                            <tr>
                                <th>Firma</th>
                                <th>Name</th>
                                <th>vom</th>
                                <th>Nachricht</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {messages.map((message, i) => 
                                <tr key={message._id}>
                                    <td>{message?.company}</td>
                                    <td>{message?.name}</td>
                                    <td>{new Date(message?.messageDate).toLocaleString("DE")}</td>
                                    <td>{message?.message}</td>
                                    <td><CustomerMessageStatus message={message}/></td>
                                    <td>                                        
                                        <Button className="btn-sm" variant="outline-primary" id="addClientButton"        
                                            onClick={() => { 
                                                navigate({
                                                    pathname: "/customerMessage",
                                                    search: "id=" + message._id
                                                    });
                                            }}>
                                            Bearbeiten
                                        </Button>
                                    </td>
                                </tr>
                            )
                            }      
                        </tbody>                                                          
                    </Table>
                </Card>  
            </div>                   
        ) : (
            <div>
            </div> 
        )}   
        </section>
    )
}

export default CustomerMessages