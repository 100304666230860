import { Fragment, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
const ProductList = (props) => {
	const { certification } = props;
	var hasInfo = false;

	if (!certification) {
		return (<div>loading...</div>);
	}

	certification.products.map((product, i) => {
		if (product.info || product.infoEN) {
			hasInfo = true;
		}
	});

	const printValues = (v1, v2) => {
		if (v1 && v2) {
			return (
				<td>
					{v1}<br/>{v2}
				</td>
			);
		} else if (v1 || v2) {
			return (
				<td>
					{ v1 ? <span>{v1}</span> : <span>{v2}</span>}
				</td>
			);
		}
	}

	return( 
		<Fragment>
			{certification?.products?.length > 0 ?
				(
					<table className="table table-striped">
						<thead>
							<tr>
								<th scope="col">Nummer</th>
								<th scope="col">Name</th>
								{ hasInfo && 
									<th scope="col">Info</th>
								}
							</tr>
						</thead>
						<tbody>
							{ certification.products.map((product, i) =>
								<tr className="mb-2" key={'product'+i}>
									{ printValues(product.number)}
									{ printValues(product.name, product.nameEN)}
									{ printValues(product.info, product.infoEN)}
								</tr>
								)
							}
						</tbody>
					</table>
				) : (
					<div className="mt-2 mb-2">Es wurden noch keine Produkte erfasst.</div> 
				)
			} 
		</Fragment>
	)
}
export default ProductList