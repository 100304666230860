import { useEffect } from "react";
import { axiosPrivate } from "../api/axios";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";

// Hook to attach interceptors to axios
const useAxiosPrivate = () => {  // custom hook
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {

        // requestInterceptor: 
        const requestInterceptor = axiosPrivate.interceptors.request.use(
            config => {
                // Authorization header is not set: initial request, add auth header.
                // (If the header is already set, the request is a refresh request.)
                if (!config.headers['Authorization']) {
                    console.log('requestInterceptor adding JWT token to Bearer header.');
                    config.headers['Authorization']  = `Bearer ${auth?.accessToken}`;
                } else {
                    console.log('requestInterceptor JWT token already in request.');
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        // responseInterceptor: if JWT token has timed out (response = 403), call refresh endpoint and
        // and resend request with new token.
        const responseInterceptor = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 403 && !prevRequest?.sent) {
                    console.log('responseInterceptor JWT token timed out, calling refresh endpoint.');
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axiosPrivate(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestInterceptor);
            axiosPrivate.interceptors.response.eject(responseInterceptor);
        }
    }, [auth, refresh])

    return axiosPrivate;
}

export default useAxiosPrivate;