import { Fragment } from "react";
const Countries = () => {
  return( 
      <Fragment>
        <option></option>
        <option>Österreich</option>
        <option>Deutschland</option>
        <option>Frankreich</option>
        <option>Italien</option>
        <option>Rumänien</option>
        <option>Schweiz</option>
        <option>Spanien</option>
        <option>Tschechien</option>
        <option>Türkei</option>
        <option>Ungarn</option>
      </Fragment>
  )
}
export default Countries