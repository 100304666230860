import { Fragment } from "react";
import useConsent from "../hooks/useConsent";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Recaptcha = ({ children }) => {
    const { consent } = useConsent();

    if (consent) {
        return (
            <GoogleReCaptchaProvider
                useRecaptchaNet
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
                scriptProps={{ async: true, defer: true, appendTo: 'body' }}
            >
                {children}
            </GoogleReCaptchaProvider>
        );
    } else {
        return (
            <Fragment>
                {children}
            </Fragment>
        );
    }
}

export default Recaptcha;