import { Fragment } from "react";
import { Col, Row, Card } from "react-bootstrap";
const ValidFromTo = (prop) => {
  const { certificate } = prop;
  return( 
	<Fragment>
		<Card.Title>Gültigkeit</Card.Title>
		
		<Row className="mb-2 mt-2">
			<Col sm={3}>
				Gültig ab:
			</Col>
			<Col>
				{new Date(certificate?.validFrom).toLocaleDateString("DE")}     
			</Col>
		</Row>
		<Row className="mb-2 mt-2">
			<Col sm={3}>
				Gültig bis:
			</Col>
			<Col>
				{new Date(certificate?.validTo).toLocaleDateString("DE")}     
			</Col>
		</Row>
	</Fragment>
  )
}
export default ValidFromTo