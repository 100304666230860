import { Fragment } from "react";
import { Col, Row} from "react-bootstrap";
const Address = (addressProp) => {
  const { address } = addressProp;
  return( 
      <Fragment>
        <Row>
            <Col>{address?.street}, {address?.number}</Col>
        </Row>
        <Row>
            <Col>{address?.zip} {address?.city}</Col>
        </Row>
        <Row>
            <Col>{address?.country}</Col>
        </Row>
      </Fragment>
  )
}
export default Address