import { Fragment, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import Header from "../fragments/public/Header"
import Datenschutz_de from "../fragments/public/Datenschutz_de"
import Datenschutz_en from "../fragments/public/Datenschutz_en"
import Footer2 from "../fragments/public/Footer2"

import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/iidc.css';

const Datenschutz = () => {
    const { t } = useTranslation();
    const [ds, setDs] = useState();

    useEffect(() => {
        console.log("asdasda");
        switch(i18n?.resolvedLanguage) {
            case "de":
                setDs(Datenschutz_de());
                break;
            default:
                setDs(Datenschutz_en());
                break;
        }
        return () => {
        }
    }, [i18n?.resolvedLanguage]);

    return (
        <Fragment>
            <Header/>
            <main>
            <div className="articleSeperator2"></div>
            <article id="datenschutz" className="article2">
            <div className="container ">
                {ds}
                <div className="text-center mt-4 pt-4">
                    <Button className="btn-md publicBtn" variant="outline-success" 
                    href="/">{t('impressum.btnBack')}</Button>
                </div>
            </div>
            </article>
            </main>
            <Footer2/>
        </Fragment>
    )
}

export default Datenschutz