import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { Button, ButtonGroup, Table, Form, Col, Row, Modal, Card } from "react-bootstrap";
import { Formik, getIn, FieldArray } from 'formik';
import * as Yup from 'yup';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import MyAlert from "./MyAlert";
import MsgHolder from "../util/MsgHolder";
import SVGDelete from "../fragments/SVGDelete";

const AddClient = () => {
    const navigate = useNavigate();
    const [msgHolder, setMsgHolder] = useState(new MsgHolder());
    const [certification, setCertification] = useState();
    const [lockedProductRows, setlockedProductRows] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const [usedProducts, setUsedProducts] = useState();

    function initFormValues() {
        console.log('certification?.products.length: ' + certification?.products.length);
        if (certification?.products.length > 0) {
            return certification;
        } else return (
        {
            products: [{
                number : '',
                name : ''
            }]
        });
    }
    function createYupSchema() {
        Yup.addMethod(Yup.array, 'unique', function(message, mapper = a => a) {
            return this.test('unique', message, function(list) {
                return list.length  === new Set(list.map(mapper)).size;
            });
        });
        return Yup.object({
            /*products: Yup.array().of(
                Yup.object().shape({
                    number: Yup.string().required('Die Nummer muss eingegeben werden.'),
                    name: Yup.string().required('Der Produktname muss eingegeben werden.')
                })
                )*/
        });
    }

    const upload = async () => {
        console.log("upload!");
        try {
            const response = await axiosPrivate.postForm(`/client/certification/${certification._id}/productImport`, {
                'products': document.getElementById("products").files[0]
            });
            navigate(`/certificationDetails?cid=${certification._id}`, 
                {state : {id : 1 , msgHolder : new MsgHolder(response.data.products + ' Produkte wurden importiert und gespeichert.', 'success')}});
        } catch (err) {
            console.log('getCertification: api responded with error:');
            console.log(err);
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }

    const getCertification = async (certId) => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.get(`/certification/${certId}`, {
                signal : controller.signal
            });
            setCertification(response.data);
        } catch (err) {
            console.log('getCertification: api responded with error:');
            console.log(err);
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }

    useEffect(() => {
        let params = new URLSearchParams(document.location.search);
        let certId = params.get("certId");
        if (certId) {
            getCertification(certId);
        }
        return () => {
        }
    }, []);
    
    useEffect(() => {
        setUsedProducts(getUsedProducts());
    }, [certification]);

    var initValues = initFormValues(certification);
    
    function hasDuplicateNumber(array) {
        var valuesSoFar = Object.create(null);
        for (var i = 0; i < array.length; ++i) {
            var value = array[i].number;
            if (value in valuesSoFar) {
                return i;
            }
            valuesSoFar[value] = true;
        }
        return -1;
    }
    function hasDuplicateName(array) {
        var valuesSoFar = Object.create(null);
        for (var i = 0; i < array.length; ++i) {
            var value = array[i].name;
            if (value in valuesSoFar) {
                return i;
            }
            valuesSoFar[value] = true;
        }
        return -1;
    }

    function getUsedProducts() {
        var usedProducts = new Array();
        certification?.certificates?.map((certificate, i) => {
            usedProducts = usedProducts.concat(certificate.products);
        });
        return usedProducts;
    }

    function isEditable(product) {
        var editable = true;
        if (usedProducts?.includes(product._id)) {
            editable = false;
        }
        console.log("editable: " + editable);
        return editable;
    }

    return (
        <section>
            <div className="flexGrow">
                <Formik
                    initialValues = {initValues}
                    enableReinitialize = {true}
                    validate = {values => {
                        const errors = {};
                        errors.products = new Array();
                        for (var i = 0; i < values.products.length; i++) {
                            errors.products.push(new Object());
                        }
                        console.log(' validate products:');
                        console.log(values.products);
                        var duplicateNr = -1; //hasDuplicateNumber(values.products);
                        var duplicateName = -1 //hasDuplicateName(values.products);

                        if (duplicateNr > -1 || duplicateName > -1) {
                            if (duplicateNr > -1 ) {
                                errors.products[duplicateNr].number = 'Die Nummer muss eindeutig sein.';
                            }
                            if (duplicateName > -1 ) {
                                errors.products[duplicateName].name = 'Der Name muss eindeutig sein.';
                            }
                            return errors;
                        } else {
                            return {};
                        }
                    }}
                    validationSchema = {createYupSchema}
                    onSubmit = {async (values, { setSubmitting, setErrors }) => {
                        const controller = new AbortController();
                        console.log("editProducts: form values: " + JSON.stringify(values));
                        try {
                            console.log("editProducts:  Submiting...");
                            await axiosPrivate.put('/client/certification/' + certification._id + '/products', JSON.stringify(values), {
                                signal : controller.signal
                            });
                            navigate(`/certificationDetails?cid=${certification._id}&key=produkte`, 
                                {state : {id : 1 , msgHolder : new MsgHolder('Produkte wurden geändert.', 'success')}});
                        } catch (err) {
                            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
                        }
                        setSubmitting(false);
                        }}
                >
                {({
                    handleSubmit, handleChange, handleBlur, setValues,
                    isSubmitting, values, touched, errors, form, formik
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Card className="inputCard shadow-sm">
                        <Card.Header>Produkte zur Zertifizierung {certification?.certificationNumber}
                        </Card.Header>
                        <Card.Body>
                        <MyAlert msgHolder={msgHolder}/>
                            <Card.Title className="mt-5 mb-3">Produkte eingeben</Card.Title>
                            { lockedProductRows &&
                            <div>
                                Produkte, die in einem Zertifikat mit einem anderen Status als 'in Bearbeitung' oder 'gelöscht' enthalten sind,
                                können nicht geändert werden.
                            </div>
}
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th width="15%" scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th width="15%" scope="col">Info</th>
                                        <th width="2%" scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <FieldArray name="products">
                                        {(fieldArrayProps) => (
                                            fieldArrayProps.form.values.products.map((product, i) => {
                                                const productName = `products[${i}].name`;
                                                const productNameEN = `products[${i}].nameEN`;
                                                const info = `products[${i}].info`;
                                                const infoEN = `products[${i}].infoEN`;
                                                const nr = `products[${i}].number`;
                                                const editable = isEditable(product);
                                                return ( 
                                                    <tr className="mb-4" key={'product'+i}>
                                                        <td>
                                                            <Form.Group controlId={nr}>
                                                                <Form.Control type="text" name={nr} 
                                                                    value={product?.number}
                                                                    onChange={handleChange} onBlur={handleBlur}                                                
                                                                    isValid={getIn(touched, nr) && !getIn(errors, nr)}
                                                                    isInvalid={getIn(touched, nr) && getIn(errors, nr)}
                                                                    disabled={!editable}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{getIn(errors, nr)}</Form.Control.Feedback>
                                                            </Form.Group>
                                                            <div className="mt-3 text-end fw-bold">Englisch</div>
                                                        </td>                       
                                                        <td>
                                                            <Form.Group controlId={productName}>
                                                                <Form.Control type="text" name={productName}
                                                                    value={product?.name}
                                                                    onChange={handleChange} onBlur={handleBlur}                                                
                                                                    isValid={getIn(touched, productName) && !getIn(errors, productName)}
                                                                    isInvalid={getIn(touched, productName) && getIn(errors, productName)}
                                                                    disabled={!editable}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{getIn(errors, productName)}</Form.Control.Feedback>
                                                            </Form.Group>
                                                            <div className="mt-2">
                                                                <Form.Group controlId={productNameEN}>
                                                                    <Form.Control type="text" name={productNameEN}
                                                                        value={product?.nameEN}
                                                                        onChange={handleChange} onBlur={handleBlur}                                                
                                                                        isValid={getIn(touched, productNameEN) && !getIn(errors, productNameEN)}
                                                                        isInvalid={getIn(touched, productNameEN) && getIn(errors, productNameEN)}
                                                                        disabled={!editable}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">{getIn(errors, productNameEN)}</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <Form.Group controlId={info}>
                                                                <Form.Control type="text" name={info} 
                                                                    value={product?.info}
                                                                    onChange={handleChange} onBlur={handleBlur}                                                
                                                                    isValid={getIn(touched, info) && !getIn(errors, info)}
                                                                    isInvalid={getIn(touched, info) && getIn(errors, info)}
                                                                    disabled={!editable}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{getIn(errors, info)}</Form.Control.Feedback>
                                                            </Form.Group>
                                                            <div className="mt-2">
                                                                <Form.Group controlId={infoEN}>
                                                                    <Form.Control type="text" name={infoEN} 
                                                                        value={product?.infoEN}
                                                                        onChange={handleChange} onBlur={handleBlur}                                                
                                                                        isValid={getIn(touched, infoEN) && !getIn(errors, infoEN)}
                                                                        isInvalid={getIn(touched, infoEN) && getIn(errors, infoEN)}
                                                                        disabled={!editable}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">{getIn(errors, infoEN)}</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {(values.products.length > 1 && editable) &&
                                                                <Button variant="outline-danger" className="float-end" onClick={() => {
                                                                    console.log('Clicky! entferne product: ' + i);
                                                                    const products = [...values.products];
                                                                    products.splice(i,1);
                                                                    setValues({ ...values, products });
                                                                }}>
                                                                    <SVGDelete/>
                                                                </Button>                                         
                                                            }
                                                        </td>
                                                    </tr>                                                                                      
                                                );
                                            })
                                            )}
                                    </FieldArray>
                                </tbody>
                            </table>

                            <Button variant="outline-primary" className="float-end" onClick={() => {
                                console.log('Clicky! ' , values);
                                const products = [...values.products];
                                products.push({});
                                setValues({ ...values, products });
                            }}>Hinzufügen</Button> 

                            <Row className="mt-2">
                                <Col>
                                    <Card.Title className="mt-5 mb-3">Produkte aus XLSX-Datei importieren</Card.Title>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={10}>
                                    <Form.Control type="file" id="products" name="products"/>
                                    <Form.Text className="text-muted">
                                        Die Datei muss die Produkte im vorgegebenen Format enthalten.
                                    </Form.Text>
                                </Col>
                                <Col sm={2}>
                                    <Button variant="outline-primary" className="float-end" onClick={upload}>Import</Button>
                                </Col>
                            </Row>

                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button className="mx-2" variant="outline-dark" id="backButton"
                                onClick={() => {
                                    navigate({
                                        pathname: '/certificationDetails',
                                        search: `cid=${certification?._id}&key=produkte`
                                    });
                                }}>
                                zurück
                            </Button>                            
                            <Button variant="outline-primary" type="submit" disabled={isSubmitting}>
                                Daten ändern
                            </Button>
                        </Card.Footer>
                        </Card>
                    </Form>
                )}
                </Formik> 
            </div>                                  
        </section>
    )
}

export default AddClient