import { Fragment } from "react";

const Datenschutz_de = () => {
  return (
    <Fragment>
      <h2 className="text-center mt-0 pt-4 pb-3">Privacy Policy</h2>
      <h3>1. AN OVERVIEW OF DATA PROTECTION</h3>
      <strong>General information</strong>
      <p>
        The following information will provide you with an easy to navigate overview of what will happen with your personal data when you visit this website. The term “personal data” comprises all data that can be used to personally identify you. For detailed information about the subject matter of data protection, please consult our Data Protection Declaration, which we have included beneath this copy.
      </p>
      <strong>Data recording on this website</strong><br />
      <strong>Who is the responsible party for the recording of data on this website (i.e., the “controller”)?</strong>
      <p>
        The data on this website is processed by the operator of the website, whose contact information is available under section “Information about the responsible party (referred to as the “controller” in the GDPR)” in this Privacy Policy.
      </p>
      <strong>How do we record your data?</strong>
      <p>
        We collect your data as a result of your sharing of your data with us. This may, for instance be information you enter into our contact form.
      </p>
      <p>
        Other data shall be recorded by our IT systems automatically or after you consent to its recording during your website visit. This data comprises primarily technical information (e.g., web browser, operating system, or time the site was accessed). This information is recorded automatically when you access this website.
      </p>
      <strong>What are the purposes we use your data for?</strong>
      <p>
        A portion of the information is generated to guarantee the error free provision of the website. Other data may be used to analyze your user patterns.
      </p>
      <strong>What rights do you have as far as your information is concerned?</strong>
      <p>
        You have the right to receive information about the source, recipients, and purposes of your archived personal data at any time without having to pay a fee for such disclosures. You also have the right to demand that your data are rectified or eradicated. If you have consented to data processing, you have the option to revoke this consent at any time, which shall affect all future data processing. Moreover, you have the right to demand that the processing of your data be restricted under certain circumstances. Furthermore, you have the right to log a complaint with the competent supervising agency.
      </p>
      <p>
        Please do not hesitate to contact us at any time if you have questions about this or any other data protection related issues.
      </p>
      <strong>Analysis tools and tools provided by third parties</strong>
      <p>
        There is a possibility that your browsing patterns will be statistically analyzed when your visit this website. Such analyses are performed primarily with what we refer to as analysis programs.
      </p>
      <p>
        For detailed information about these analysis programs please consult our Data Protection Declaration below.
      </p>
      <h3>2. HOSTING</h3>
      <p>We are hosting the content of our website at the following provider:</p>
      <strong>Host Europe</strong>
      <p>
        The provider is the Host Europe GmbH, Hansestraße 111, 51149 Köln, Germany (hereinafter referred to as: Host Europe). Whenever you visit our website, Host Europe will record a variety of logfiles, including your IP addresses.
      </p>
      <p>
        For details, please refer to the Data Privacy Policy of Host Europe: https://www.hosteurope.de/AGB/Datenschutzerklaerung/.
      </p>
      <p>
        We use Host Europe on the basis of Art. 6(1)(f) GDPR. We have a legitimate interest in making the depiction of our website as dependable as possible. If you have been asked for your respective consent, processing shall occur exclusively on the basis of Art. 6(1)(a) GDPR and § 25(1) TTDSG, if the consent comprises the archiving of cookies or access to information on the user's device (e.g., device finger printing) as defined in the TTDSG. Such consent may be revoked at any time.
      </p>
      <strong>Data processing</strong>
      <p>
        We have concluded a data processing agreement (DPA) with the above-mentioned provider. This is a contract mandated by data privacy laws that guarantees that they process personal data of our website visitors only based on our instructions and in compliance with the GDPR.
      </p>

      <h3>3. GENERAL INFORMATION AND MANDATORY INFORMATION</h3>
      <strong>Data protection</strong>
      <p>
        The operators of this website and its pages take the protection of your personal data very seriously. Hence, we handle your personal data as confidential information and in compliance with the statutory data protection regulations and this Data Protection Declaration.
      </p>
      <p>
        Whenever you use this website, a variety of personal information will be collected. Personal data comprises data that can be used to personally identify you. This Data Protection Declaration explains which data we collect as well as the purposes we use this data for. It also explains how, and for which purpose the information is collected.
      </p>
      <p>
        We herewith advise you that the transmission of data via the Internet (i.e., through e-mail communications) may be prone to security gaps. It is not possible to completely protect data against third-party access.
      </p>
      <strong>Information about the responsible party (referred to as the “controller” in the GDPR)</strong>
      <p>
        The data processing controller on this website is:
      </p>
      <p>
        IIDC Austria Islamic Information Documentation and Certification GmbH<br />
        Johann-Roithner-Str. 131<br />
        A-4050 Traun<br />
        Austria<br />
        <br /><br />
        Phone: +43 (0) 699 884 658 04<br />
        E-mail: office@iidc.at<br />
      </p>
      <p>
        The controller is the natural person or legal entity that single-handedly or jointly with others makes decisions as to the purposes of and resources for the processing of personal data (e.g., names, e-mail addresses, etc.).
      </p>
      <strong>Storage duration</strong>
      <p>
        Unless a more specific storage period has been specified in this privacy policy, your personal data will remain with us until the purpose for which it was collected no longer applies. If you assert a justified request for deletion or revoke your consent to data processing, your data will be deleted, unless we have other legally permissible reasons for storing your personal data (e.g., tax or commercial law retention periods); in the latter case, the deletion will take place after these reasons cease to apply.
      </p>
      <strong>General information on the legal basis for the data processing on this website</strong>
      <p>
        If you have consented to data processing, we process your personal data on the basis of Art. 6(1)(a) GDPR or Art. 9 (2)(a) GDPR, if special categories of data are processed according to Art. 9 (1) DSGVO. In the case of explicit consent to the transfer of personal data to third countries, the data processing is also based on Art. 49 (1)(a) GDPR. If you have consented to the storage of cookies or to the access to information in your end device (e.g., via device fingerprinting), the data processing is additionally based on § 25 (1) TTDSG. The consent can be revoked at any time. If your data is required for the fulfillment of a contract or for the implementation of pre-contractual measures, we process your data on the basis of Art. 6(1)(b) GDPR. Furthermore, if your data is required for the fulfillment of a legal obligation, we process it on the basis of Art. 6(1)(c) GDPR. Furthermore, the data processing may be carried out on the basis of our legitimate interest according to Art. 6(1)(f) GDPR. Information on the relevant legal basis in each individual case is provided in the following paragraphs of this privacy policy.
      </p>
      <strong>Information on data transfer to the USA and other non-EU countries</strong>
      <p>
        Among other things, we use tools of companies domiciled in the United States or other from a data protection perspective non-secure non-EU countries. If these tools are active, your personal data may potentially be transferred to these non-EU countries and may be processed there. We must point out that in these countries, a data protection level that is comparable to that in the EU cannot be guaranteed. For instance, U.S. enterprises are under a mandate to release personal data to the security agencies and you as the data subject do not have any litigation options to defend yourself in court. Hence, it cannot be ruled out that U.S. agencies (e.g., the Secret Service) may process, analyze, and permanently archive your personal data for surveillance purposes. We have no control over these processing activities.
      </p>
      <strong>Revocation of your consent to the processing of data</strong>
      <p>
        A wide range of data processing transactions are possible only subject to your express consent. You can also revoke at any time any consent you have already given us. This shall be without prejudice to the lawfulness of any data collection that occurred prior to your revocation.
      </p>
      <strong>Right to object to the collection of data in special cases; right to object to direct advertising (Art. 21 GDPR)</strong>
      <p>
        IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6(1)(E) OR (F) GDPR, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR UNIQUE SITUATION. THIS ALSO APPLIES TO ANY PROFILING BASED ON THESE PROVISIONS. TO DETERMINE THE LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA IS BASED, PLEASE CONSULT THIS DATA PROTECTION DECLARATION. IF YOU LOG AN OBJECTION, WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA, UNLESS WE ARE IN A POSITION TO PRESENT COMPELLING PROTECTION WORTHY GROUNDS FOR THE PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR INTERESTS, RIGHTS AND FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS THE CLAIMING, EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION PURSUANT TO ART. 21(1) GDPR).
      </p>
      <p>
        IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT ADVERTISING, YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING AT ANY TIME. THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES (OBJECTION PURSUANT TO ART. 21(2) GDPR).
      </p>
      <strong>Right to log a complaint with the competent supervisory agency</strong>
      <p>
        In the event of violations of the GDPR, data subjects are entitled to log a complaint with a supervisory agency, in particular in the member state where they usually maintain their domicile, place of work or at the place where the alleged violation occurred. The right to log a complaint is in effect regardless of any other administrative or court proceedings available as legal recourses.
      </p>
      <strong>Right to data portability</strong>
      <p>
        You have the right to demand that we hand over any data we automatically process on the basis of your consent or in order to fulfil a contract be handed over to you or a third party in a commonly used, machine readable format. If you should demand the direct transfer of the data to another controller, this will be done only if it is technically feasible.
      </p>
      <strong>Information about, rectification and eradication of data</strong>
      <p>
        Within the scope of the applicable statutory provisions, you have the right to at any time demand information about your archived personal data, their source and recipients as well as the purpose of the processing of your data. You may also have a right to have your data rectified or eradicated. If you have questions about this subject matter or any other questions about personal data, please do not hesitate to contact us at any time.
      </p>
      <strong>Right to demand processing restrictions</strong>
      <p>
        You have the right to demand the imposition of restrictions as far as the processing of your personal data is concerned. To do so, you may contact us at any time. The right to demand restriction of processing applies in the following cases:
      </p>
      <p>
        In the event that you should dispute the correctness of your data archived by us, we will usually need some time to verify this claim. During the time that this investigation is ongoing, you have the right to demand that we restrict the processing of your personal data.
        If the processing of your personal data was/is conducted in an unlawful manner, you have the option to demand the restriction of the processing of your data in lieu of demanding the eradication of this data.
        If we do not need your personal data any longer and you need it to exercise, defend or claim legal entitlements, you have the right to demand the restriction of the processing of your personal data instead of its eradication.
        If you have raised an objection pursuant to Art. 21(1) GDPR, your rights and our rights will have to be weighed against each other. As long as it has not been determined whose interests prevail, you have the right to demand a restriction of the processing of your personal data.
        If you have restricted the processing of your personal data, these data - with the exception of their archiving - may be processed only subject to your consent or to claim, exercise or defend legal entitlements or to protect the rights of other natural persons or legal entities or for important public interest reasons cited by the European Union or a member state of the EU.
      </p>
      <strong>SSL and/or TLS encryption</strong>
      <p>
        For security reasons and to protect the transmission of confidential content, such as purchase orders or inquiries you submit to us as the website operator, this website uses either an SSL or a TLS encryption program. You can recognize an encrypted connection by checking whether the address line of the browser switches from “http://” to “https://” and also by the appearance of the lock icon in the browser line.
      </p>
      <p>
        If the SSL or TLS encryption is activated, data you transmit to us cannot be read by third parties.
      </p>
      <strong>Rejection of unsolicited e-mails</strong>
      <p>
        We herewith object to the use of contact information published in conjunction with the mandatory information to be provided in our Site Notice to send us promotional and information material that we have not expressly requested. The operators of this website and its pages reserve the express right to take legal action in the event of the unsolicited sending of promotional information, for instance via SPAM messages.
      </p>

      <h3>4. RECORDING OF DATA ON THIS WEBSITE</h3>
      <strong>Cookies</strong>
      <p>
        Our websites and pages use what the industry refers to as “cookies.” Cookies are small data packages that do not cause any damage to your device. They are either stored temporarily for the duration of a session (session cookies) or they are permanently archived on your device (permanent cookies). Session cookies are automatically deleted once you terminate your visit. Permanent cookies remain archived on your device until you actively delete them, or they are automatically eradicated by your web browser.
      </p>
      <p>
        In some cases, it is possible that third-party cookies are stored on your device once you enter our site (third-party cookies). These cookies enable you or us to take advantage of certain services offered by the third party (e.g., cookies for the processing of payment services).
      </p>
      <p>
        Cookies have a variety of functions. Many cookies are technically essential since certain website functions would not work in the absence of the cookies (e.g., the shopping cart function or the display of videos). The purpose of other cookies may be the analysis of user patterns or the display of promotional messages.
      </p>
      <p>
        Cookies, which are required for the performance of electronic communication transactions, or for the provision of certain functions you want to use (e.g., for the shopping cart function) or those that are necessary for the optimization (required cookies) of the website (e.g., cookies that provide measurable insights into the web audience), shall be stored on the basis of Art. 6(1)(f) GDPR, unless a different legal basis is cited. The operator of the website has a legitimate interest in the storage of required cookies to ensure the technically error free and optimized provision of the operator's services. If your consent to the storage of the cookies and similar recognition technologies has been requested, processing occurs exclusively on the basis of the consent obtained (Art. 6(1)(a) GDPR and § 25 (1) TTDSG); this consent may be revoked at any time.
      </p>
      <p>
        You have the option to set up your browser in such a manner that you will be notified any time cookies are placed and to permit the acceptance of cookies only in specific cases. You may also exclude the acceptance of cookies in certain cases or in general or activate the delete function for the automatic eradication of cookies when the browser closes. If cookies are deactivated, the functions of this website may be limited.
      </p>
      <p>
        In the event that third-party cookies are used or if cookies are used for analytical purposes, we will separately notify you in conjunction with this Data Protection Policy and, if applicable, ask for your consent.
      </p>

      <strong>Server log files</strong>
      <p>
        The provider of this website and its pages automatically collects and stores information in so-called server log files, which your browser communicates to us automatically. The information comprises:
      </p>
      <p>
        The type and version of browser used<br />
        The used operating system<br />
        Referrer URL<br />
        The hostname of the accessing computer<br />
        The time of the server inquiry<br />
        The IP address<br />
        This data is not merged with other data sources.<br />
      </p>
      <p>
        This data is recorded on the basis of Art. 6(1)(f) GDPR. The operator of the website has a legitimate interest in the technically error free depiction and the optimization of the operator's website. In order to achieve this, server log files must be recorded.
      </p>
      <strong>Contact form</strong>
      <p>
        If you submit inquiries to us via our contact form, the information provided in the contact form as well as any contact information provided therein will be stored by us in order to handle your inquiry and in the event that we have further questions. We will not share this information without your consent.
      </p>
      <p>
        The processing of these data is based on Art. 6(1)(b) GDPR, if your request is related to the execution of a contract or if it is necessary to carry out pre-contractual measures. In all other cases the processing is based on our legitimate interest in the effective processing of the requests addressed to us (Art. 6(1)(f) GDPR) or on your agreement (Art. 6(1)(a) GDPR) if this has been requested; the consent can be revoked at any time.
      </p>
      <p>
        The information you have entered into the contact form shall remain with us until you ask us to eradicate the data, revoke your consent to the archiving of data or if the purpose for which the information is being archived no longer exists (e.g., after we have concluded our response to your inquiry). This shall be without prejudice to any mandatory legal provisions, in particular retention periods.
      </p>

      <strong>Request by e-mail, telephone, or fax</strong>
      <p>
        If you contact us by e-mail, telephone or fax, your request, including all resulting personal data (name, request) will be stored and processed by us for the purpose of processing your request. We do not pass these data on without your consent.
      </p>
      <p>
        These data are processed on the basis of Art. 6(1)(b) GDPR if your inquiry is related to the fulfillment of a contract or is required for the performance of pre-contractual measures. In all other cases, the data are processed on the basis of our legitimate interest in the effective handling of inquiries submitted to us (Art. 6(1)(f) GDPR) or on the basis of your consent (Art. 6(1)(a) GDPR) if it has been obtained; the consent can be revoked at any time.
      </p>
      <p>
        The data sent by you to us via contact requests remain with us until you request us to delete, revoke your consent to the storage or the purpose for the data storage lapses (e.g. after completion of your request). Mandatory statutory provisions - in particular statutory retention periods - remain unaffected.
      </p>

      <h3>5. ANALYSIS TOOLS AND ADVERTISING</h3>
      <p>At this moment, we don't use any tools of that kind.</p>

      <h3>6. PLUG-INS AND TOOLS</h3>
      <strong>Google Fonts (local embedding)</strong>
      <p>
        This website uses so-called Google Fonts provided by Google to ensure the uniform use of fonts on this site. These Google fonts are locally installed so that a connection to Google's servers will not be established in conjunction with this application.
      </p>
      <p>
        For more information on Google Fonts, please follow this link: https://developers.google.com/fonts/faq and consult Google's Data Privacy Declaration under: https://policies.google.com/privacy?hl=en.
      </p>
      <strong>Google reCAPTCHA</strong>
      <p>
        We use “Google reCAPTCHA” (hereinafter referred to as “reCAPTCHA”) on this website. The provider is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
      </p>
      <p>
        The purpose of reCAPTCHA is to determine whether data entered on this website (e.g., information entered into a contact form) is being provided by a human user or by an automated program. To determine this, reCAPTCHA analyzes the behavior of the website visitors based on a variety of parameters. This analysis is triggered automatically as soon as the website visitor enters the site. For this analysis, reCAPTCHA evaluates a variety of data (e.g., IP address, time the website visitor spent on the site or cursor movements initiated by the user). The data tracked during such analyses are forwarded to Google.
      </p>
      <p>
        reCAPTCHA analyses run entirely in the background. Website visitors are not alerted that an analysis is underway.
      </p>
      <p>
        Data are stored and analyzed on the basis of Art. 6(1)(f) GDPR. The website operator has a legitimate interest in the protection of the operator's websites against abusive automated spying and against SPAM. If appropriate consent has been obtained, the processing is carried out exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1) TTDSG, insofar the consent includes the storage of cookies or the access to information in the user's end device (e.g., device fingerprinting) within the meaning of the TTDSG. This consent can be revoked at any time.
      </p>
      <p>
        For more information about Google reCAPTCHA please refer to the Google Data Privacy Declaration and Terms Of Use under the following links: https://policies.google.com/privacy?hl=en and https://policies.google.com/terms?hl=en.
      </p>
    </Fragment>
  )
}
export default Datenschutz_de