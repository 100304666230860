import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button, ButtonGroup, Form, Col, Row, Card } from "react-bootstrap";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import MyAlert from "./MyAlert";
import MsgHolder from "../util/MsgHolder";
import ValidToFrom from "../fragments/certificate/ValidFromTo";
import Reason from "../fragments/certificate/Reason";
import Products from "../fragments/certificate/Products";
import ProductionSites from "../fragments/certificate/ProductionSites";
import History from "../fragments/certificate/History";
import Stati from "../util/Stati";

const CertificateDetails = () => {
    const navigate = useNavigate();
    const [msgHolder, setMsgHolder] = useState(new MsgHolder());
    const [certification, setCertification] = useState();
    const [certificate, setCertificate] = useState();
    const axiosPrivate = useAxiosPrivate();
    const [client, setClient] = useState();

    const getCertification = async (certId) => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.get(`/certification/${certId}`, {
                signal : controller.signal
            });
            setCertification(response.data);
        } catch (err) {
            console.log('getCertification: api responded with error:');
            console.log(err);
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }

    const getCertificate = async (certificationId, certificateId) => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.get(`/certificate/${certificateId}/certification/${certificationId}`, {
                signal : controller.signal
            });
            setCertificate(response.data);
        } catch (err) {
            console.log('getCertificate: api responded with error:');
            console.log(err);
            var msgHolder = new MsgHolder(err.response?.data?.message? err.response.data.message : err.message);
            navigate('/clients', {state : {id : 1 , msgHolder : msgHolder}});
        }
    }

    const getClient = async (clientId) => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.get(`/client/${clientId}`, {
                signal : controller.signal
            });
            setClient(response.data);
        } catch (err) {
            console.log('getClient: api responded with error:');
            console.log(err);
            msgHolder = new MsgHolder(err.response?.data?.message? err.response.data.message : err.message);
            navigate('/clients', {state : {id : 1 , msgHolder : msgHolder}});
        }
    }

    const handlePreview = async (certificateId, lang) => {
        console.log("addCertificate.handlePreview");
        setMsgHolder();
        await downloadDocument(certificateId, lang);
    }

    const downloadDocument = async (certificateId, lang) => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.get(`/download/preview/${certification._id}/${certificateId}/${lang}`,
                {
                    signal : controller.signal,
                    responseType: 'blob'
                });
            // create file link in browser's memory
            const url = window.URL.createObjectURL(new Blob([response.data]));
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Zertifikat_Vorschau_${certificate?.certificateNumber}_${lang}.docx`);
            document.body.appendChild(link);
            link.click();
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        } catch (err) {
            console.log('addCertificate.handlePreviewEN: api responded with error!');
            console.log(err);
            setMsgHolder(new MsgHolder("Die Vorschau konnte nicht erstellt werden."));
        }
    }

    const deleteCertificate = async () => {
        const controller = new AbortController();
        console.log('delete Certificate.');
        try {
            await axiosPrivate.delete(`/certificate/${certificate._id}/certification/${certification._id}`, {
                signal : controller.signal
            });
            navigate(`/certificationDetails?cid=${certification._id}&key=zertifikate`, 
                {state : {id : 1 , msgHolder : new MsgHolder(`Das Zertifikat ${certificate.certificateNumber} wurde gelöscht.`, 'success')}});
        } catch (err) {
            console.log('deleteCertificate: api responded with error:');
            console.log(err);
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }

    const approveCertificate = async () => {
        const controller = new AbortController();
        console.log('approveCertificate.');
        try {
            var result = await axiosPrivate.post(`/approval/add/certificate/${certificate._id}/certification/${certification._id}`, {
                signal : controller.signal
            });
            navigate(`/certificationDetails?cid=${certification._id}&key=zertifikate`, 
                {state : {id : 1 , msgHolder : new MsgHolder(`Das Zertifikat ${certificate.certificateNumber} wurde zur Prüfung freigegeben.`, 'success')}});
        } catch (err) {
            console.log('approveCertificate: api responded with error:');
            console.log(err);
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }

    useEffect(() => {
        let params = new URLSearchParams(document.location.search);
        let certificationId = params.get("certificationId");
        let clientId = params.get("clientId");
        if (certificationId) {
            getCertification(certificationId);
            getClient(clientId);
            let certificateId = params.get("certificateId");
            if (certificateId) {
                getCertificate(certificationId, certificateId);
            }
        } else {
            navigate('/certificationsList', {state : {id : 1 , msgHolder : new MsgHolder("AddCertification ohne certificateID aufgerufen.")}});
        }

        return () => {
        }
    }, []);

    return (
        <section>
            <div className="flexGrow">
                <Card className="inputCard shadow-sm">
                    <Card.Header>
                        Zertifizierung für {certification?.clientName}
                        <span className="float-end">Zertifikatsnummer: {certificate?.certificateNumber}
                        </span>
                    </Card.Header>
                    <Card.Body>
                        <MyAlert msgHolder={msgHolder}/>
                        <Card.Title>Zertifikat</Card.Title>

                        <ValidToFrom certificate={certificate}/>
                        <Reason certificate={certificate}/>
                        <ProductionSites certificate={certificate} client={client}/>
                        <Products certificate={certificate} certification={certification}/>
                        <History certificate={certificate}/>

                    </Card.Body>
                    <Card.Footer className="text-end">
                        <Button className="mx-2" variant="outline-dark" id="backButton"
                            onClick={() => {
                                navigate({
                                    pathname: '/certificationDetails',
                                    search: `cid=${certification?._id}&key=zertifikate`
                                });
                            }}>
                            zurück
                        </Button>
                        <ButtonGroup aria-label="Vorschau">
                            <Button variant="outline-primary" type="button"
                                onClick={() => {
                                    handlePreview(certificate?._id, 'de');
                                }}>
                                { certificate?.stati[certificate.stati.length-1].status === Stati.CERTIFICATE_STATUS.PUBLIC ||
                                  certificate?.stati[certificate.stati.length-1].status === Stati.CERTIFICATE_STATUS.APPROVED ||
                                  certificate?.stati[certificate.stati.length-1].status === Stati.CERTIFICATE_STATUS.RETRACTED ?
                                    (<span>Zertifikat DE</span>) :
                                    (<span>Vorschau DE</span>)
                                }
                            </Button>
                            <Button variant="outline-primary" type="button"
                                onClick={() => {
                                    handlePreview(certificate?._id, 'en');
                                }}>
                                <span>EN</span>
                            </Button>
                        </ButtonGroup>
                        { certificate?.stati[certificate.stati.length-1].status === Stati.CERTIFICATE_STATUS.WIP &&
                            <span>
                                <Button variant="outline-danger" id="detailsButton" className="mx-2"
                                    onClick={deleteCertificate}>
                                    Löschen
                                </Button>
                                
                                <Button variant="outline-success" id="detailsButton" className="mx-2"
                                    onClick={approveCertificate}>
                                    Genehigung
                                </Button> 
                            </span>
                        } 
                    </Card.Footer>
                </Card>
            </div>                                  
        </section>
    )
}

export default CertificateDetails