import { Fragment } from "react";
import { useTranslation } from 'react-i18next';
import coffee from "../../assets/images/WomanDrinkingCoffee.jpg";
import coffeeWebp from "../../assets/images/WomanDrinkingCoffee.webp";

const Halal = () => {
  const { t } = useTranslation();
  return( 
    <Fragment>
      <div className="collapse" id="collapseHalal">
        <div className="articleSeperator2"></div>
        <article id="halal" className="article2">
          <div className="container">
            <div className="row row-cols-1 row-cols-lg-2 bsGutterX">
              <div className="col col-lg-6">
                <h2>{t('halal.title')}</h2>
                <p>{t('halal.para1')}</p>
                <p>{t('halal.para2')}</p>
              </div>              
              <div className="col col-lg-6">
                <picture>
                  <source srcSet={coffeeWebp} type="image/webp"/>
                  <img src={coffee} className="coffee img-fluid mx-auto d-block rounded" alt="Woman drinking Coffee"/>
                </picture>
              </div>
            </div>

            <div className="row infoKarten">
              <div className="col-md">
                <div className="shadow rounded">
                  <h3><p>{t('halal.subheader1')}</p></h3>
                  <p>{t('halal.subpara1')}</p>
                </div>
              </div>
              <div className="col-md">
                <div className="shadow rounded">
                  <h3><p>{t('halal.subheader2')}</p></h3>
                  <p>{t('halal.subpara2')}</p>
                </div>
              </div>
            </div>
            <div className="row infoKarten">
              <div className="col-md">
                <div className="shadow rounded">
                  <h3><p>{t('halal.subheader3')}</p></h3>
                  <p>{t('halal.subpara3')}</p>
                </div>
              </div>
              <div className="col-md">
                <div className="shadow rounded">
                  <h3><p>{t('halal.subheader4')}</p></h3>
                  <p>{t('halal.subpara4')}</p>
                </div>
              </div>
            </div>                                
          </div>
        </article>
      </div>
    </Fragment>
  )
}
export default Halal