import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { Button, Tabs, Tab, ButtonGroup, Table, Form, Col, Row, Card, Nav } from "react-bootstrap";
import MyAlert from "./MyAlert";
import Certifications from "./Certifications";
import MsgHolder from "../util/MsgHolder";
import Address from "../fragments/Address";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const ClientDetails = () => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate(); 
    const [client, setClient] = useState();
    const [certifications, setCertifications] = useState();
    const [msgHolder, setMsgHolder] = useState(new MsgHolder());

    const getClient = async (cid) => {
        const controller = new AbortController();
        console.log('getClient: fetching client from db.');
        try {
            const response = await axiosPrivate.get(`/client/${cid}`, {
                signal : controller.signal
            });
            console.log('getClient: api response:');
            console.log(response.data);
            setClient(response.data);
            if (client?.contacts.length == 0) {
                setMsgHolder(new MsgHolder('Bitte Ansprechpersonen ergänzen.', 'warning'));
            }
        } catch (err) {
            console.log('getClient: api responded with error:');
            console.log(err);
            navigate('/clients', {state : {id : 1 , msgHolder : new MsgHolder(err.response?.data?.message? err.response.data.message : err.message)}});
        }
    }

    const getCertificationsForClient = async (cid) => {
        const controller = new AbortController();
        console.log('getCertificationsForClient: fetching clients from db.');
        try {
            const response = await axiosPrivate.get(`/client/certification/${cid}`, {
                signal : controller.signal
            });
            console.log('getCertificationsForClient: api response:');
            console.log(response.data);
            setCertifications(response.data);
        } catch (err) {
            console.log('getCertificationsForClient: api responded with error:');
            console.log(err);
            navigate('/clients', {state : {id : 1 , msgHolder : new MsgHolder(err.response?.data?.message? err.response.data.message : err.message)}});
        }
    }
    
    useEffect(() => {
        let params = new URLSearchParams(document.location.search);
        let cid = params.get("cid");
        console.log('useEffect... calling getClient with id: ' + cid);
        getClient(cid);
        console.log('useEffect... calling getCertificationsForClient');
        getCertificationsForClient(cid);
        return () => {
        }
    }, []);

    return (
        <section>
            <Card className="inputCard shadow-sm">
                <Tab.Container id="myTabs" defaultActiveKey="daten">
                    <Nav variant="tabs">
                        <Nav.Item className="myTab">
                            <Nav.Link className="myTabLink" eventKey="daten">Kundendaten</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="myTab">
                            <Nav.Link className="myTabLink" eventKey="zert">Zertifizierungen</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    
                    <MyAlert msgHolder={msgHolder}/>
                    <Tab.Content>
                        <Card.Header>
                            {client?.name}
                            <span className="float-end">Registrierungsnummer: {client?.clientNumber}</span>
                        </Card.Header>
                        <Tab.Pane eventKey="daten">
                            <Card.Body>
                            <Card.Title className="mt-4">Kundendaten</Card.Title>
                                <Row>
                                    <Col sm={2}>UID-Nummer:</Col>
                                    <Col>{client?.vatNr}</Col>
                                </Row>
                            <Card.Title className="mt-4">Adresse</Card.Title>
                            <Address address={client?.address}/>
                            { client?.invoiceAddress && (
                                <div>
                                <Card.Title className="mt-4">Rechnungsadresse</Card.Title>
                                <Address address={client?.invoiceAddress}/>
                                </div>)
                            }
                            <Card.Title className="mt-4">Ansprechpersonen</Card.Title>                        
                            {client?.contacts?.map((contact, i) =>
                                <div className="mb-4" id={'contact' + i}>
                                    <Row>
                                        <Col>{contact.person.title} {contact.person.firstName} {contact.person.lastName}</Col>
                                    </Row>
                                    <Row>
                                        <Col>{contact.dep}</Col>
                                    </Row>
                                    <Row>
                                        <Col>{contact.person.email}</Col>
                                    </Row>
                                    <Row>
                                        <Col>{contact.person.phone}</Col>
                                    </Row>
                                </div>
                            )}
                            { client?.productionSites?.length > 0 && ( 
                                    <Card.Title className="mt-4">Produktionsstätten</Card.Title>                        
                            )}
                            { client?.productionSites?.map((site, i) =>
                                <div className="mb-4" id={'site' + i}>
                                    <Row>
                                        <Col>{site.name}</Col>
                                    </Row>
                                    <Address address={site?.address}/>
                                </div>
                            )}
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button variant="outline-primary"
                                        onClick={() => { 
                                        navigate({
                                            pathname: "/editClient",
                                            search: "cid="+client._id
                                        })}}
                                        >Daten bearbeiten</Button>
                            </Card.Footer>
                        </Tab.Pane>
                        <Tab.Pane eventKey="zert">
                            <Card.Body>
                                <Certifications certifications={certifications}/>
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button variant="outline-primary"
                                    onClick={() => { 
                                        navigate({
                                            pathname: "/addCertification",
                                            search: "cid="+client._id
                                        })}}
                                >Zertifizierung starten</Button>
                            </Card.Footer>
                        </Tab.Pane>                        
                    </Tab.Content>
                </Tab.Container>
            </Card>
        </section>
    )
}

export default ClientDetails