import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { Button, ButtonGroup, Table, Form, Col, Row, Modal, Card } from "react-bootstrap";
import { Formik, getIn, FieldArray } from 'formik';
import * as Yup from 'yup';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import MyAlert from "./MyAlert";
import MsgHolder from "../util/MsgHolder";
import { Categories, Subcategories} from "../fragments/Categories";
import { errorsIfNotAllFilled, trueIfAllFilled, trueIfOneFilled } from "../util/utils";
import Address from "../fragments/Address";
import CertStatus from "../fragments/CertStatus";

const AddCertification = () => {
    const navigate = useNavigate();
    const [msgHolder, setMsgHolder] = useState(new MsgHolder());
    const [client, setClient] = useState();
    const [editMode, setEditMode] = useState(false);
    const [certification, setCertification] = useState();
    const axiosPrivate = useAxiosPrivate(); 

    function initFormValues() {
        if (editMode) {
            console.log("ValidFrom: " +certification?.validFrom );
            return ({    
                _siteId : certification?._siteId,
                category : certification?.category,
                subcategory : certification?.subcategory,
                validFrom : certification?.validFrom,
                initialValidFrom : certification?.initialValidFrom,
                clientSitesNumber : client?.productionSites.length
            });
        } else {
            return ({
                _siteId : 'client',
                category : '',
                subcategory : '',
                validFrom : '',
                initialValidFrom : ''
            });
    }
    }
    function createYupSchema() {
        return Yup.object({
            validFrom: Yup.string().required('Das Gültigkeitsdatum muss ausgewählt werden.'),
            category: Yup.string().required('Eine Kategorie muss ausgewählt werden.'),
            subcategory: Yup.string().when('category', {
                is: (category) => category === 'A: Farming of Animals' || category === 'C: Food Manufacturing',
                then: Yup.string().required('Eine Subkategorie muss ausgewählt werden.'),
                otherwise: Yup.string()
              }),
        });
    }

    const getCertification = async (certId) => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.get(`/certification/${certId}`, {
                signal : controller.signal
            });
            //console.log('getCertification: api response:');
            //console.log(response.data);
            setCertification(response.data);
        } catch (err) {
            console.log('getCertification: api responded with error:');
            console.log(err);
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }

    const getClient = async (cid) => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.get(`/client/${cid}`, {
                signal : controller.signal
            });
            //console.log('getClient: api response:');
            //console.log(response.data);
            setClient(response.data);
        } catch (err) {
            console.log('getClient: api responded with error:');
            console.log(err);
            msgHolder = new MsgHolder(err.response?.data?.message? err.response.data.message : err.message);
            navigate('/clients', {state : {id : 1 , msgHolder : msgHolder}});
        }
    }

    useEffect(() => {
        let params = new URLSearchParams(document.location.search);
        let cid = params.get("cid");
        if (cid) {
            getClient(cid);
        }
        let certId = params.get("certId");
        if (certId) {
            getCertification(certId);
            setEditMode(true);
        }
        return () => {
        }
    }, []);

    var initValues = initFormValues();
    return (
        <section>
            <div className="flexGrow">
                <Formik
                    initialValues = {initValues}
                    enableReinitialize = {true}
                    validationSchema = {createYupSchema}
                    onSubmit = {async (values, { setSubmitting, setErrors }) => {
                        const controller = new AbortController();
                        console.log("addCertification: form values: " + JSON.stringify(values));
                        var response = null;
                        var msgHolder = null;
                        try {
                            console.log("addCertification:  Submiting...");
                            if (!editMode) {
                                response = await axiosPrivate.post('/client/certification/' + client._id, JSON.stringify(values), {
                                    signal : controller.signal
                                });
                                msgHolder = new MsgHolder('Neue Zertifizierung wurde gestartet.', 'success');
                            } else {
                                response = await axiosPrivate.put('/client/certification/' + certification._id, JSON.stringify(values), {
                                    signal : controller.signal
                                });
                                msgHolder = new MsgHolder('Die Daten der Zertifizierung wurden geändert.', 'success');
                            }
                            navigate('/certificationsList', {state : {id : 1 , msgHolder : msgHolder}});
                        } catch (err) {
                            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
                        }
                        
                        setSubmitting(false);
                        }}
                >
                {({
                    handleSubmit, handleChange, handleBlur, setValues,
                    isSubmitting, values, touched, errors, form, formik
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Card className="inputCard shadow-sm">
                            <Card.Header>
                                Zertifizierung für {editMode ? certification?.clientName : client?.name}
                                {editMode ? (
                                    <span className="float-end">Zertifizierungsnummer: {certification?.certificationNumber}</span>
                                ) : (
                                    <span className="float-end">Registrierungsnummer: {client?.clientNumber}</span>
                                )}
                            </Card.Header>
                            <Card.Body>
                                <MyAlert msgHolder={msgHolder}/>
                                <Card.Title>Daten zur Zertifizierung</Card.Title>

                                <Card.Title>Gültigkeit</Card.Title>
                                Achtung: 00:00:00.000Z" does not conform to the required format, "yyyy-MM-dd".
set @ react-dom.development.js:
                                <Row className="mb-2 mt-2">
                                    <Col sm={3}>
                                        <Form.Group controlId="initialValidFrom" className="mb-3">
                                            <Form.Label>Datum der ersten Zertifizierung:</Form.Label>
                                            <Form.Control type="date" min="2010-01-01"
                                                name="initialValidFrom"
                                                onChange={handleChange} onBlur={handleBlur} value={values.initialValidFrom}                                              
                                                isValid={touched.initialValidFrom && !errors.initialValidFrom}
                                                isInvalid={errors.initialValidFrom}
                                            />
                                            <div className='invalid-feedback display-block'>{errors.initialValidFrom}</div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-2">                                   
                                    <Col sm={3}>
                                        <Form.Group controlId="validFrom" className="mb-3">
                                            <Form.Label>Gültig ab:</Form.Label>
                                            <Form.Control type="date" min="2010-01-01"
                                                name="validFrom"
                                                onChange={handleChange} onBlur={handleBlur} value={values.validFrom}                                              
                                                isValid={touched.validFrom && !errors.validFrom}
                                                isInvalid={errors.validFrom}
                                            />
                                            <div className='invalid-feedback display-block'>{errors.validFrom}</div>
                                        </Form.Group>
                                    </Col>             
                                </Row>

                                <Card.Title>Scope</Card.Title>
                                <Row className="mb-2">
                                    <Col sm={8}>
                                        <Form.Group controlId="category">
                                            <Form.Label>Kategorie:</Form.Label>
                                            <Form.Select name="category"
                                                onChange={handleChange} onBlur={handleBlur} value={values?.category} 
                                                isValid={getIn(touched, 'category') && !getIn(errors, 'category')}
                                                isInvalid={getIn(touched, 'category') && getIn(errors, 'category')}
                                            >   
                                                <Categories/>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">{getIn(errors, 'category')}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col sm={8}>
                                        <Form.Group controlId="subcategory">
                                            <Form.Label>Subkategorie:</Form.Label>
                                            <Form.Select name="subcategory"
                                                onChange={handleChange} onBlur={handleBlur} value={values?.subcategory} 
                                                isValid={getIn(touched, 'subcategory') && !getIn(errors, 'subcategory')}
                                                isInvalid={getIn(touched, 'subcategory') && getIn(errors, 'subcategory')}
                                            >   
                                                <Subcategories category={values?.category}/>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">{getIn(errors, 'subcategory')}</Form.Control.Feedback>
                                        </Form.Group>                            
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button className="mx-2" variant="outline-dark" id="backButton"
                                    onClick={() => {
                                        navigate({
                                            pathname: '/certificationDetails',
                                            search: `cid=${certification?._id}&key=daten`
                                        });
                                    }}>
                                    zurück
                                </Button>
                                <Button variant="outline-primary" type="submit" disabled={isSubmitting}>
                                    {editMode ? (
                                        <span>Daten ändern</span>
                                    ) : (
                                        <span>Zertifizierung starten</span>
                                    )
                                    }
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Form>
                )}
                </Formik> 
            </div>                                  
        </section>
    )
}

export default AddCertification