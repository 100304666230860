import { useNavigate } from "react-router-dom";
import { useState, useEffect} from "react";
import { Button, ButtonGroup, Form, Col, Row, Collapse, Card } from "react-bootstrap";
import { Formik, getIn, FieldArray } from 'formik';
import * as Yup from 'yup';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import MyAlert from "./MyAlert";
import MsgHolder from "../util/MsgHolder";
import Countries from "../fragments/Countries";

const AddClient = () => {
    const navigate = useNavigate();
    const [msgHolder, setMsgHolder] = useState(new MsgHolder());
    const [client, setClient] = useState();
    const axiosPrivate = useAxiosPrivate();
    const [invoiceAddress, setinvoiceAddress] = useState(false);

    function initFormValues() {
        if (client != null) {
            return client;
        } else return (
        {
            name : '',
            vatNr : '',
            field : '',
            address : {
                street : '',
                number : '',
                city : '',
                zip : '',
                country : '',
            },
            invoiceAddress : {
                street : '',
                number : '',
                city : '',
                zip : '',
                country : '',
            },            
            productionSites: [],
            contacts: [{
                dep : '',
                person : {
                    titel : '',
                    firstName : '',
                    lastName : '',
                    email : '',
                    phone : '',
                }
            }]
        });
    }
    function createYupSchema() {
        return Yup.object({
            name: Yup.string()
                .min(4, 'Mindestens 4 Zeichen.')
                .max(50, 'Höchstens 50 Zeichen.')
                .required('Pflichtfeld'),
            vatNr: Yup.string()
                .min(10, 'Mindestens 10 Zeichen.')
                .max(11, 'Höchstens 11 Zeichen.')
                //.matches(/[abcdefghijklmnopqrstuvwxyz]+/ , 'Is not in correct format')
                .required('Pflichtfeld'),                
            address: Yup.object({
                street: Yup.string()
                .min(6, 'Die Strasse muss mindestens 6 Zeichen lang sein.')
                .required('Die Strasse muss eingegeben werden.'),
                number: Yup.string()
                .required('Die Hausnummer muss eingegeben werden.'),
                city: Yup.string()
                .required('Der Ort muss eingegeben werden.'),
                zip: Yup.number()
                .typeError('Die Postleitzahl muss numerisch sein.')
                .min(1000, 'mindestens 4 Zeichen')
                .required('Die Postleitzahl muss eingegeben werden.'),
                country: Yup.string()
                .required('Das Land muss ausgewählt werden.')
            }),
            invoiceAddress: Yup.object({
                street: Yup.string()
                .min(6, 'Die Strasse muss mindestens 6 Zeichen lang sein.'),
                zip: Yup.number()
                .typeError('Die Postleitzahl muss numerisch sein.')
                .min(1000, 'mindestens 4 Zeichen'),
            }),
            productionSites: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string()
                    .min(4, 'Mindestens 4 Zeichen.')
                    .max(50, 'Höchstens 50 Zeichen.')
                    .required('Der Name muss eingegeben werden.'),
                    address: Yup.object().shape({
                        street: Yup.string()
                        .min(6, 'Die Strasse muss mindestens 6 Zeichen lang sein.')
                        .required('Die Strasse muss eingegeben werden.'),
                        number: Yup.string()
                        .required('Die Hausnummer muss eingegeben werden.'),
                        city: Yup.string()
                        .required('Der Ort muss eingegeben werden.'),
                        zip: Yup.number()
                        .typeError('Die Postleitzahl muss numerisch sein.')
                        .min(1000, 'mindestens 4 Zeichen')
                        .required('Die Postleitzahl muss eingegeben werden.'),
                        country: Yup.string()
                        .required('Das Land muss ausgewählt werden.')
                    })
                })
            ),
            contacts: Yup.array().of(
                Yup.object().shape({
                    dep: Yup.string()
                    .required('Die Bezeichnung muss eingegeben werden.'),
                    person: Yup.object().shape({
                        firstName: Yup.string()
                        .min(3, 'Mindestens 3 Zeichen.')
                        .max(40, 'Höchstens 40 Zeichen.')
                        .required('Der Vorname muss eingegeben werden.'),
                        lastName: Yup.string()
                        .min(3, 'Mindestens 3 Zeichen.')
                        .max(40, 'Höchstens 40 Zeichen.')
                        .required('Der Nachname muss eingegeben werden.'),
                        phone: Yup.string()
                        .min(8, 'Mindestens 8 Zeichen.')
                        .max(20, 'Höchstens 20 Zeichen.'),
                        email: Yup.string()
                        .min(8, 'Mindestens 8 Zeichen.')
                        .max(50, 'Höchstens 50 Zeichen.')
                        .email('Die E-Mail hat ein ungültiges Format')
                        .required('Die E-Mail muss eingegeben werden.')
                    })
                })
            )
        });
    }

    const getClient = async (cid) => {
        const controller = new AbortController();
        console.log('getClient: fetching client from db.');
        try {
            const response = await axiosPrivate.get(`/client/${cid}`, {
                signal : controller.signal
            });
            console.log('getClient: api response:');
            console.log(response.data);
            setClient(response.data);
            if (client?.contacts.length == 0) {
                setMsgHolder(new MsgHolder('Bitte Ansprechpersonen ergänzen.', 'warning'));
            }
        } catch (err) {
            console.log('getClient: api responded with error:');
            console.log(err);
            msgHolder = new MsgHolder(err.response?.data?.message? err.response.data.message : err.message);
            navigate('/clients', {state : {id : 1 , msgHolder : msgHolder}});
        }
    }

    useEffect(() => {
        let params = new URLSearchParams(document.location.search);
        let cid = params.get("cid");
        if (cid) {
            console.log('addClient called with ID: ' + cid);
            getClient(cid);
        }
        return () => {
        }
    }, []);

    var initValues = initFormValues(client);

    return (
        <section>
            <div className="flexGrow">
                <Formik
                    initialValues = {initValues}
                    enableReinitialize = {true}
                    validationSchema = {createYupSchema}
                    onSubmit = {async (values, { setSubmitting, setErrors }) => {
                        const controller = new AbortController();
                        console.log("addClient: form values: " + JSON.stringify(values));
                        var response = null;
                        var msgHolder = null;
                        try {
                            console.log("addClient:  Submiting...");
                            if (client) {
                                response = await axiosPrivate.put('/client/' + client._id, JSON.stringify(values), {
                                    signal : controller.signal
                                });
                                msgHolder = new MsgHolder('Benutzerdaten wurden geändert.', 'success');
                            } else {
                                response = await axiosPrivate.post('/client', JSON.stringify(values), {
                                    signal : controller.signal
                                });
                                msgHolder = new MsgHolder('Neuer Kunde angelegt.', 'success');
                            }
                            console.log("addClient:  Response: " + JSON.stringify(response?.data));
                            navigate('/clients', {state : {id : 1 , msgHolder : msgHolder}});
                        } catch (err) {
                            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
                            //navigate('/login', {state : { from: location}, replace: true});  // back to login in case of jwt cookie timeout
                                                                                               // TODO only in case of timeout error from api!
                        }
                        
                        setSubmitting(false);
                        }}
                >
                {({
                    handleSubmit, handleChange, handleBlur, setValues,
                    isSubmitting, values, touched, errors, form, formik
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Card className="inputCard shadow-sm">
                        <Card.Header>Kunde anlegen
                            { client?.clientNumber && (
                                <span className="float-end">Registrierungsnummer: {client?.clientNumber}</span>
                            )}
                        </Card.Header>
                        <Card.Body>
                        <MyAlert msgHolder={msgHolder}/>
                            <Card.Title>Daten zur Firma</Card.Title>
                            <Row className="mb-2">
                                <Col sm={3}>
                                    <Form.Group controlId="vatNr">
                                        <Form.Label>UID-Nummer:</Form.Label>
                                        <Form.Control type="text" name="vatNr" 
                                            onChange={handleChange} onBlur={handleBlur} value={values.vatNr} 
                                            isValid={touched.vatNr && !errors.vatNr}
                                            isInvalid={touched.vatNr && errors.vatNr}
                                            maxLength="11"
                                            placeholder="ATU99999999 , DE999999999, HU99999999"
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.vatNr}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm={9}>
                                    <Form.Group className="mb-2" controlId="name">
                                        <Form.Label>Firmenname:</Form.Label>
                                        <Form.Control type="text" name="name" 
                                            onChange={handleChange} onBlur={handleBlur} value={values.name} 
                                            isValid={touched.name && !errors.name}
                                            isInvalid={touched.name && errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Card.Title className="mt-4">Adresse</Card.Title>
                            <Row className="mb-2">
                                <Col sm={10}>
                                    <Form.Group controlId="address.street">
                                        <Form.Label>Straße:</Form.Label>
                                        <Form.Control type="text" name="address.street" 
                                            onChange={handleChange} onBlur={handleBlur} value={values.address.street} 
                                            isValid={getIn(touched, 'address.street') && !getIn(errors, 'address.street')}
                                            isInvalid={getIn(touched, 'address.street') && getIn(errors, 'address.street')}
                                        />
                                        <Form.Control.Feedback type="invalid">{getIn(errors, 'address.street')}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm={2}>
                                    <Form.Group controlId="address.number">
                                        <Form.Label>Hausnummer:</Form.Label>
                                        <Form.Control type="text" name="address.number" 
                                            onChange={handleChange} onBlur={handleBlur} value={values.address.number} 
                                            isValid={getIn(touched, 'address.number') && !getIn(errors, 'address.number')}
                                            isInvalid={getIn(touched, 'address.number') && getIn(errors, 'address.number')}
                                        />
                                        <Form.Control.Feedback type="invalid">{getIn(errors, 'address.number')}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col sm={2}>
                                    <Form.Group controlId="address.zip">
                                        <Form.Label>Postleitzahl:</Form.Label>
                                        <Form.Control type="text" name="address.zip" 
                                            onChange={handleChange} onBlur={handleBlur} value={values.address.zip} 
                                            isValid={getIn(touched, 'address.zip') && !getIn(errors, 'address.zip')}
                                            isInvalid={getIn(touched, 'address.zip') && getIn(errors, 'address.zip')}
                                        />
                                        <Form.Control.Feedback type="invalid">{getIn(errors, 'address.zip')}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="address.city">
                                        <Form.Label>Ort:</Form.Label>
                                        <Form.Control type="text" name="address.city" 
                                            onChange={handleChange} onBlur={handleBlur} value={values.address.city} 
                                            isValid={getIn(touched, 'address.city') && !getIn(errors, 'address.city')}
                                            isInvalid={getIn(touched, 'address.city') && getIn(errors, 'address.city')}
                                        />
                                        <Form.Control.Feedback type="invalid">{getIn(errors, 'address.city')}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group controlId="address.country">
                                        <Form.Label>Land:</Form.Label>
                                        <Form.Select name="address.country"
                                            onChange={handleChange} onBlur={handleBlur} value={values.address.country} 
                                            isValid={getIn(touched, 'address.country') && !getIn(errors, 'address.country')}
                                            isInvalid={getIn(touched, 'address.country') && getIn(errors, 'address.country')}
                                        >   
                                            <Countries/>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{getIn(errors, 'address.country')}</Form.Control.Feedback>
                                    </Form.Group>                                                       
                                </Col>                                    
                            </Row>

                            <Card.Title className="mt-5 mb-3">Rechnungsadresse
                                <span className="mx-4"
                                    onClick={() => setinvoiceAddress(!invoiceAddress)}
                                    aria-controls="example-collapse-text"
                                    aria-expanded={invoiceAddress}
                                >
                                öffnen
                                </span>
                            </Card.Title>
                            <Collapse in={invoiceAddress}>
                                <div>
                                <Row className="mb-2">
                                    <Col sm={10}>
                                        <Form.Group controlId="invoiceAddress.street">
                                            <Form.Label>Straße:</Form.Label>
                                            <Form.Control type="text" name="invoiceAddress.street" 
                                                onChange={handleChange} onBlur={handleBlur} value={values.invoiceAddress?.street} 
                                                isValid={getIn(touched, 'invoiceAddress.street') && !getIn(errors, 'invoiceAddress.street')}
                                                isInvalid={getIn(touched, 'invoiceAddress.street') && getIn(errors, 'invoiceAddress.street')}
                                            />
                                            <Form.Control.Feedback type="invalid">{getIn(errors, 'invoiceAddress.street')}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group controlId="invoiceAddress.number">
                                            <Form.Label>Hausnummer:</Form.Label>
                                            <Form.Control type="text" name="invoiceAddress.number" 
                                                onChange={handleChange} onBlur={handleBlur} value={values.invoiceAddress?.number} 
                                                isValid={getIn(touched, 'invoiceAddress.number') && !getIn(errors, 'invoiceAddress.number')}
                                                isInvalid={getIn(touched, 'invoiceAddress.number') && getIn(errors, 'invoiceAddress.number')}
                                            />
                                            <Form.Control.Feedback type="invalid">{getIn(errors, 'invoiceAddress.number')}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col sm={2}>
                                        <Form.Group controlId="invoiceAddress.zip">
                                            <Form.Label>Postleitzahl:</Form.Label>
                                            <Form.Control type="text" name="invoiceAddress.zip" 
                                                onChange={handleChange} onBlur={handleBlur} value={values.invoiceAddress?.zip} 
                                                isValid={getIn(touched, 'invoiceAddress.zip') && !getIn(errors, 'invoiceAddress.zip')}
                                                isInvalid={getIn(touched, 'invoiceAddress.zip') && getIn(errors, 'invoiceAddress.zip')}
                                            />
                                            <Form.Control.Feedback type="invalid">{getIn(errors, 'invoiceAddress.zip')}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group controlId="invoiceAddress.city">
                                            <Form.Label>Ort:</Form.Label>
                                            <Form.Control type="text" name="invoiceAddress.city" 
                                                onChange={handleChange} onBlur={handleBlur} value={values.invoiceAddress?.city} 
                                                isValid={getIn(touched, 'invoiceAddress.city') && !getIn(errors, 'invoiceAddress.city')}
                                                isInvalid={getIn(touched, 'invoiceAddress.city') && getIn(errors, 'invoiceAddress.city')}
                                            />
                                            <Form.Control.Feedback type="invalid">{getIn(errors, 'invoiceAddress.city')}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group controlId="invoiceAddress.country">
                                            <Form.Label>Land:</Form.Label>
                                            <Form.Select name="invoiceAddress.country"
                                                onChange={handleChange} onBlur={handleBlur} value={values.invoiceAddress?.country} 
                                                isValid={getIn(touched, 'invoiceAddress.country') && !getIn(errors, 'invoiceAddress.country')}
                                                isInvalid={getIn(touched, 'invoiceAddress.country') && getIn(errors, 'invoiceAddress.country')}
                                            >   
                                                <Countries/>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">{getIn(errors, 'invoiceAddress.country')}</Form.Control.Feedback>
                                        </Form.Group>                                                       
                                    </Col>                                    
                                </Row>
                                </div>
                            </Collapse>

                            <Card.Title className="mt-5 mb-3">Ansprechpersonen</Card.Title>
                                  
                            <FieldArray name="contacts">
                                {(fieldArrayProps) => 
                                    (
                                        fieldArrayProps.form.values.contacts.map((contact, i) => {
                                            const firstNameName = `contacts[${i}].person.firstName`;
                                            const lastNameName = `contacts[${i}].person.lastName`;
                                            const titelName = `contacts[${i}].person.title`;
                                            const phoneName = `contacts[${i}].person.phone`;
                                            const mailName = `contacts[${i}].person.email`;
                                            const depName = `contacts.[${i}].dep`;

                                            return ( 
                                                <div className="mb-4" key={'contact'+i}>
                                                    
                                                    <Row className="mb-2">
                                                        <Col sm={4}>
                                                            <Form.Group controlId={depName}>
                                                                <Form.Label>Bezeichnung:</Form.Label>
                                                                <Form.Control type="text" name={depName} 
                                                                    value={contact.dep}
                                                                    onChange={handleChange} onBlur={handleBlur}
                                                                    isValid={getIn(touched, depName) && !getIn(errors, depName)}
                                                                    isInvalid={getIn(touched, depName) && getIn(errors, depName)}
                                                                    placeholder="z.B. QM, Eigentümer, CEO..."
                                                                />
                                                                <Form.Control.Feedback type="invalid">{getIn(errors, depName)}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-2">
                                                        <Col sm={2}>
                                                            <Form.Group className="" controlId={titelName}>
                                                                <Form.Label>Titel:</Form.Label>
                                                                <Form.Control type="text" name={titelName} 
                                                                    value={contact.person?.title}
                                                                    onChange={handleChange} onBlur={handleBlur}                                                
                                                                    isValid={getIn(touched, titelName) && !getIn(errors, titelName)}
                                                                    isInvalid={getIn(touched, titelName) && getIn(errors, titelName)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{getIn(errors, titelName)}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>                       
                                                        <Col sm={5}>
                                                            <Form.Group className="" controlId={firstNameName}>
                                                                <Form.Label>Vorname:</Form.Label>
                                                                <Form.Control type="text" name={firstNameName}
                                                                    value={contact.person?.firstName}
                                                                    onChange={handleChange} onBlur={handleBlur}                                                
                                                                    isValid={getIn(touched, firstNameName) && !getIn(errors, firstNameName)}
                                                                    isInvalid={getIn(touched, firstNameName) && getIn(errors, firstNameName)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{getIn(errors, firstNameName)}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>                       
                                                        <Col sm={5}>
                                                            <Form.Group className="" controlId={lastNameName}>
                                                                <Form.Label>Nachname:</Form.Label>
                                                                <Form.Control type="text" name={lastNameName}
                                                                    value={contact.person?.lastName}
                                                                    onChange={handleChange} onBlur={handleBlur}                                                
                                                                    isValid={getIn(touched, lastNameName) && !getIn(errors, lastNameName)}
                                                                    isInvalid={getIn(touched, lastNameName) && getIn(errors, lastNameName)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{getIn(errors, lastNameName)}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>                       
                                                    </Row>
                                                    <Row className="mb-4">
                                                        <Col sm={8}>
                                                            <Form.Group controlId={mailName}>
                                                                <Form.Label>E-Mail:</Form.Label>
                                                                <Form.Control type="text" name={mailName} 
                                                                    value={contact.person?.email}
                                                                    onChange={handleChange} onBlur={handleBlur}
                                                                    isValid={getIn(touched, mailName) && !getIn(errors, mailName)}
                                                                    isInvalid={getIn(touched, mailName) && getIn(errors, mailName)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{getIn(errors, mailName)}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Form.Group controlId={phoneName}>
                                                                <Form.Label>Telefon:</Form.Label>
                                                                <Form.Control type="text" name={phoneName}
                                                                    value={contact.person?.phone}
                                                                    onChange={handleChange} onBlur={handleBlur} 
                                                                    isValid={contact.person?.phone && getIn(touched, phoneName) && !getIn(errors, phoneName)}
                                                                    isInvalid={getIn(touched, phoneName) && getIn(errors, phoneName)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{getIn(errors, phoneName)}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>                       
                                                    </Row>
                                                    <Row className="mb-5">
                                                        <Col sm={12}>
                                                        <ButtonGroup className="float-end" aria-label="Aktionen">
                                                        {values.contacts.length > 1 &&
                                                        <Button variant="outline-warning" className="float-end" onClick={(i) => {
                                                                console.log('Clicky! entferne ' + i);
                                                                const contacts = [...values.contacts];
                                                                contacts.splice(i,1);
                                                                setValues({ ...values, contacts });
                                                            }}>Entfernen</Button>                                         
                                                        }
                                                        <Button variant="outline-primary" className="float-end" onClick={() => {
                                                            console.log('Clicky! ' , values);
                                                            const contacts = [...values.contacts];
                                                            contacts.push({});
                                                            setValues({ ...values, contacts });
                                                        }}>Hinzufügen</Button> 
                                                        </ButtonGroup>
                                                        </Col>
                                                    </Row>                      
                                                </div>                                                                                      
                                            );
                                        })
                                    )
                                }
                            </FieldArray>                                                    

                            <Card.Title className="mt-4">Produktionsstätten</Card.Title>
                                   
                            <FieldArray name="productionSites">
                                {() => (values.productionSites.map((site, i) => {
                                    const nameName = `productionSites.[${i}].name`;
                                    const streetName = `productionSites.[${i}].address.street`;
                                    const numberName = `productionSites.[${i}].address.number`;
                                    const zipName = `productionSites.[${i}].address.zip`;
                                    const cityName = `productionSites.[${i}].address.city`;
                                    const countryName = `productionSites.[${i}].address.country`;
                                    return ( 
                                        <div className="mb-4" key={'productionSite'+i}>
                                        
                                        <Form.Group className="mb-2" controlId={nameName}>
                                            <Form.Label>Name der {i+1}. Produktionsstätte:</Form.Label>
                                            <Form.Control type="text" name={nameName}
                                                value={site.name}
                                                onChange={handleChange} onBlur={handleBlur}                                                
                                                isValid={getIn(touched, nameName) && !getIn(errors, nameName)}
                                                isInvalid={getIn(touched, nameName) && getIn(errors, nameName)}
                                            />
                                            <Form.Control.Feedback type="invalid">{getIn(errors, nameName)}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Row className="mb-2">
                                            <Col sm={10}>
                                                <Form.Group controlId={streetName}>
                                                    <Form.Label>Straße:</Form.Label>
                                                    <Form.Control type="text" name={streetName}
                                                        value={site.address?.street}
                                                        onChange={handleChange} onBlur={handleBlur} 
                                                        isValid={getIn(touched, streetName) && !getIn(errors, streetName)}
                                                        isInvalid={getIn(touched, streetName) && getIn(errors, streetName)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{getIn(errors, streetName)}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>                       
                                            <Col sm={2}>
                                                <Form.Group controlId={numberName}>
                                                    <Form.Label>Hausnummer:</Form.Label>
                                                    <Form.Control type="text" name={numberName}
                                                        value={site.address?.number}
                                                        onChange={handleChange} onBlur={handleBlur}
                                                        isValid={getIn(touched, numberName) && !getIn(errors, numberName)}
                                                        isInvalid={getIn(touched, numberName) && getIn(errors, numberName)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{getIn(errors, numberName)}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col sm={2}>
                                                <Form.Group controlId={zipName}>
                                                    <Form.Label>Postleitzahl:</Form.Label>
                                                    <Form.Control type="text" name={zipName}
                                                        value={site.address?.zip}
                                                        onChange={handleChange} onBlur={handleBlur}
                                                        isValid={getIn(touched, zipName) && !getIn(errors, zipName)}
                                                        isInvalid={getIn(touched, zipName) && getIn(errors, zipName)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{getIn(errors, zipName)}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Group controlId={cityName}>
                                                    <Form.Label>Ort:</Form.Label>
                                                    <Form.Control type="text" name={cityName} 
                                                        value={site.address?.city}
                                                        onChange={handleChange} onBlur={handleBlur}
                                                        isValid={getIn(touched, cityName) && !getIn(errors, cityName)}
                                                        isInvalid={getIn(touched, cityName) && getIn(errors, cityName)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{getIn(errors, cityName)}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Group controlId={countryName}>
                                                    <Form.Label>Land:</Form.Label>
                                                    <Form.Select name={countryName}
                                                        value={site.address?.country}
                                                        onChange={handleChange} onBlur={handleBlur}
                                                        isValid={getIn(touched, countryName) && !getIn(errors, countryName)}
                                                        isInvalid={getIn(touched, countryName) && getIn(errors, countryName)}
                                                    >
                                                        <Countries/>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{getIn(errors, countryName)}</Form.Control.Feedback>
                                                </Form.Group>                                                       
                                            </Col>                                    
                                        </Row>
                                        <Row className="mb-5">
                                            <Col sm={12}>
                                                <ButtonGroup className="float-end" aria-label="Aktionen">
                                                <Button variant="outline-warning" className="float-end" onClick={(i) => {
                                                    console.log('Clicky! entferne ' + i);
                                                    const productionSites = [...values.productionSites];
                                                    productionSites.splice(i,1);
                                                    setValues({ ...values, productionSites });
                                                }}>Entfernen</Button> 
                                                <Button variant="outline-primary" className="float-end" onClick={() => {
                                                    console.log('Clicky!');
                                                    const productionSites = [...values.productionSites];
                                                    productionSites.push({});
                                                    setValues({ ...values, productionSites });
                                                    }}>Hinzufügen</Button>
                                                </ButtonGroup>
                                            </Col>
                                        </Row> 
                                    </div>                                                                                      
                                    );
                                }))}
                            </FieldArray>
                            { values.productionSites?.length == 0 && ( 
                                <Row className="mb-5">
                                    <Col sm={12}>
                                        <Button variant="outline-primary" className="float-end" onClick={() => {
                                            console.log('Clicky!');
                                            const productionSites = [...values.productionSites];
                                            productionSites.push({});
                                            setValues({ ...values, productionSites });
                                            }}>Hinzufügen
                                        </Button>
                                    </Col>
                                </Row>
                            )} 

                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button variant="outline-primary" type="submit" disabled={isSubmitting}>
                            { client?.clientNumber ? (
                                <span>Daten ändern</span>
                            ) : (
                                <span>Kunden anlegen</span>
                            )
                            }
                            </Button>
                        </Card.Footer>
                        </Card>
                    </Form>
                )}
                </Formik> 
            </div>                                  
        </section>
    )
}

export default AddClient