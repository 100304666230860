import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button, ButtonGroup, Form, Col, Row, Card } from "react-bootstrap";
import { Formik, } from 'formik';
import * as Yup from 'yup';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import MyAlert from "./MyAlert";
import MsgHolder from "../util/MsgHolder";
import Address from "../fragments/Address";
import Stati from "../util/Stati";

const AddCertification = () => {
    const navigate = useNavigate();
    const [msgHolder, setMsgHolder] = useState(new MsgHolder());
    const [certification, setCertification] = useState();
    const [editMode, setEditMode] = useState(false);
    const [certificate, setCertificate] = useState();
    const axiosPrivate = useAxiosPrivate();
    const [client, setClient] = useState();

    function initFormValues() {
        return (editMode ? {
                validFrom : certificate?.validFrom?.substring(0,10),
                productionSites : certificate?.productionSites,
                products : certificate?.products
            } : {
                validFrom : certification?.validFrom?.substring(0,10),
                productionSites : [],
                products : []
            }
        );
    }

    function createYupSchema() {
        return Yup.object({
            validFrom: Yup.string().required('Das Gültigkeitsdatum muss ausgewählt werden.')
        });
    }

    const getCertification = async (certId) => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.get(`/certification/${certId}`, {
                signal : controller.signal
            });
            setCertification(response.data);
        } catch (err) {
            console.log('getCertification: api responded with error:');
            console.log(err);
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }

    const getCertificate = async (certificationId, certificateId) => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.get(`/certificate/${certificateId}/certification/${certificationId}`, {
                signal : controller.signal
            });
            setCertificate(response.data);
        } catch (err) {
            console.log('getCertificate: api responded with error:');
            console.log(err);
            var msgHolder = new MsgHolder(err.response?.data?.message? err.response.data.message : err.message);
            navigate('/clients', {state : {id : 1 , msgHolder : msgHolder}});
        }
    }

    const getClient = async (clientId) => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.get(`/client/${clientId}`, {
                signal : controller.signal
            });
            setClient(response.data);
        } catch (err) {
            console.log('getClient: api responded with error:');
            console.log(err);
            msgHolder = new MsgHolder(err.response?.data?.message? err.response.data.message : err.message);
            navigate('/clients', {state : {id : 1 , msgHolder : msgHolder}});
        }
    }

    const handlePreview = async (values, setSubmitting, lang) => {
        console.log("addCertificate.handlePreview");
        setMsgHolder();
        var preview = true;
        await submitForm(values, { setSubmitting, preview, lang });
    }

    const downloadDocument = async (certificateId, lang) => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.get(`/download/preview/${certification._id}/${certificateId}/${lang}`,
                {
                    signal : controller.signal,
                    responseType: 'blob'
                });
            // create file link in browser's memory
            const url = window.URL.createObjectURL(new Blob([response.data]));
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Zertifikat_Vorschau_${certificate?.certificateNumber}_${lang}.docx`);
            document.body.appendChild(link);
            link.click();
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        } catch (err) {
            console.log('addCertificate.handlePreviewEN: api responded with error!');
            console.log(err);
            setMsgHolder(new MsgHolder("Die Vorschau konnte nicht erstellt werden."));
        }
    }

    const submitForm = async (values, { setSubmitting, preview, lang }) => {
        const controller = new AbortController();
        console.log("addCertification: form values: " + JSON.stringify(values));
        var response = null;
        var localMsgHolder;
        try {
            console.log("addCertification:  Submiting...");
            if (!editMode) {
                response = await axiosPrivate.post(`/certificate/certification/${certification._id}`, 
                    JSON.stringify(values), {
                        signal : controller.signal
                    });
                localMsgHolder = new MsgHolder('Das Zertifikat wurde erstellt.', 'success');
            } else {
                response = await axiosPrivate.put(`/certificate/${certificate._id}/certification/${certification._id}`, 
                    JSON.stringify(values), {
                        signal : controller.signal
                    });
                localMsgHolder = new MsgHolder('Das Zertifikat wurde geändert.', 'success');
            }
            if (!preview) {
                navigate(`/certificationDetails?cid=${certification._id}&key=zertifikate`, {state : {id : 1 , msgHolder : localMsgHolder}});
            } else {
                setMsgHolder(localMsgHolder);
                if (!editMode) {
                    setCertificate(response.data);
                    setEditMode(true);
                }
                downloadDocument(response.data._id, lang);
            }
        } catch (err) {
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
        
        setSubmitting(false);
    }

    const deleteCertificate = async () => {
        const controller = new AbortController();
        console.log('delete Certificate.');
        try {
            await axiosPrivate.delete(`/certificate/${certificate._id}/certification/${certification._id}`, {
                signal : controller.signal
            });
            navigate(`/certificationDetails?cid=${certification._id}&key=zertifikate`, 
                {state : {id : 1 , msgHolder : new MsgHolder(`Das Zertifikat ${certificate.certificateNumber} wurde gelöscht.`, 'success')}});
        } catch (err) {
            console.log('deleteCertificate: api responded with error:');
            console.log(err);
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }

    const approveCertificate = async () => {
        const controller = new AbortController();
        console.log('approveCertificate.');
        try {
            var result = await axiosPrivate.post(`/approval/add/certificate/${certificate._id}/certification/${certification._id}`, {
                signal : controller.signal
            });
            navigate(`/certificationDetails?cid=${certification._id}&key=zertifikate`, 
                {state : {id : 1 , msgHolder : new MsgHolder(`Das Zertifikat ${certificate.certificateNumber} wurde zur Prüfung freigegeben.`, 'success')}});
        } catch (err) {
            console.log('approveCertificate: api responded with error:');
            console.log(err);
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }

    useEffect(() => {
        let params = new URLSearchParams(document.location.search);
        let certificationId = params.get("certificationId");
        let clientId = params.get("clientId");
        if (certificationId) {
            getCertification(certificationId);
            getClient(clientId);
            let certificateId = params.get("certificateId");
            if (certificateId) {
                getCertificate(certificationId, certificateId);
                setEditMode(true);
            }
        } else {
            navigate('/certificationsList', {state : {id : 1 , msgHolder : new MsgHolder("AddCertification ohne certificateID aufgerufen.")}});
        }
        return () => {
        }
    }, []);
    var initValues = initFormValues();
    return (
        <section>
            <div className="flexGrow">
                <Formik
                    initialValues = {initValues}
                    enableReinitialize = {true}
                    validationSchema = {createYupSchema}
                    onSubmit = {submitForm}
                >
                {({
                    handleSubmit, handleChange, handleBlur, setSubmitting, setTouched,
                    isSubmitting, values, touched, errors, form, formik
                }) => (
                    <Form onSubmit={handleSubmit} id="form">
                        <Card className="inputCard shadow-sm">
                            <Card.Header>
                                Zertifizierung für {certification?.clientName}
                                { certificate ? (
                                    <span className="float-end">Zertifikatsnummer: {certificate?.certificateNumber}</span>
                                ) : (
                                    <span className="float-end">Zertifizierungsnummer: {certification?.certificationNumber}</span>
                                )}
                            </Card.Header>
                            <Card.Body>
                                <MyAlert msgHolder={msgHolder}/>
                                <Card.Title>Zertifikat</Card.Title>

                                <Card.Title>Gültigkeit</Card.Title>
                                
                                <Row className="mb-2 mt-2">
                                    <Col sm={3}>
                                        <Form.Group controlId="validFrom" className="mb-3">
                                            <Form.Label>Gültig ab: (Mindestens gültig ab von Certification!)</Form.Label>
                                            <Form.Control type="date" min="2010-01-01"
                                                name="validFrom"
                                                onChange={handleChange} onBlur={handleBlur} value={values.validFrom}                                              
                                                isValid={touched.validFrom && !errors.validFrom}
                                                isInvalid={errors.validFrom}
                                            />
                                            <div className='invalid-feedback display-block'>{errors.validFrom}</div>
                                        </Form.Group>
                                    </Col>             
                                </Row>

                                {client?.productionSites?.length > 0 && ( 
                                    <div>
                                        <Card.Title><Form.Label>Produktionsstätten</Form.Label></Card.Title>
                                        { client?.productionSites?.map((site, i) =>
                                        <Row className="mb-3" id={i} key={'site'+i}>
                                            <Col sm={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Check name="productionSites" type='checkbox'
                                                        value={site._id} label={site.name}
                                                        checked={values?.productionSites?.includes(site._id)}
                                                        onChange={handleChange} onBlur={handleBlur}
                                                    />
                                                </Form.Group>  
                                            </Col>
                                            <Col>
                                                <Address address={site.address}/>            
                                            </Col>
                                        </Row>
                                        )}
                                    </div> 
                                )}

                                <Card.Title>Produkte</Card.Title>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                            </th>
                                            <th scope="col">Nummer</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Info</th>
                                            <th scope="col">Name Englisch</th>
                                            <th scope="col">Info Englisch</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { certification?.products?.map((product, i) =>
                                            <tr className="mb-2" key={'product'+i}>
                                                <td>
                                                    <Form.Group controlId="products">
                                                        <Form.Check name="products" type='checkbox'
                                                            value={product._id} checked={values?.products?.includes(product._id)}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    {product?.number}
                                                </td>
                                                <td>
                                                    {product?.name}
                                                </td>
                                                <td>
                                                    {product?.info}
                                                </td>
                                                <td>
                                                    {product?.nameEN}
                                                </td>
                                                <td>
                                                    {product?.infoEN}
                                                </td>                                                                                                                                                            
                                            </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
          

                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button className="mx-2" variant="outline-dark" id="backButton"
                                    onClick={() => {
                                        navigate({
                                            pathname: '/certificationDetails',
                                            search: `cid=${certification?._id}&key=zertifikate`
                                        });
                                    }}>
                                    zurück
                                </Button>
                                <ButtonGroup aria-label="Vorschau">
                                    <Button variant="outline-primary" type="button"  disabled={isSubmitting}
                                        onClick={() => {
                                            handlePreview(values, setSubmitting, 'de');
                                        }}>
                                        <span>Vorschau DE</span>
                                    </Button>
                                    <Button variant="outline-primary" type="button" className="mr-2" disabled={isSubmitting}
                                        onClick={() => {
                                            handlePreview(values, setSubmitting, 'en');
                                        }}>
                                        <span>EN</span>
                                    </Button>
                                </ButtonGroup>
                                <Button variant="outline-primary" type="submit" className="mx-2" disabled={isSubmitting}>
                                    { editMode ? (<span>Speichern</span>) :
                                        (<span>Zertifikat erstellen</span>)}
                                </Button>
                                { certificate?.stati[certificate.stati.length-1].status === Stati.CERTIFICATE_STATUS.WIP && 
                                    <span>
                                        <Button variant="outline-danger" id="detailsButton" className="mx-2"
                                            onClick={deleteCertificate}>
                                            Löschen
                                        </Button>
                                        
                                        <Button variant="outline-success" id="detailsButton" className="mx-2"
                                            onClick={approveCertificate}>
                                            Genehigung
                                        </Button> 
                                    </span>
                                }                                
                            </Card.Footer>
                        </Card>
                    </Form>
                )}
                </Formik> 
            </div>                                  
        </section>
    )
}

export default AddCertification