import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import MyAlert from "./MyAlert";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import MsgHolder from "../util/MsgHolder";
import Certifications from "./Certifications";

const CertificationsList = () => {
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const [certifications, setCertifications] = useState();
    const [msgHolder, setMsgHolder] = useState(new MsgHolder());
   
    const getCertifications = async () => {
        const controller = new AbortController();
        console.log('getCertifications: fetching certificationsients from db.');
        try {
            const response = await axiosPrivate.get('/certification', {
                signal : controller.signal
            });
            console.log('getCertifications: api response:');
            console.log(response.data);
            setCertifications(response.data);
        } catch (err) {
            console.log('getCertifications: api responded with error:');
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }

    useEffect(() => {
        console.log('useEffect... calling getCertifications');
        getCertifications();
        if (location?.state?.msgHolder) {
            console.log('setting msg from location: ' , location.state?.msgHolder );
            setMsgHolder(location.state?.msgHolder);
        }
        return () => {
        }
    }, []);

    return (
        <section>
            <h2>Zertifizierungen</h2>
            <MyAlert msgHolder={msgHolder}/>
            <div className="flexGrow">
                {certifications?.length ?
                    (
                        <Certifications certifications={certifications} list={true}/>
                    ) : (
                        <div>Keine Zertifikate angelegt.</div> 
                    )
                }                       
            </div>
        </section>
    )
}

export default CertificationsList;