import { createContext, useState } from "react";

const ConsentContext = createContext({});

export const ConsentProvider = ({ children }) => {
    const [consent, setConsent] = useState(false);

    return (
        <ConsentContext.Provider value={{ consent, setConsent }}>
            {children}
        </ConsentContext.Provider>
    )
}

export default ConsentContext;