import Alert from 'react-bootstrap/Alert';

const MyAlert = (msgHolderState) => {
  if (!msgHolderState) {
    return;
  }
  const { msgHolder } = msgHolderState;  // Deconstruct msgHolder from state variable
  if (!msgHolder) {
    return;
  }
  if (msgHolder.msg) {
    return (
      <div>
        <Alert key={'error'} variant={msgHolder.variant}>
          {msgHolder.msg}
        </Alert>
      </div>
    )
  } else {
  return (
    <div>
      {msgHolder?.msg}
      {msgHolder?.errors?.map((error, i) =>
          <Alert key={'error'+i} variant='danger'>
            {error}
          </Alert>
      )}
      {msgHolder?.warnings?.map((warning, i) =>
          <Alert key={'warning'+i} variant='warning'>
            {warning}
          </Alert>
      )}
      {msgHolder?.infos?.map((info, i) =>
          <Alert key={'info'+i} variant='info'>
            {info}
          </Alert>
      )}
      {msgHolder?.ok?.map((ok, i) =>
          <Alert key={'ok'+i} variant='success'>
            {ok}
          </Alert>
      )}      
    </div>
  )
      }
  
}

export default MyAlert;
