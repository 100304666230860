import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button, Table, Card } from "react-bootstrap";
import MyAlert from "./MyAlert";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import MsgHolder from "../util/MsgHolder";

const Clients = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate(); 
    const [clients, setClients] = useState();
    const [msgHolder, setMsgHolder] = useState(new MsgHolder());
   
    
    useEffect(() => {
        
        const getClients = async () => {
            const controller = new AbortController();
            console.log('getClients: fetching clients from db.');
            try {
                const response = await axiosPrivate.get('/client', {
                    signal : controller.signal
                });
                console.log('getClients: api response:');
                console.log(response.data);
                setClients(response.data);
            } catch (err) {
                console.log('getClients: api responded with error:');
                setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
            }
        }

        console.log('useEffect... calling getClients');
        getClients();
        console.log('useEffect... location:');
        console.log(location);
        if (location.state?.msgHolder) {
            console.log('setting msg from location: ' , location.state?.msgHolder );
            setMsgHolder(location.state?.msgHolder);
        }
        return () => {
        }
    }, [location, axiosPrivate, msgHolder]);

    return (
        <section>
            <h2>Kundenliste</h2>
            <MyAlert msgHolder={msgHolder}/>
            <div className="flexGrow">
                <Card>
                    <Card.Body>
                        {clients?.length ?
                            ( 
                                <Table className="table-striped">
                                    <thead>        
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Land</th>
                                            <th>
                                                <Button className="btn-sm" variant="outline-primary" id="addClientButton"        
                                                    onClick={() => { 
                                                        navigate('/addClient');
                                                    }}>
                                                    Kunden anlegen
                                                </Button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {clients.map((client, i) => 
                                            <tr key={client._id}>
                                                <td>{client.clientNumber}</td>
                                                <td>{client.name}</td>
                                                <td>{client.address.country}</td>
                                                <td>                                        
                                                    <Button className="btn-sm" variant="outline-primary" id="addClientButton"        
                                                        onClick={() => { 
                                                            navigate({
                                                                pathname: "/clientDetails",
                                                                search: "cid="+client._id
                                                            });
                                                        }}>
                                                        Details
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                        }      
                                    </tbody>                                                          
                                </Table>
                            ) : (
                                <div>Keine Kunden angelegt.<br/>
                                    <Button className="btn-sm" variant="outline-primary" id="addClientButton"        
                                            onClick={() => { navigate('/addClient');}}>
                                        Kunden anlegen
                                    </Button>
                                </div> 
                            )
                        }                       
                    </Card.Body>
                </Card>
            </div>
        </section>
    )
}

export default Clients