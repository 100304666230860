import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshTocken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/refresh', {
            withCredentials : true
        });
        setAuth(prev => {
            console.log('useRefreshToken: prev auth state:');
            console.log(JSON.stringify(prev));
            console.log('useRefreshToken: new accessToken:' + response.data.accessToken);
            return {...prev, accessToken : response.data.accessToken};
        });
        return response.data.accessToken;
    }
    return refresh;
}

export default useRefreshTocken;