import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Table } from "react-bootstrap";
import MyAlert from "./MyAlert";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import MsgHolder from "../util/MsgHolder";

const CertificateApprovalsList = () => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [approvals, setApprovals] = useState();
    const [msgHolder, setMsgHolder] = useState(new MsgHolder());
  
    const getApprovals = async () => {
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.get('approval/', {
                signal : controller.signal
            });
            console.log('getApprovals: api response:');
            console.log(response.data);
            setApprovals(response.data);
        } catch (err) {
            console.log('getApprovals: api responded with error:');
            setMsgHolder(new MsgHolder(err.response?.data?.message? err.response.data.message : err.message));
        }
    }

    useEffect(() => {
        getApprovals();
        return () => {
        }
    }, []);

    return (
        <section>
            <h2>Zertifikate zur Prüfung und Genehmigung</h2>
            <MyAlert msgHolder={msgHolder}/>
            <div className="flexGrow">
                {approvals?.length ? (
                    <Card className="shadow-sm">
                        <Table className="table-striped">
                            <thead>        
                                <tr>
                                    <th>Zertifikat {approvals?.length}</th>
                                    <th>Firma</th>
                                    <th>Bearbeitet von</th>
                                    <th>Datum</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {approvals.map((approval, i) => 
                                    <tr key={i}>
                                        <td>{approval?.certificateNumber}</td>
                                        <td>{approval?.clientName}</td>
                                        <td>{approval?.submitted.user}</td>
                                        <td>{new Date(approval?.submitted.ts).toLocaleString("DE")}</td>
                                        <td>                                        
                                            <Button className="btn-sm" variant="outline-primary" id="addClientButton"        
                                                onClick={() => { 
                                                    navigate({
                                                        pathname: "/certificationDetails",
                                                        search: `cid=${approval?._certificationId}&certificateId=${approval?._certificateId}&key=zertifikate`
                                                        });
                                                }}>
                                                Prüfen
                                            </Button>
                                        </td>
                                    </tr>
                                )}    
                            </tbody>                                                          
                        </Table>
                    </Card>  
                    ) : (
                        <div>Keine offenen Zertifikate gefunden.</div> 
                    )
                }                       
            </div>
        </section>
    )
}

export default CertificateApprovalsList;