import { useCallback, useState } from 'react';
import { Formik } from 'formik';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import useConsent from "../hooks/useConsent";
import useAuth from '../hooks/useAuth';
import axios from '../api/axios';
import MsgHolder from "../util/MsgHolder";
import MyAlert from './MyAlert';
import cookie from "../assets/images/cookie.jpg";
import cookieWebp from "../assets/images/cookie.webp";

const Login = () => {
    const { setAuth } = useAuth() ;

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const to = "/home";
    const [msgHolder, setMsgHolder] = useState(new MsgHolder());
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { consent, setConsent } = useConsent();
    const { t } = useTranslation();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
        }
    
        const token = await executeRecaptcha('Login');
        return token;
        // send token to server for verification
    }, [executeRecaptcha]);

    const updateConsent = () => {
        setConsent(!consent);
    }

    function initFormValues() {
        return ({
            userid : '',
            pwd : ''
        });
    }

    function createYupSchema() {
        return ( Yup.object({
          userid: Yup.string()
            .min(6, t('login.errUserKurz'))
            .max(50, t('login.errUserLang'))
            .required(t('login.errUserPflicht')),
          pwd: Yup.string()
            .min(8, t('login.errPwdKurz'))
            .max(50, t('login.errPwdLang'))
            .required(t('login.errPwdPflicht'))
          }));
      }

    return (
        <Container className="vh-100 d-flex justify-content-center align-items-center">
            { consent ? (
            <Formik
                initialValues = {initFormValues()}
                validationSchema = { createYupSchema() }
                onSubmit = {async (values, { setSubmitting, setErrors }) => {
                    const token = await handleReCaptchaVerify();
                    console.log("Rufe Login auf.");
                    try {
                        values.token = token;
                        const response = await axios.post('/login',
                            JSON.stringify(values),
                            {
                                headers: {'Content-Type' : 'application/json'},
                                withCredentials : true
                            }
                        );
                            
                        const userid = values.userid;
                        const accessToken = response?.data?.accessToken;
                        const _id = response?.data?.user._id;
                        const roles = response?.data?.user.roles;
                        const name = response?.data?.user.name;

                        setAuth({ _id, userid, roles, name, accessToken });  // Save user data to global auth object.
                        console.log("Login ok, gehe zu: " + to);
                        navigate(to, from, { replace: true });
                    } catch (err) {
                        console.log(err);
                        if (!err?.response) {
                            setMsgHolder(new MsgHolder(t('login.err')));
                        } else {
                            if (err.response?.status === 401) {
                                setMsgHolder(new MsgHolder(t('login.errUserInvalid')));
                            } else {
                                setMsgHolder(new MsgHolder(t('login.err')));
                            }
                        }
                    }
                    setSubmitting(false);
                  }}
            >
            {({
                handleSubmit, handleChange, handleBlur,
                isSubmitting, values, touched, errors
            }) => (
                <Card className="w-50 p-3 d-flex align-items-center justify-content-center bg-white shadow-lg">
                    <Card.Body className="w-50 p-2">
                        <Card.Title>
                        {t('login.title')}
                        </Card.Title>
                        <MyAlert msgHolder={msgHolder}/>
                        <Form onSubmit={handleSubmit}>
                            
                            <Row className="pt-4">
                                <Form.Group controlId="validationUserid">
                                    <Form.Label>{t('login.user')}</Form.Label>
                                    <Form.Control type="text" name="userid" 
                                        onChange={handleChange} onBlur={handleBlur} value={values.userid} 
                                        isValid={touched.userid && !errors.userid}
                                        isInvalid={errors.userid}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.userid}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="pt-4">
                                <Form.Group controlId="validationPwd">
                                    <Form.Label>{t('login.pwd')}</Form.Label>
                                    <Form.Control type="password" name="pwd" 
                                        onChange={handleChange} onBlur={handleBlur} value={values.pwd} 
                                        isValid={touched.pwd && !errors.pwd}
                                        isInvalid={errors.pwd}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.pwd}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="pt-4">
                                <Col>
                                    <Button className="btn-md" variant="outline-success" type="submit" disabled={isSubmitting}>Login</Button>
                                </Col>
                                <Col>
                                    <Button className="btn-md" variant="outline-primary" href="/">Zurück</Button>
                                </Col>
                            </Row>
                        </Form>
                    
                    </Card.Body>
                </Card>
            )}
            </Formik>
            ) : (
                <Row className='mt-4 pt-4'>
                  <Col sm={3}>
                    <picture>
                      <source srcSet={cookieWebp} type="image/webp"/>
                      <img src={cookie} className="img-fluid" alt='hamster with cookie'></img>
                    </picture>
                  </Col>
                  <Col>
                    <strong>{t('kontakt.dsHeader')}</strong>
                    <br/>
                    {t('login.dsPara1')}<br/><br/>
                    {t('kontakt.dsPara2')}
                    <br/><br/>
                    {t('kontakt.dsPara4')}
                    <br/><br/>
                    <Button onClick={updateConsent}>{t('kontakt.btnDs')}</Button>
                  </Col>
                </Row>
              )}
        </Container>
    )
}

export default Login