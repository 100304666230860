const CERTIFICATE_STATUS = {
    WIP : 10,
    IN_APPROVAL : 20,
    APPROVED : 30,
    REJECTED : 35,
    PUBLIC : 40,
    RETRACTED : 50
}

module.exports = {
    CERTIFICATE_STATUS
}