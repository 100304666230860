import { Fragment } from "react";
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const CustomerMessageStatus = (msgProp) => {

  if (!msgProp.message || !msgProp.message.status) {
    console.error("CustomerMessageStatus msgProp.message was: " , msgProp);
    return;
  }

  var status = msgProp.message.status;
  var variant;
  var txt , action;
  /*    New: 10,
    saved: 20,
    answeres: 30*/

  console.log('status: ' + status);
  switch(true) {
    case status < 20 :
      variant = 'primary';
      txt = 'Neu';
      break;
    case status >= 20 && status < 30:
      variant = 'secondary';
      txt = 'in Bearbeitung';
      action = msgProp.message.answeredByUser + ' hat die Anfrage am ' + 
      new Date(msgProp.message.answerDate).toLocaleString("DE") + ' bearbeitet.';
      break;
    case status >= 30:
      variant = 'success';
      txt = 'Beantwortet';
      action = msgProp.message.answeredByUser + ' hat die Anfrage am ' + 
      new Date(msgProp.message.answerDate).toLocaleString("DE") + ' beantwortet.';
      break;
    default:
      break;
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        {action}
      </Popover.Body>
    </Popover>
  );

  return ( 
    <Fragment>
      <OverlayTrigger trigger="click" placement="left" overlay={popover}>
        <Badge bg={variant}>
          {txt}
        </Badge>
      </OverlayTrigger>
    </Fragment>
  )
}
export default CustomerMessageStatus