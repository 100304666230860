import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Table, Col, Row, Card } from "react-bootstrap";
import ValidFromTo from "../fragments/ValidFromTo";
import CertStatus from "../fragments/CertStatus";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import MsgHolder from "../util/MsgHolder";

const Certifications = (props) => {
    const { certifications } = props;
    const { list } = props;
    const axiosPrivate = useAxiosPrivate(); 
    const [msgHolder, setMsgHolder] = useState(new MsgHolder());
    const navigate = useNavigate();

    const download = async (certification) => {
        console.log("Certification.download: Certificat id: " + certification._id);
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.get('/downloadCert/' + certification._id, {
                signal : controller.signal,
                responseType: 'blob'
            });
            // create file link in browser's memory
            const url = window.URL.createObjectURL(new Blob([response.data]));
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Zertifikat_' + certification.certificationNumber + '.docx');
            document.body.appendChild(link);
            link.click();
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        } catch (err) {
            console.log('Certification.download: api responded with error!');
            console.log(err);
            setMsgHolder(new MsgHolder("Das Zertifikat konnte nicht geöffnet werden."));
        }
    };

    return (
        <section>
            <Card>
            <Card.Body>
            {certifications?.length ? ( 
                <Table className="table-striped">
                    <thead>        
                        <tr>
                            <th>#</th>
                            {list && (
                                <th>Kunde</th>
                            )}
                            <th>Scope</th>
                            <th>Gültigkeit</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {certifications.map((certification, i) => 
                        <tr key={certification._id} id={'cert'+i}>
                            <td>{certification.certificationNumber}</td>
                            {list && (
                                <td>{certification.clientName}</td>
                            )}
                            <td>
                                {certification.category}
                            </td>
                            <td>
                                <ValidFromTo cert={certification}/>
                            </td>
                            <td>                                        
                                <CertStatus cert={certification}/>
                            </td>
                            <td>                                        
                                <Button className="btn-sm" variant="outline-primary" id="detailsButton"        
                                    onClick={() => { 
                                        navigate({
                                            pathname: "/certificationDetails",
                                            search: "cid=" + certification._id
                                            });
                                    }}>
                                    Details
                                </Button>
                                { certification.stati[certification.stati.length-1].status == 40 &&
                                    <Button className="btn-sm ms-2" variant="outline-primary" id="downloadButton"        
                                        onClick={() => {
                                            download(certification);
                                        }}>
                                        download <i class="bi bi-file-earmark-arrow-down"></i>
                                    </Button>
                                }
                            </td>                            
                        </tr>
                    )
                    }
                    </tbody>                                
                </Table>
            ) : (
                <Row>
                    <Col>Für diesen Kunden wurden keine Zertifizierungen gefunden.</Col>
                </Row>
            )}
            </Card.Body>
            </Card>
        </section>
    )
}

export default Certifications