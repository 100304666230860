import axios from 'axios';

const BASE_API_URL = 'http://localhost:3000/api';
const BASE_API_URL_P = 'https://www.iidc.at/api';

export default axios.create({
    baseURL : process.env.NODE_ENV === 'production' ? BASE_API_URL_P : BASE_API_URL,
    headers : { 'Content-Type' : 'application/json'}
});

export const axiosPrivate = axios.create({
    baseURL : process.env.NODE_ENV === 'production' ? BASE_API_URL_P : BASE_API_URL,
    headers : { 'Content-Type' : 'application/json'},
    withCredentials : true
});