import { Fragment } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import logo from "../../assets/images/IIDC_logo_white.png";
import logoWebp from "../../assets/images/IIDC_logo_white.webp";
import heroMobile from "../../assets/images/hero_schiff_mobile.webp"; // 500 x 500
import heroS from "../../assets/images/hero_schiff_s.webp"; // 780 x 550
import heroM from "../../assets/images/hero_schiff_m.webp"; // 1000 x 667
import heroL from "../../assets/images/hero_schiff_l.webp"; // 1300 x 700
import heroXL from "../../assets/images/hero_schiff_xl.webp"; // 1500 x 850
import heroXXL from "../../assets/images/hero_schiff_xxl.webp"; // 1920 x 1010
const Hero = () => {
  const { t } = useTranslation();
  return( 
    <Fragment>
        <article className="container-fluid mx-0 px-0">
          <div className="hero">
            
            <picture>
              <source media="(max-width: 500px)" srcSet={heroMobile} type="image/webp"/>
              <source media="(min-width: 500px) and (max-width: 780px)" srcSet={heroS} type="image/webp"/>
              <source media="(min-width: 780px) and (max-width: 1000px)" srcSet={heroM} type="image/webp"/>
              <source media="(min-width: 1000px) and (max-width: 1300px)" srcSet={heroL} type="image/webp"/>
              <source media="(min-width: 1300px) and (max-width: 1500px)" srcSet={heroXL} type="image/webp"/>
              <source media="(min-width: 1500px)" srcSet={heroXXL} type="image/webp"/>
              <img src={heroMobile} alt="Containership sailing into the sunset"/>
            </picture>

            <div className="hero-text px-2 px-md-3 px-lg-5">
              <div className="row pt-4">
                <div className="col-7 col-sm-8">
                </div>
                <div className="col text-end">
                  <picture>
                    <source srcSet={logoWebp} type="image/webp"/>
                    <img src={logo} className="img-fluid" alt="IIDC Logo"/>
                  </picture>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col">
                  <h1 className="heroTitle">{t('hero.title')}</h1>
                  <h2 className="heroSubTitle">{t('hero.subtitle')}</h2>
                  <Button href="#vorteile" variant="btn btn-outline-success publicBtn">{t('hero.mehr')}</Button>
                  <Button href="#kontakt" variant="btn btn-outline-success publicBtn ms-4">{t('links.kontakt')}</Button>
                </div>
                <div className="col-sm-5 col-lg-4 navigation d-none d-sm-block">
                  <div className="text-end">
                    <ul>
                      <li className="pt-4">
                        <a className="heroLink fs-3" href="#vorteile">{t('links.vorteile')}</a>
                      </li>
                      <li className="pt-3">
                        <a className="heroLink fs-3" href="#zertifizierung">{t('links.zertifizierung')}</a>
                      </li>                
                      <li className="pt-3">
                        <a className="heroLink fs-3" href="#akkreditierungen">{t('links.akkreditierungen')}</a>
                      </li>
                      <li className="pt-3">
                        <a className="heroLink fs-3" href="#kontakt">{t('links.kontakt')}</a>
                      </li>
                      <li className="pt-3">
                        <a className="heroLink fs-3" href="#panel">{t('links.panel')}</a>
                      </li>
                      <li className="pt-3">
                        <a className="heroLink fs-3 active" aria-current="page" href="#about">{t('links.about')}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
    </Fragment>
  )
}
export default Hero